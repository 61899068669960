import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import {
  addCity,
  editCity,
  getCities,
  getStates,
} from '../../_metronic/layout/components/api';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;

  editData: {
    Id: any;
    ZipCode: Number;
    AddressLine1: String;
    AddressLine2: String;
    Name: String;
    CityId: Number;
    StateId: Number;
    CityName: string;
    PhoneNumber: any;
    CountryCode: Number;
    IsActive: boolean
  };
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddCity = ({
  show,
  handleClose,
  editData,
  title,
  isForEditProfile = false,
}: Props) => {
  const [isActive, setIsActive] = useState(true);

  const validationSchema = Yup.object().shape({
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
  });

  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
  } = useMutation('states', getStates);

  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
    error: cityAPIError,
  } = useMutation('cities', getCities);

  const formik: any = useFormik({
    initialValues: {
      state: editData?.StateId,
      city: editData?.CityName,
    },
    validationSchema,
    onSubmit: async values => {

      let bodyParams = {
        id: editData?.Id,
        name : values.city ? values?.city?.trim().replace(/\s+/g, ' ') : '',
        stateId: Number(values?.state),
        isActive: isActive
      };

      if (editData) {
        let response = await editCityAPI(bodyParams);
        if (response?.IsSuccess) {
          snackActions.success(response?.Message);
          formik.resetForm();
          if (isForEditProfile) {
            localStorage.setItem('userInfo', JSON.stringify(response?.data));
          }
          handleClose(true);
        } else {
          snackActions.error(response?.Message);
        }
      } else {
        let response = await addCityAPI(bodyParams);
        if (response?.IsSuccess) {
          snackActions.success(response.Message);
          formik.resetForm();
          handleClose(true);
        } else {
          snackActions.error(response?.Message);
        }
      }
    },
  });

  const stateValue = formik.values.state;

  useEffect(() => {
    getStateData({ countryId: 1 });
  }, []);

  useEffect(() => {
if(editData){
  setIsActive(editData?.IsActive)
}
  }, [editData])

  useEffect(() => {
    if (stateValue) {
      getCityData({ stateId: stateValue, countryId: 1, Take: 1000  });
    }
  }, [stateValue]);

  const { mutateAsync: addCityAPI, isLoading: isAdding } = useMutation(
    'add-city',
    addCity
  );
  const { mutateAsync: editCityAPI, isLoading: isEditing } = useMutation(
    'edit-city',
    editCity
  );

  const handleIsAdminChange = (e: any) => {
    setIsActive(e.target.checked);
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px mx-md-auto mx-3"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="nursingHomepopup">
        <div className="modal-header">
          <h2 className="mb-0">
            {editData ? 'Edit City' : 'Add City'}
          </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined close">cancel</span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="p-4">
            <Container fluid>
              <Row className="mb-2 gy-4">
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    State <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>

                  <div>
                    <select
                      disabled={isLoadingState}
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      {...formik.getFieldProps('state')}
                      onChange={e => {
                        formik.handleChange(e);
                        const selectedState = e.target.value;
                        if (!selectedState) {
                          formik.setFieldValue('city', '');
                          cityData.data = '';
                        }
                      }}
                    >
                      <option value="">State</option>
                      {stateData
                        ? stateData?.Result?.map((state: any) => (
                            <option value={state?.Id} key={state?.Id}>
                              {state?.StateName} ({state?.Acronym})
                            </option>
                          ))
                        : null}
                    </select>

                    {formik.touched.state && formik.errors.state && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.state}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    City <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>

                  <div>
                  <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 documentBorder"
                        placeholder="City name"
                        aria-label="city name"
                        {...formik.getFieldProps('city')}
                        onChange={(e) => {
                        const value = e.target.value;
                        if (value.startsWith(' ')) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      />
                    {formik.touched.city && formik.errors.city && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.city}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={12} xs={12}>
                <input
                    type="checkbox"
                    className="form-check-input documentCheckbox"
                    checked={isActive}
                    onChange={handleIsAdminChange}
                  />
                  <label className="form-check-label documentCheckboxText">
                    Is Active?
                  </label>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="w-100 d-flex align-items-end justify-content-end px-6 pb-5">
            <Button type="submit" variant="primary">
              {isAdding || isEditing ? 'Loading...' : 'Save'}
            </Button>
          </div>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default AddCity;
