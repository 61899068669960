import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import { ReactComponent as Userprofile } from '../../../assets/svg/userprofile.svg';
import { ReactComponent as Edituser } from '../../../assets/svg/edituser.svg';
import { ReactComponent as Close } from '../../../assets/svg/close.svg';
import {
  createcompany,
  getCities,
  getCompanyDetailsById,
  getCountries,
  getSkills,
  getStates,
  updateCompany,
  uploadPicture,
} from '../api';
import ConfirmEditPopup from '../common/ConfirmEditPopup';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import './company.css';
import { APP_URLs } from '../../../../constants/appURLs';
type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;
  editData: {
    Id: any;
    isAdmin: boolean;
    skillName: string;
    lastName: string;
    firstName: string;
    phone: string;
    lastname: string;
    email: string;
    firstname: string;
    IsActive: boolean;
    isPrimaryAddress: boolean;
    address: any;
    ZipCode: string;
    state: string;
    city: string;
    AddressLine2: string;
    AddressLine1: string;
    description: string;
    CompanyWebsiteUrl: string;
    CompanyName: string;
    companySkills: any;
    IsRequiredNdadocument: any;
  };
};
interface ContactInfo {
  idObj: string;
  contactInfoId: any;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isAdmin: any;
}
interface Address {
  idObj: string;
  addressId: any;
  address1: string;
  address2: string;
  state: string;
  city: string;
  zipCode: string;
  isPrimaryAddress: any;
  stateName: string;
  cityName: string;
}
interface ISelectedCompanies {
  label: string;
  value: string;
}

type Skill = {
  id: any;
  skillName: string;
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const OnboardnewCompany = ({ show, handleClose, editData }: Props) => {
  console.log(editData, 'editData');
  const [addressList, setAddressList] = useState<Address[]>([
    {
      idObj: 'address-if-0',
      addressId: 0,
      address1: '',
      address2: '',
      state: '',
      city: '',
      zipCode: '',
      isPrimaryAddress: true,
      stateName: '',
      cityName: '',
    },
  ]);

  const [contactInfoList, setcontactInfoList] = useState<ContactInfo[]>([
    {
      idObj: 'contact-if-0',
      contactInfoId: 0,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      isAdmin: true,
    },
  ]);
  const [validationError, setValidationError] = useState(false);
  const [companyimg, setCompanyImg] = useState<string>('');
  const [skills, setSkills] = useState<Skill[]>([]);
  const [selectedState, setSelectedState] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedskills, setSelectedSkills] = useState<
    ISelectedCompanies[] | []
  >([]);
  const [selectedSkillName, setSelectedSkillName] = useState<Skill>(); // Inistialize with an empty string
  const open = Boolean(anchorEl);
  const [imgFileName, setImgFileName] = useState<string>();

  const [isVitasRequired, setIsVitasRequired] = useState(false);

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Facility name is required'),
    websiteURL: Yup.string().required('Website URL is required'),
    description: Yup.string().required('Description is required'),
  });

  const {
    data: countryData,
    isLoading: isLoadingCountry,
    error: countryAPIError,
  } = useQuery('countries', getCountries);

  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
    error: stateAPIError,
  } = useMutation('states', getStates);

  const { mutateAsync: pictureUpload, isLoading: isLoadingCompanyImg } =
    useMutation('upload-image', uploadPicture);

  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
    error: cityAPIError,
  } = useMutation('cities', getCities);

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (editData) {
      setIsVitasRequired(editData.IsRequiredNdadocument);
    }
  }, [editData]);

  const formik: any = useFormik({
    initialValues: {
      companyName: '',
      websiteURL: '',
      description: '',
      isVitasRequired: false,
    },
    validationSchema,
    onSubmit: async (values: any) => {
      if (selectedskills?.length === 0) {
        setValidationError(true);
        return;
      } else {
        setValidationError(false);
      }
      const emptyAddressFields = addressList.some(
        address =>
          address.address1 === '' ||
          address.address2 === '' ||
          address.city === '' ||
          address.state === '' ||
          address.zipCode === ''
      );
      if (emptyAddressFields) {
        snackActions.error('Please fill out all address fields.');
        return;
      }

      const emptyContactFields = contactInfoList.some(
        contact =>
          contact.firstName === '' ||
          contact.lastName === '' ||
          contact.email === '' ||
          contact.phoneNumber === ''
      );

      if (emptyContactFields) {
        snackActions.error('Please fill out all contact fields.');
        return;
      }

      const allAddressValues = [];
      for (let i = 0; i < addressList?.length; i++) {
        const sectionAddress = addressList[i];
        const sectionValues = {
          addressId: sectionAddress.addressId,
          addressLine1: sectionAddress.address1 ? sectionAddress?.address1?.trim().replace(/\s+/g, ' ') : '',
          addressLine2: sectionAddress.address2 ? sectionAddress?.address2?.trim().replace(/\s+/g, ' ') : '',
          cityId: sectionAddress.city,
          stateId: sectionAddress.state,
          zipCode: sectionAddress.zipCode,
          countryId: 1,
          isPrimaryAddress: sectionAddress.isPrimaryAddress,
        };

        allAddressValues.push(sectionValues);
      }

      const allContactValues = [];

      for (let i = 0; i < contactInfoList?.length; i++) {
        const sectionContact = contactInfoList[i];
        const sectionContactValues = {
          contactInfoId: sectionContact.contactInfoId,
          firstName: sectionContact.firstName ? sectionContact?.firstName?.trim().replace(/\s+/g, ' ') : '',
          lastName: sectionContact.lastName ? sectionContact?.lastName?.trim().replace(/\s+/g, ' ') : '',
          email: sectionContact.email ? sectionContact?.email?.trim().replace(/\s+/g, ' ') : '',
          phoneNumber: sectionContact.phoneNumber,
          isAdmin: sectionContact.isAdmin,
        };

        allContactValues.push(sectionContactValues);
      }

      const selectedSkillIds = selectedskills
        .map(skill => skill.value)
        .join(',');

      try {
        const bodyParams = {
          id: editData ? editData?.Id : undefined,
          companyName: values?.companyName ? values?.companyName?.trim().replace(/\s+/g, ' ') : '',
          companyWebsiteUrl: values?.websiteURL ? values?.websiteURL?.trim().replace(/\s+/g, ' ') : '',
          description: values?.description ? values?.description?.trim().replace(/\s+/g, ' ') : '',
          companyAddresses: allAddressValues,
          companyContactInfos: allContactValues,
          companyLogoUrl: companyimg ? companyimg : '',
          CompanySkillIds: selectedSkillIds,
          isRequiredNdadocument: isVitasRequired,
          isActive: editData?.IsActive ?? true,
          createdBy: 2,
        };
        if (editData) {
          let response = await editCompany(bodyParams);
          if (response?.IsSuccess) {
            snackActions.success(response?.Message);
            formik.resetForm();
            handleClose(true);
          } else {
            snackActions.error(response?.Message);
          }
        } else {
          let response = await companyCreate(bodyParams);
          if (response?.IsSuccess) {
            snackActions.success(response?.Message);
            formik.resetForm();
            handleClose(true);
          } else {
            snackActions.error(response?.Message);
          }
        }
      } catch (error) {
        console.error('Error creating company:', error);
      }
    },
  });

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? '');
    formData.append('files', file);
    formData.append('fileDriveName', 'UserProfilePictures');
    if (file) {
      if (file.size > 15 * 1024 * 1024) {
        snackActions.error('File size exceeds 15 MB limit.');
        return;
      }
      let response = await pictureUpload(formData);
      if (response?.IsSuccess) {
        setCompanyImg(response?.Result[0].FileUrl);
        snackActions.success(
          response.Message ?? 'Files Uploaded successfully.'
        );
      } else {
        snackActions.error(
          response?.message ?? 'Issue in image upload, try again latter!'
        );
      }
    }
  };

  const isAddressComplete = addressList.every(
    address =>
      address.address1 &&
      address.address2 &&
      address.city &&
      address.state &&
      address.zipCode
  );

  const isContactComplete = contactInfoList.every(
    contact =>
      contact.firstName &&
      contact.lastName &&
      contact.email &&
      contact.phoneNumber
  );

  const {
    data: companyData,
    mutateAsync: getCompanyDataById,
    isLoading: isLoadingCompany,
    error: companyAPIError,
  } = useMutation('comapnydetail', getCompanyDetailsById);

  const {
    data: appSkillData,
    isLoading: isGettingskillData,
    error: skillsAPIerror,
  } = useQuery('golf-list', getSkills);

  useEffect(() => {
    if (editData) {
      getCompanyDataById({ id: editData.Id });
    }
  }, [editData]);

  useEffect(() => {
    if (companyData) {
      formik.setFieldValue('companyName', companyData?.Result?.CompanyName);
      formik.setFieldValue(
        'websiteURL',
        companyData?.Result?.CompanyWebsiteUrl
      );
      formik.setFieldValue('description', companyData?.Result?.Description);
      setCompanyImg(companyData?.Result?.CompanyLogoUrl);

      const fetchCityData = async (stateId: any) => {
        const cityResponse = await getCityData({
          stateId: stateId,
          countryId: countryValue,
          Take: 1000
        });
        return cityResponse?.Result || [];
      };

      const processAddresses = async () => {
        const allAddressValues = await Promise.all(
          companyData?.Result?.CompanyAddresses?.map(
            async (sectionAddress: any) => {
              const cities = await fetchCityData(sectionAddress.StateId);
              const cityName =
                cities.find((c: any) => c?.Id === sectionAddress?.CityId)
                  ?.CityName || '';

              return {
                addressId: sectionAddress.AddressId,
                address1: sectionAddress.AddressLine1 || '',
                address2: sectionAddress.AddressLine2 || '',
                state: sectionAddress.StateId || '',
                city: sectionAddress.CityId || '',
                zipCode: sectionAddress.ZipCode || '',
                isPrimaryAddress: sectionAddress.IsPrimaryAddress || false,
                stateName: stateData?.Result?.find(
                  (c: any) => c?.Id === sectionAddress?.StateId
                )?.StateName,
                cityName: cityName,
              };
            }
          )
        );

        setAddressList(allAddressValues || []);
      };

      processAddresses();

      const allContactValues = companyData.Result?.CompanyContactInfos?.map(
        (contact: any) => ({
          contactInfoId: contact.ContactInfoId,
          firstName: contact.FirstName || '',
          lastName: contact.LastName || '',
          email: contact.Email || '',
          phoneNumber: contact.PhoneNumber || '',
          isAdmin: contact.IsAdmin || false,
        })
      );

      setcontactInfoList(allContactValues || []);

      const skillsValue = companyData?.Result?.CompanySkills?.map(
        (skill: any) => ({
          value: skill.SkillId,
          label: skill.SkillName,
        })
      );

      setSelectedSkills(skillsValue);
    }
  }, [companyData]);

  const { mutateAsync: companyCreate, isLoading: isAdding } = useMutation(
    'add-company',
    createcompany
  );

  const { mutateAsync: editCompany, isLoading: isUpdating } = useMutation(
    'update-company',
    updateCompany
  );

  const handleEdit = (value: any) => {
    setShowEditModal(true);
    setSelectedSkillName(value?.skillName);
  };

  const handleDeleteAddressField = (index: any) => {
    const updatedFields = [...addressList];
    if (addressList?.length > 1) {
      updatedFields.splice(index, 1);
    }

    setAddressList(updatedFields);
  };

  const handleDeleteContactField = (index: any) => {
    const updatedFields = [...contactInfoList];
    if (contactInfoList?.length > 1) {
      updatedFields.splice(index, 1);
    }
    setcontactInfoList(updatedFields);
  };

  const handleCloseEditmodal = (value?: any) => {
    setShowEditModal(false);
    if (value) {
      // Find the index of the item to be updated based on its ID
      const index = skills.findIndex(skill => skill.id === value.id);

      if (index !== -1) {
        // Create a copy of the skills array to avoid mutating state directly
        const updatedSkills = [...skills];
        // Update the item at the found index with the new data
        updatedSkills[index] = value;
        // Update the state with the modified skills array
        setSkills(updatedSkills);
      }
    }
  };

  const handleConfirmedEdit = (newValue: any) => {
    const index = skills.findIndex(skill => skill.id === newValue.id);
    if (index !== -1) {
      // If the skill is found, create a new array with the updated skill
      const updatedSkills = [...skills];
      updatedSkills[index] = newValue;

      // Update the state with the new array
      setSkills(updatedSkills);
    } else {
      // If the skill is not found, add the new skill to the existing array
      setSkills(prevSkills => [...prevSkills, newValue]);
    }
    handleCloseEditmodal(); // Close the modal
  };

  const handleStateChange = (
    index: number,
    fieldName: keyof Address,
    value: any
  ) => {
    const selectedStateId = value;
    setSelectedState(selectedStateId);

    const updatedAddressList = [...addressList];
    updatedAddressList[index][fieldName] = value;
    updatedAddressList[index].city = '';
    updatedAddressList[index].cityName = '';
    setAddressList(updatedAddressList);
  };

  const handleInputChange = (
    index: number,
    fieldName: keyof Address,
    value: string
  ) => {
    const updatedAddressList = [...addressList];
    updatedAddressList[index][fieldName] = value;
    setAddressList(updatedAddressList);
  };

  const handleAddAddress = () => {
    setAddressList([
      ...addressList,
      {
        idObj: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        isPrimaryAddress: false,
        stateName: '',
        cityName: '',
        addressId: 0,
      },
    ]);
  };

  const handleDeleteAddress = (index: any) => {
    let updatedFields = [...addressList];

    if (addressList?.length > 1) {
      updatedFields.splice(index, 1);
    }
    setAddressList(updatedFields);
  };

  const handleContactInfoChange = (
    index: number,
    fieldName: keyof ContactInfo,
    value: string
  ) => {
    const updateContactList = [...contactInfoList];
    updateContactList[index][fieldName] = value;
    setcontactInfoList(updateContactList);
  };

  const handleAddContact = () => {
    setcontactInfoList([
      ...contactInfoList,
      {
        idObj: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        isAdmin: false,
        contactInfoId: 0,
      },
    ]);
  };
  const handleDeleteContact = (index: any) => {
    let updatedFields = [...contactInfoList];

    if (contactInfoList?.length > 1) {
      updatedFields.splice(index, 1);
    }
    setcontactInfoList(updatedFields);
  };

  const handleisPrmariyAddress = (
    index: any,
    fieldName: keyof Address,
    e: any
  ) => {
    let updatedFields = [...addressList];

    // If only one address in the list and it's the one being selected
    if (addressList?.length === 1 && index === 0) {
      updatedFields[0].isPrimaryAddress = true;
    } else {
      updatedFields = updatedFields.map((address, i) => ({
        ...address,
        isPrimaryAddress: i === index, // Set isPrimaryAddress to true for the selected index, false for others
      }));
    }

    setAddressList(updatedFields);
  };

  const countryValue = 1;

  useEffect(() => {
    if (countryValue) {
      getStateData({ countryId: countryValue });
    }
  }, [countryValue]);

  useEffect(() => {
    if (selectedState) {
      getCityData({ stateId: selectedState, countryId: countryValue, Take: 1000  });
    }
  }, [selectedState]);

  const handleIsVitasChange = (e: any) => {
    setIsVitasRequired(e.target.checked);
  };

  return (
    <>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-900px"
        show={show}
        onHide={() => handleClose(false)}
        backdrop="static"
      >
        <Card className="companyModel">
          {isLoadingCompanyImg ||
            (isLoadingCity &&
            isLoadingState && (
              <Box className="spinner-loader">
                <CircularProgress />
              </Box>
            ))}
          <div className="modal-header py-3 px-5">
            <h2 className="mb-0">{editData ? 'Edit' : 'Add'} Facility</h2>
            {/* begin::Close */}
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              onClick={() => handleClose(false)}
            >
              <span className="material-symbols-outlined closeBtn">cancel</span>
            </div>
          </div>

          <form onSubmit={formik.handleSubmit} className="form">
            <div className="p-5">
              <Container fluid>
                <div className="mb-3">
                  <h2>Basic Information</h2>
                </div>
                <Row>
                  <Col md={3} sm={4} xs={12}>
                    <div className="mb-3 mb-sm-0 addImage">
                      <label
                        htmlFor="golf-img"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer addImageLabel"
                      ></label>
                      <input
                        id="golf-img"
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 addImageinput"
                        onChange={handleChangeImageUpload}
                      />
                      {companyimg ? (
                        <img
                          src={`${APP_URLs?.viewDocBaseURL}/${companyimg}`}
                          alt="Uploaded Image"
                          className="uplodedImageFile"
                          onClick={() => {
                            const inputElement =
                              document.getElementById('golf-img');
                            if (inputElement) {
                              inputElement.click();
                            }
                          }}
                        />
                      ) : (
                        <Userprofile
                          className="demoSvgImage"
                          onClick={() => {
                            const inputElement =
                              document.getElementById('golf-img');
                            if (inputElement) {
                              inputElement.click();
                            }
                          }}
                        />
                      )}
                      <Edituser
                        className="addImageIcon"
                        onClick={() => {
                          const inputElement =
                            document.getElementById('golf-img');
                          if (inputElement) {
                            inputElement.click();
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={9} sm={8} xs={12}>
                    <div className="mb-3">
                      <label className="form-label fw-bold mb-1">
                        Facility Name{' '}
                        <span className="fw-bolder mt-5 text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 companyInput autofillField"
                        placeholder="Facility Name"
                        aria-label="companyname"
                        {...formik.getFieldProps('companyName')}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.startsWith(' ')) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      />
                      {formik.touched.companyName &&
                        formik.errors.companyName && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.companyName}
                            </div>
                          </div>
                        )}
                    </div>

                    <div className="mb-3">
                      <label className="form-label fw-bold mb-1">
                        Website URL{' '}
                        <span className="fw-bolder mt-5 text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 companyInput autofillField"
                        placeholder="Website URL"
                        aria-label="websiteurl"
                        {...formik.getFieldProps('websiteURL')}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.startsWith(' ')) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      />
                      {formik.touched.websiteURL &&
                        formik.errors.websiteURL && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.websiteURL}
                            </div>
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="mb-3">
                      <label className="form-label fw-bold mb-1">
                        Description{' '}
                        <span className="fw-bolder mt-5 text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        placeholder="Description"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 companyInput textarea autofillField"
                        {...formik.getFieldProps('description')}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.startsWith(' ')) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      />
                      {formik.touched.description &&
                        formik.errors.description && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.description}
                            </div>
                          </div>
                        )}
                    </div>
                  </Col>
                </Row>
                <hr></hr>
                <div className="d-flex justify-content-between align-items-center mb-5">
                  <h2 className="mb-0">
                    Address{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </h2>
                  <div>
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={handleAddAddress}
                      disabled={!isAddressComplete}
                    >
                      <AddIcon /> Add Address
                    </Button>
                  </div>
                </div>
                {addressList.map((address, index) => (
                  <Card className="mb-7 customeboxShadow">
                    <Card.Body className="p-5">
                      <Row key={index}>
                        <Col xs={12}>
                          <div className="d-flex align-items-center justify-content-end mb-3">
                            <input
                              className="form-check-input primaryAddressCheckbox"
                              type="checkbox"
                              id="kt_role_contact"
                              onChange={e =>
                                handleisPrmariyAddress(
                                  index,
                                  'isPrimaryAddress',
                                  e.target.checked
                                )
                              }
                              checked={address.isPrimaryAddress}
                            />
                            <label className="form-check form-check-inline ps-3 m-0 fw-normal">
                              Mark as Primary Address
                            </label>
                          </div>
                        </Col>

                        <Col md={6} xs={12}>
                          <input
                            id={address.idObj}
                            name={`address1-${index}`}
                            type="text"
                            className="form-control form-control-lg mb-md-5 mb-3 addressInputStyle autofillFieldwhite"
                            placeholder="Address line 1"
                            value={address.address1}
                            onChange={e => {
                              const value = e.target.value;
                              if (value.startsWith(' ')) {
                                e.target.value = value.trimStart();
                              }
                              handleInputChange(
                                index,
                                'address1',
                                e.target.value
                              );
                            }}
                          />
                        </Col>

                        <Col md={6} xs={12}>
                          <input
                            id={address.idObj}
                            name={`address2-${index}`}
                            type="text"
                            className="form-control form-control-lg mb-md-5 mb-3 addressInputStyle autofillFieldwhite"
                            placeholder="Address line 2"
                            value={address.address2}
                            onChange={e =>{
                              const value = e.target.value;
                              if (value.startsWith(' ')) {
                                e.target.value = value.trimStart();
                              }
                              handleInputChange(
                                index,
                                'address2',
                                e.target.value
                              )
                            }
                            }
                          />
                        </Col>

                        <Col md={4} xs={12}>
                          <select
                            id={address.idObj}
                            name={`state-${index}`}
                            disabled={isLoadingState}
                            className="form-select form-select-lg mb-md-0 mb-3 addressInputStyle autofillFieldwhite"
                            aria-label={`state-${index}`}
                            onChange={e =>
                              handleStateChange(index, 'state', e.target.value)
                            }
                          >
                            <option value="">
                              {address.stateName === null ||
                              address.stateName === ''
                                ? ' Select State'
                                : address.stateName}
                            </option>
                            {stateData
                              ? stateData?.Result?.map((state: any) => (
                                  <option value={state?.Id} key={state?.Id}>
                                    {state?.StateName} ({state?.Acronym})
                                  </option>
                                ))
                              : null}
                          </select>
                        </Col>
                        <Col md={4} xs={12}>
                          <select
                            id={address.idObj}
                            name={`city-${index}`}
                            disabled={isLoadingCity}
                            className="form-select form-select-lg mb-md-0 mb-3 addressInputStyle autofillFieldwhite"
                            onChange={e =>
                              handleInputChange(index, 'city', e.target.value)
                            }
                          >
                            <option value="">
                              {' '}
                              {address.cityName === null ||
                              address.cityName === ''
                                ? ' Select City'
                                : address.cityName}
                            </option>
                            {cityData
                              ? cityData?.Result?.map((city: any) => (
                                  <option value={city?.Id} key={city?.Id}>
                                    {city?.CityName}
                                  </option>
                                ))
                              : null}
                          </select>
                        </Col>

                        <Col md={4} xs={12}>
                          <input
                            id={address.idObj}
                            name={`zipCode-${index}`}
                            type="number"
                            maxLength={6}
                            className="form-control form-control-lg mb-md-0 mb-3 addressInputStyle autofillFieldwhite"
                            placeholder="Postal code/Zipcode"
                            aria-label={`zipcode-${index}`}
                            value={address.zipCode}
                            onChange={e => {
                              // Remove non-numeric characters
                              const sanitizedValue = e.target.value.replace(
                                /\D/g,
                                ''
                              );
                              // Limit to 6 digits
                              const truncatedValue = sanitizedValue.slice(0, 6);
                              // Update the input value
                              handleInputChange(
                                index,
                                'zipCode',
                                truncatedValue
                              );
                            }}
                          />
                        </Col>
                        <div className="mt-3">
                          {addressList.map((address, index) => (
                            <div key={index}>
                              {addressList?.length > 1 &&
                                index === addressList?.length - 1 &&
                                index !== 0 && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'end',
                                    }}
                                    onClick={() => handleDeleteAddress(index)}
                                  >
                                    <div
                                      onClick={() =>
                                        handleDeleteAddressField(false)
                                      }
                                      style={{ marginRight: '8px' }}
                                    >
                                      <Close />
                                    </div>
                                    <label className="col-form-label fw-bold p-0 text-danger addressDelete">
                                      Delete
                                    </label>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
                <hr></hr>
                <div className="d-flex justify-content-between align-items-center mb-5">
                  <h2 className="mb-0">
                    Contact Information{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </h2>
                  <Button
                    size="sm"
                    variant="primary"
                    onClick={handleAddContact}
                    disabled={!isContactComplete}
                  >
                    <AddIcon /> Add Contact
                  </Button>
                </div>

                {contactInfoList.map((contact, index) => (
                  <Card className="mb-7 customeboxShadow">
                    <Card.Body className="p-5">
                      <Row key={index}>
                        <Col md={6} xs={12}>
                          <input
                            type="text"
                            className="form-control form-control-lg mb-md-5 mb-3 addressInputStyle autofillFieldwhite"
                            placeholder="First Name"
                            value={contact.firstName}
                            aria-label={`firstName-${index}`}
                            onChange={e =>{
                              const value = e.target.value;
                              if (value.startsWith(' ')) {
                                e.target.value = value.trimStart();
                              }
                              handleContactInfoChange(
                                index,
                                'firstName',
                                e.target.value
                              )
                            }
                            }
                          />
                        </Col>

                        <Col md={6} xs={12}>
                          <input
                            type="text"
                            className="form-control form-control-lg mb-md-5 mb-3 addressInputStyle autofillFieldwhite"
                            placeholder="Last Name"
                            value={contact.lastName}
                            aria-label={`lastName-${index}`}
                            onChange={e =>{
                              const value = e.target.value;
                              if (value.startsWith(' ')) {
                                e.target.value = value.trimStart();
                              }
                              handleContactInfoChange(
                                index,
                                'lastName',
                                e.target.value
                              )
                            }
                            }
                          />
                        </Col>

                        <Col md={6} xs={12}>
                          <input
                            placeholder="Email"
                            aria-label={`email-${index}`}
                            value={contact.email}
                            onChange={e =>
                              handleContactInfoChange(
                                index,
                                'email',
                                e.target.value
                              )
                            }
                            className="form-control form-control-lg mb-md-0 mb-3 addressInputStyle autofillFieldwhite"
                            type="email"
                          />
                        </Col>

                        <Col md={6} xs={12}>
                          <div className="mb-md-0 mb-3">
                            <PhoneInput
                              country={'us'}
                              onBlur={formik.handleBlur}
                              value={contact.phoneNumber}
                              onChange={(
                                value: string,
                                country: any,
                                e: any
                              ) => {
                                const newValue =
                                  value.trim() === '' ? '+1' : value;
                                //
                                handleContactInfoChange(
                                  index,
                                  'phoneNumber',
                                  e.target.value
                                );
                                formik.setFieldValue('countryCode', '+1');
                                formik.setFieldValue('phoneNumber', newValue);
                              }}
                              inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 addressInputStyle w-100 autofillFieldwhite"
                              onlyCountries={['us']}
                              disabled={editData ? true : false}
                              countryCodeEditable={false}
                            />
                          </div>
                        </Col>
                        <div className="mt-3">
                          {contactInfoList?.map((address, index) => (
                            <div key={index}>
                              {contactInfoList?.length > 1 &&
                                index === contactInfoList?.length - 1 &&
                                index !== 0 && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'end',
                                    }}
                                    onClick={() => handleDeleteContact(index)}
                                  >
                                    <div
                                      onClick={() =>
                                        handleDeleteContactField(false)
                                      }
                                      style={{ marginRight: '8px' }}
                                    >
                                      <Close />
                                    </div>
                                    <label className="col-form-label fw-bold p-0 addressDelete text-danger">
                                      Delete
                                    </label>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
                <div className="mb-3">
                  <h2>
                    Skills <span className="fw-bolder mt-5 text-danger">*</span>
                  </h2>
                </div>
                <Col xs={12}>
                  <div className="mb-3 skillSelection">
                    <MultiSelect
                      options={
                        appSkillData && !isGettingskillData
                          ? appSkillData?.Result?.map((d: any) => {
                              return { label: d?.SkillName, value: d?.Id };
                            })
                          : []
                      }
                      value={selectedskills ?? []}
                      onChange={setSelectedSkills}
                      labelledBy="Select skill..."
                      className="selectProgram form-control form-control-lg form-control-solid p-1"
                    />
                  </div>
                  {validationError && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        select atleast one skill
                      </div>
                    </div>
                  )}
                </Col>

                <div className="mt-5 d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={isVitasRequired}
                    onChange={handleIsVitasChange}
                    id="isRequiredVitas"
                  />
                  <label
                    className="form-check-label vitasworkLabel"
                    htmlFor="isRequiredVitas"
                  >
                    Is Required Vitas Paperwork?
                  </label>
                </div>
              </Container>
            </div>
            <div className="p-5 pt-0 d-flex align-items-end justify-content-end">
              <Button type="submit" size="lg" variant="primary">
                {isAdding || isUpdating ? 'Loading..' : 'Save'}
              </Button>
            </div>
          </form>
        </Card>
        {showEditModal && (
          <ConfirmEditPopup
            showPopup={showEditModal}
            handleCloseEdit={handleCloseEditmodal}
            title={'Edit Skill'}
            handleConfirmed={handleConfirmedEdit}
            message={''}
            loading={false}
            initialValue={selectedSkillName}
          />
        )}
      </Modal>
    </>
  );
};

export default OnboardnewCompany;
