import { useFormik } from 'formik';
import { useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import { setAssignmentFormData } from '../../../../store/slice/form/assignment_form';
import { documentData, uploadPicture } from '../api';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  handleCallBack: (data: any) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;

  editData?: {
    ProfilePictureUrl: string;
    Address: any;
    UserId: any;
    FirstName: string;
    LastName: string;
    Email: string;
    MobileNumber: any;
    password: string;
    CountryId: number;
    StateId: number;
    CityId: number;
    ZipCode: number;
    manageGolfs: string[];
    role: string[];
    Documents: any;
    Id: number;
    DocumentName: string;
    DocumentUrl: string;
    CompanyName: string;
    companyId: number;
    file: any;
  };
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddEditAssignmentDocument = ({
  show,
  handleClose,
  editData,
  title,
}: Props) => {
  let imgUrl: string = editData?.ProfilePictureUrl
    ? editData?.ProfilePictureUrl
      ? editData?.ProfilePictureUrl
      : ''
    : '';
  const [assignmentDoc, setAssignmentDoc] = useState<string>('');

  const [imgFileName, setImgFileName] = useState<string>(
    imgUrl ? imgUrl?.split('/img/')[1] : ''
  );
  const formDatas: any = useSelector((state: any) => state.assignment.formData);
  const validationSchema = Yup.object().shape({
    documentFileName: Yup.string().required('Document name is required'),
    file: Yup.string().required('Document is required'),
  });

  const { mutateAsync: pictureUpload, isLoading: documentUploading } =
    useMutation('upload-image', uploadPicture);

  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      documentFileName: editData?.DocumentName || '',
      file: '',
    },
    validationSchema,
    onSubmit: async values => {
      const existingDocuments = formDatas?.assignmentDocuments;
      let newDocument = {
        documentName: values?.documentFileName ? values?.documentFileName?.trim().replace(/\s+/g, ' ') : '',
        documentUrl: assignmentDoc,
        fileName: imgFileName || [],
      };
      let updatedDocuments = [...existingDocuments, newDocument];

      dispatch(
        setAssignmentFormData({ assignmentDocuments: updatedDocuments })
      );
      handleClose(true);
    },
  });

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    'add-document',
    documentData
  );

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? '');
    formData.append('files', file);
    formData.append('fileDriveName', 'CompanyDocuments');
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.IsSuccess) {
        formik.setFieldValue('file', response?.Result[0].FileUrl || '');
        setAssignmentDoc(response?.Result[0].FileUrl);
        snackActions.success(
          response.Message ?? 'Files Uploaded successfully.'
        );
      } else {
        snackActions.error(
          response?.message ?? 'Issue in file upload, try again latter!'
        );
      }
    }
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="" style={{ backgroundColor: '#f9f9f9' }}>
        {documentUploading && (
          <Box className="spinner-loader">
            <CircularProgress />
          </Box>
        )}
        <div
          className="modal-header"
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '1.5px solid #919EAB66',
            padding: '12px 24px',
          }}
        >
          <h2 className="mb-0">{title ? title : 'Add/Edit Document'}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: '#FE7253' }}
            >
              cancel
            </span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="p-6" style={{ backgroundColor: '#f9f9f9' }}>
            <Container fluid>
              <Row>
                <label className="form-label fw-normal">Document Name</label>
                <Col xs={12}>
                  <div className="mb-5">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                      placeholder="Document Name"
                      aria-label="Document Name"
                      style={{
                        border: '1px solid #919EAB',
                      }}
                      {...formik.getFieldProps('documentFileName')}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.startsWith(' ')) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />
                    {formik.touched.documentFileName &&
                      formik.errors.documentFileName && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.documentFileName}
                          </div>
                        </div>
                      )}
                  </div>
                </Col>
                <Col xs={12}>
                  <label className="form-label fw-normal">File</label>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      border: '1px solid #919EAB66',
                      borderRadius: '8px',
                      padding: '4px',
                    }}
                  >
                    <div className="p-2 flex-grow-1 w-100">
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx,image/*"
                        onChange={handleChangeImageUpload}
                        className="form-control-file border-0"
                      />
                    </div>
                  </div>
                  {formik.touched.file && formik.errors.file && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.file}</div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
          <div className="w-100 d-flex align-items-end justify-content-end px-8 pb-5">
            <div>
              <Button
                type="submit"
                size="lg"
                variant="primary"
                style={{ padding: '7px 45px' }}
                disabled={isAdding}
              >
                {isAdding ? 'Loading...' : 'Save'}
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default AddEditAssignmentDocument;
