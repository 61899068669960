import { format } from 'date-fns';
import dayjs from 'dayjs';
// import moment from "moment";
import 'moment-timezone'; // Ensure to import moment-timezone
const moment = require('moment');
require('moment-timezone');

const momentTimeZone = require('moment-timezone');

// const convertTimesToUTC = (
//   assignmentDate: string,
//   startTime: string,
//   endTime: string,
//   timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
// ) => {
//   // Parse the assignmentDate as a local date in the specified timezone
//   const localAssignmentDate = momentTimeZone.tz(assignmentDate, timezone);

//   // Combine the assignmentDate with the startTime and endTime to create full datetime strings
//   const startLocal = momentTimeZone.tz(
//     `${localAssignmentDate.format('YYYY-MM-DD')}T${startTime}`,
//     timezone
//   );

//   const endLocal = momentTimeZone.tz(
//     `${localAssignmentDate.format('YYYY-MM-DD')}T${endTime}`,
//     timezone
//   );

//   // Convert startLocal and endLocal to UTC
//   const startUTC = startLocal.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
//   const endUTC = endLocal.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

//   // Get the start of the day in local time and convert it to UTC
//   const startOfDayLocal = localAssignmentDate.startOf('day');
//   const startOfDayUTC = startOfDayLocal
//     .utc()
//     .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

//   return {
//     assignmentDate: startOfDayUTC,
//     startTime: startUTC,
//     endTime: endUTC,
//     timezone,
//   };
// };

const convertTimesToUTC = (
  assignmentDate: string,
  startTime: string,
  endTime: string,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  const localAssignmentDate = momentTimeZone.tz(assignmentDate, timezone);
  const startLocal = momentTimeZone.tz(
    `${localAssignmentDate.format('YYYY-MM-DD')}T${startTime}`,
    timezone
  );

  let endLocalDate = localAssignmentDate.clone();
  if (
    momentTimeZone
      .tz(`${localAssignmentDate.format('YYYY-MM-DD')}T${endTime}`, timezone)
      .isBefore(startLocal)
  ) {
    endLocalDate.add(1, 'day');
  }

  const endLocal = momentTimeZone.tz(
    `${endLocalDate.format('YYYY-MM-DD')}T${endTime}`,
    timezone
  );

  const startUTC = startLocal.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
  const endUTC = endLocal.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

  const startOfDayLocal = localAssignmentDate.startOf('day');
  const startOfDayUTC = startOfDayLocal
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  return {
    assignmentDate: startOfDayUTC,
    startTime: startUTC,
    endTime: endUTC,
    timezone,
  };
};

const convertUTCTimesToLocal = (
  utcAssignmentDate: any,
  utcStartTime: any,
  utcEndTime: any,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  const utcStartDatetime = `${utcAssignmentDate}T${utcStartTime}Z`;
  const utcEndDatetime = `${utcAssignmentDate}T${utcEndTime}Z`;

  const utcStartMoment = moment.utc(utcStartDatetime);
  const utcEndMoment = moment.utc(utcEndDatetime);
  const localStartMoment = utcStartMoment.tz(timezone);
  const localEndMoment = utcEndMoment.tz(timezone);

  const startTime = localStartMoment.format('HH:mm:ss');
  const endTime = localEndMoment.format('HH:mm:ss');

  const localAssignmentDate = localStartMoment.format('MM-DD-YYYY');

  return {
    assignmentDate: localAssignmentDate,
    startTime,
    endTime,
  };
};

const formatDate = (
  date: string | undefined | null,
  formatString?: string,
  addTimeZoneOffset = false,
  shouldConvertToCST = false
) => {
  if (!date) return '';
  try {
    const dt = new Date(date);
    if (shouldConvertToCST) {
      let usaTime = dt.toLocaleString('en-US', {
        timeZone: 'CST',
      });
      return format(
        new Date(usaTime),
        formatString ? formatString : 'MMM dd yyyy'
      );
    }
    const dtDateOnly = new Date(
      addTimeZoneOffset ? dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000 : dt
    );
    return format(dtDateOnly, formatString ? formatString : 'MMM dd yyyy');
  } catch (e) {
    return '';
  }
};

const formatCurrency = (
  amount: number = 0,
  currency?: string,
  isIntegerOnly?: boolean
) => {
  amount = isNaN(amount) ? 0 : amount;
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ? currency : 'USD',
    minimumFractionDigits: isIntegerOnly ? 0 : 2,
  });
  return formatter.format(amount);
};

const formatDateTimeString = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  };

  return date.toLocaleString('en-US', options);
};

function parseDateWithoutTimezone(date: string | Date, format?: string) {
  if (typeof date === 'string') {
    const momentDate = moment.utc(date, 'YYYY-MM-DD');
    if (momentDate.isValid()) {
      return momentDate.format(format ? format : 'YYYY-MM-DD');
    } else {
      return null;
    }
  } else {
    const momentDate = moment.utc(date);
    if (momentDate.isValid()) {
      return momentDate.format(format ? format : 'YYYY-MM-DD');
    } else {
      return null;
    }
  }
}

const formatSSN = (value: any) => {
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/);
  if (match) {
    return !match[2]
      ? match[1]
      : match[1] + '-' + match[2] + (match[3] ? '-' + match[3] : '');
  }
  return value;
};

const convertDateToMMDDYYYY = (dateString: string): any => {
  const parsedDate = new Date(dateString);

  if (parsedDate.toString() === 'Invalid Date') {
    return null;
  }
  return format(parsedDate, 'MM/dd/yyyy');
};

function formatTime(date: any) {
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

const formatPhoneNumber = (number: string | number) => {
  const cleaned = ('' + number).replace(/\D/g, '');

  if (cleaned.length === 10) {
    return `${cleaned.slice(0, 3)} (${cleaned.slice(3, 6)}) ${cleaned.slice(
      6
    )}`;
  } else if (cleaned.length === 11) {
    return `${cleaned.slice(0, 1)} (${cleaned.slice(1, 4)}) ${cleaned.slice(
      4,
      7
    )}-${cleaned.slice(7)}`;
  } else {
    return cleaned;
  }
};

function formatCharges(rate: number) {
  if (rate == null) return '$0.00/hr';
  const formattedRate = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(rate);
  return `${formattedRate}/hr`;
}

function decodeBase64(base64String: any) {
  try {
    return atob(base64String);
  } catch (e) {
    console.error('Invalid Base64 string', e);
    return null;
  }
}

const convertUTCToLocalForSingleValue = (
  date: string,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  const utcMoment = moment.utc(date, 'YYYY-MM-DD');
  const localMoment = utcMoment.clone().tz(timezone);
  const localDate = localMoment.format('MM/DD/YYYY');
  const localTime = localMoment.format('HH:mm:ss');
  return {
    localDate,
    localTime,
  };
};

const formatTimeForCheckInOut = (dateTimeString: any) => {
  const date = new Date(dateTimeString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

const convertUTCTimesToLocalFuncForCheckInOut = (
  utcAssignmentDate: string,
  utcStartTime: string,
  utcEndTime: string,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  type = ''
) => {
  const convertUTCToLocal = (utcDateTime: string) => {
    return moment.utc(utcDateTime).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
  };

  const localAssignmentDate = convertUTCToLocal(utcAssignmentDate);
  const localStartTime = convertUTCToLocal(utcStartTime);
  const localEndTime = convertUTCToLocal(utcEndTime);
  const formattedDate = moment(localAssignmentDate, 'YYYY-MM-DD').format(
    'MM/DD/YYYY'
  );

  if (type === 'checkIn') {
    return formatTimeForCheckInOut(localStartTime);
  } else if (type === 'clockOut') {
    return formatTimeForCheckInOut(localEndTime);
  } else {
    return formattedDate;
  }
};

const formatApprovedHours = (time: any) => {
  const hours = time?.Hours.toString().padStart(2, '0');
  const minutes = time?.Minutes.toString().padStart(2, '0');
  // const seconds = time?.Seconds.toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

function convertToDateTime(dateString: any, timeString: any, timezoneOffset = '+0530') {
  const [hours, minutes, seconds] = timeString?.split(':');
    return dayjs(dateString).hour(hours).minute(minutes).second(seconds ? seconds : "00");
}

function getDayOfWeekBasedOnDate(dateString: any) {
  const date = new Date(dateString);
  
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  
  return daysOfWeek[date.getDay()];
}


export {
  convertTimesToUTC,
  formatApprovedHours,
  convertUTCToLocalForSingleValue,
  convertUTCTimesToLocalFuncForCheckInOut,
  formatCharges,
  convertDateToMMDDYYYY,
  formatPhoneNumber,
  convertUTCTimesToLocal,
  formatCurrency,
  formatDate,
  formatDateTimeString,
  convertToDateTime,
  formatSSN,
  parseDateWithoutTimezone,
  formatTime,
  decodeBase64,
  formatTimeForCheckInOut,
  getDayOfWeekBasedOnDate
};
