import { Box, IconButton, Popover } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { KTCard, KTIcon } from '../../_metronic/helpers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  deletenursinghome,
  getCities,
  getNursinghome,
  getStates,
} from '../../_metronic/layout/components/api';
import ConfirmEventPopup from '../../_metronic/layout/components/common/ConfirmEventPopup';
import MuiTable from '../../components/table/MuiTable';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';
import useDebounce from '../../hooks/useDebounce';
import AddNursinghome from './AddNursinghome';
import { ReactComponent as Setting } from '../../_metronic/assets/svg/setting.svg';
import { ReactComponent as Edit } from '../../_metronic/assets/svg/edit.svg';
import { ReactComponent as Delete } from '../../_metronic/assets/svg/delete.svg';
import './nursinghome.css';
import FilterCommonPopUp from '../../_metronic/layout/components/common/FilterCommonPopUp';

const Nursinghome: React.FC = () => {
  const [nursingHome, setNursingHome] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [isForFilter, setIsForFilter] = useState(false);
  const [showActiveDeActivePopup, setShowActiveDeActivePopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  

  let defaultQuery = {
    offset: 0,
    limit: 100,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
  };
  
  const tableRef = useRef();
  const navigate = useNavigate();

  const [showStatusModel, setshowStatusModel] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [showFilterPopUp, setShowFilterPopUp] = useState(false);
  console.log(selectedState, "selectedState");
  console.log(selectedCity, "selectedCity");
  
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
    stateIds: selectedState,
    cityIds: selectedCity
  });
  
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  const [showUserId, setShowUserId] = useState('');

  const {
    mutateAsync: getnursinghomeaddress,
    data: nursinghomeaddressdata,
    isLoading: isGettingnursinghomeData,
  } = useMutation('golf-list', getNursinghome);

  const deletenursinghomeAPI = async (value: any) => {
    const response = await deletenursinghomedata({ id: value?.Id });
    if (response) {
      snackActions.success(response?.Message);
      handleCloseDelete();
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        roleId: 3,
        search: debounceSearchValue ?? '',
        stateIds: selectedState,
        cityIds: selectedCity
      };
      getnursinghomedata(query);
    } else {
      snackActions.error(response?.Message);
    }
  };

  const {
    mutateAsync: deletenursinghomedata,
    isLoading: isDeletingnursinghome,
  } = useMutation('delete-document', deletenursinghome);

  useEffect(() => {
    getnursinghomedata({
      ...tableOptions,
      search: debounceSearchValue ?? '',
    });
  }, [debounceSearchValue]);

  useEffect(() => {
    if(isForFilter){
      getnursinghomedata({
        ...tableOptions,
        search: debounceSearchValue ?? '',
      });
      setIsForFilter(false)
    }
  }, [debounceSearchValue, isForFilter]);

  const getnursinghomedata = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getnursinghomeaddress(query);
    if (response?.IsSuccess) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.PageDetail?.Skip,
          limit: response?.PageDetail?.Take,
          hasNextPage: response?.PageDetail?.HasNext,
          hasPrevPage: response?.PageDetail?.HasPrevious,
          totalRowCount: response?.PageDetail?.Count,
        };
      });
    }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let offsetValue = page * pageSize;
    let query = {
      offset:
      offsetValue,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? '',
      stateIds: selectedState,
    cityIds: selectedCity
    };
    getnursinghomedata(query);
  };

  const handleModalClose = (isSubmit: boolean) => {
    setNursingHome(false);
    setShowViewModal(false);
    handleCloseActivePopup();
    setSelectedRow('');
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        search: '',
      };
      getnursinghomedata(query);
    }
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleCloseActivePopup = () => {
    setShowActiveDeActivePopup(false);
  };

  const handleEditModalOpen = () => {
    setNursingHome(true);
    setAnchorEl(null);
  };

  const handleViewModalOpen = () => {
    setShowViewModal(true);
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setshowStatusModel('');
  };

  const handleFilterModalClose = (obj : any, isForClose: boolean) => {
    setShowFilterPopUp(false)
    if(!isForClose){
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          ["stateIds"]: obj?.SelectedState,
          ["cityIds"]: obj?.selectedCity,
        };
      });
      setSelectedState(obj?.SelectedState)
      setSelectedCity(obj?.SelectedCity)
      setIsForFilter(true)
    }
  else{
    setIsForFilter(false)
  }
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => data?.row?.Name ?? '-',
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 2.8,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) =>
        data?.row?.AddressLine1 +
        '' +
        data?.row?.AddressLine2 +
        ', ' +
        data?.row?.CityName +
        ', ' +
        data?.row.StateAcronym +
        ', ' +
        data?.row?.ZipCode,
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      minWidth: 120,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        let status: any;
        const handleClick = (value: any, e: any) => {
          setSelectedRow(value);
          setAnchorEl(e.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        return (
          <div className="d-flex align-items-center">
            <IconButton onClick={e => handleClick(data?.row, e)}>
              <Setting />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                p: 1,
                '& .MuiPaper-root': {
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
                },
              }}
            >
              <div className="nursingPopover">
                <Col
                  className="d-flex align-items-center mb-1"
                  onClick={handleEditModalOpen}
                >
                  <Edit />
                  <label className="ms-3">Edit</label>
                </Col>
                <Col
                  className="d-flex align-items-center"
                  onClick={() => {
                    setShowDeleteConfirmModal(true);
                    setAnchorEl(null);
                  }}
                >
                  <Delete />
                  <label className="ms-3">Archive</label>
                </Col>
              </div>
            </Popover>
          </div>
        );
      },
    },
  ];

  return (
    <Card className="p-6 customeboxShadow">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={nursinghomeaddressdata?.Result ?? []}
            loading={isGettingnursinghomeData}
            tableRef={tableRef}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <>
                <div className="d-sm-flex mb-4 align-items-end justify-content-between">
                  <Box display="flex">
                    <div className="mb-sm-0 mb-4 position-relative d-flex">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Search Nursing Home"
                        aria-label="Search"
                        style={{
                          // Your custom styles here
                          backgroundImage: `url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.80454 2.40976C7.78799 2.40985 6.78619 2.65303 5.88273 3.11903C4.97928 3.58503 4.20036 4.26032 3.61097 5.08857C3.02158 5.91683 2.6388 6.87402 2.49457 7.88029C2.35035 8.88657 2.44885 9.91274 2.78188 10.8732C3.1149 11.8337 3.67278 12.7006 4.40897 13.4016C5.14516 14.1026 6.03832 14.6173 7.01393 14.903C7.98953 15.1886 9.0193 15.2367 10.0173 15.0434C11.0153 14.8501 11.9526 14.421 12.751 13.7918L15.49 16.5308C15.6315 16.6674 15.8209 16.743 16.0176 16.7413C16.2142 16.7396 16.4024 16.6607 16.5414 16.5216C16.6805 16.3826 16.7593 16.1945 16.761 15.9978C16.7628 15.8012 16.6872 15.6117 16.5505 15.4703L13.8115 12.7313C14.5525 11.7912 15.0139 10.6616 15.1429 9.47155C15.2718 8.28155 15.0631 7.07928 14.5407 6.00233C14.0183 4.92538 13.2032 4.01728 12.1888 3.38193C11.1743 2.74659 10.0015 2.40968 8.80454 2.40976ZM3.92954 8.78476C3.92954 7.49183 4.44316 6.25185 5.3574 5.33761C6.27164 4.42337 7.51161 3.90976 8.80454 3.90976C10.0975 3.90976 11.3374 4.42337 12.2517 5.33761C13.1659 6.25185 13.6795 7.49183 13.6795 8.78476C13.6795 10.0777 13.1659 11.3177 12.2517 12.2319C11.3374 13.1461 10.0975 13.6598 8.80454 13.6598C7.51161 13.6598 6.27164 13.1461 5.3574 12.2319C4.44316 11.3177 3.92954 10.0777 3.92954 8.78476Z' fill='%23637281'/%3E%3C/svg%3E")`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'left 8px center',
                          padding: '20px 30px 0px 30px',
                        }}
                        onChange={e =>
                          handleChangeFilters('search', e.target.value)
                        }
                        value={tableOptions?.search}
                      />
                      {tableOptions?.search && (
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleChangeFilters('search', '')}
                        ></i>
                      )}



{/* <select
                      disabled={isLoadingState}
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      // {...formik.getFieldProps('state')}
                      value={selectedState}
                      onChange={e => {
                        setSelectedState(e.target.value)
                          setSelectedCity("")
                          handleChangeFilters("stateIds", e?.target?.value);
                      }}
                      style={{marginLeft:"10px"}}
                    >
                      <option value="">State</option>
                      {stateData
                        ? stateData?.Result?.map((state: any) => (
                            <option value={state?.Id} key={state?.Id}>
                              {state?.StateName}
                            </option>
                          ))
                        : null}
                    </select>


                    <select
                      disabled={isLoadingCity}
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      value={selectedCity}
                      onChange={e => {
                        setSelectedCity(e.target.value)
                        handleChangeFilters("cityIds", e?.target?.value);
                      }}
                      style={{marginLeft:"10px"}}
                    >
                      <option value="">Select City</option>
                      {cityData
                        ? cityData?.Result?.map((city: any) => (
                            <option value={city?.Id} key={city?.Id}>
                              {city?.CityName}
                            </option>
                          ))
                        : null}
                    </select> */}
                    </div>
                    <Button
                      style={{marginLeft:"10px"}}
                      onClick={() => setShowFilterPopUp(true)}
                      >
                      <FilterAltIcon />
                      </Button>
                  </Box>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="d-flex align-items-center addNursingbtn"
                      onClick={() => {
                        setNursingHome(true);
                        setSelectedRow('');
                      }}
                    >
                      <KTIcon iconName="plus" className="fs-1" />
                      Add Nursing Home
                    </Button>
                  </div>
                </div>
              </>
            }
          />
        </KTCard>
        {nursingHome && (
          <AddNursinghome
            editData={selectedRow}
            show={nursingHome}
            handleClose={handleModalClose}
          />
        )}

        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={() => deletenursinghomeAPI(selectedRow)}
            loading={isDeletingnursinghome}
            title={'Archive Nursing Home'}
            message={`Are you sure, do you want to archive this Nursing Home?`}
          />
        )}

{showFilterPopUp && (
          <FilterCommonPopUp
          showPopup={showFilterPopUp}
          values={{
            selectedState: selectedState,
            selectedCity: selectedCity
          }}
          handleClose={handleFilterModalClose}
          page={"Nursinghome"}
          />
        )}
      </>
    </Card>
  );
};

export default Nursinghome;
