import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import WorkIcon from '@mui/icons-material/Work';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import Popover from '@mui/material/Popover';
import HistoryIcon from '@mui/icons-material/History';

import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import {
  addUpdateCompanyProviderDocuments,
  getCompanies,
  getDocumentsDetails,
  getProgram,
  getproviderbyid,
  getProviderDocumentById,
  updateProviderDocument,
  updateProviderProfile,
  updateProviderReference,
  updateProviderSkill,
  updateProviderStatus,
  updateStatusOfClinician,
} from '../api';
import { Box, Chip, Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MultiSelect } from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { charValidate } from '../../../../components/utility';
import { convertDateToMMDDYYYY } from '../../../../helpers/commonFunctions';
import { isSuperAdmin } from '../../../../hooks/useUserInfo';
import {
  resetAllDocumentsValues,
  setClinicianDocuments,
  setClinicianNotesForViewScreen,
  setCompanyProviders,
} from '../../../../store/slice/form/document_form';
import ConfirmEventPopup from '../common/ConfirmEventPopup';
import AddEditClinicianDocs from './clinician-docs/AddEditClinicianDocs';
import AddEditRefDocs from './clinician-docs/AddEditRefDocs';
import { APP_URLs } from '../../../../constants/appURLs';
import AddEditVitasDoc from './clinician-docs/AddEditVitasDoc';
import AddEditReliasDocs from './clinician-docs/ReliasDocs';
import { ReactComponent as Visibility } from '../../../assets/svg/visibility.svg';
import { ReactComponent as ClinicianEdit } from '../../../assets/svg/clinicianEdit.svg';
import { ReactComponent as ClinicianRejectedEditIcon } from '../../../assets/svg/clinicianRejectedEdit.svg';
import { ReactComponent as Userprofile } from '../../../assets/svg/userprofile.svg';
import { ReactComponent as Verify } from '../../../assets/svg/verify.svg';
import { ReactComponent as Verified } from '../../../assets/svg/verified.svg';
import { ReactComponent as ClinicianVerified } from '../../../assets/svg/clinician-verified.svg';
import { ReactComponent as Rejected } from '../../../assets/svg/rejected.svg';
import { ReactComponent as Clinicianrejecte } from '../../../assets/svg/clinician_reject.svg';
import dayjs from 'dayjs';
import './clinician-style.css';
import { DocumentCategoryEnum, DocumentUploadFromEnum } from '../../../helpers/enum';
import ViewOnBoardingDocs from './clinician-docs/ViewOnBoardingDocs';
import ShowNotesHistoryInPopup from '../../../../helpers/notesHistoryPopup';
import ShowClinicianHistoryInPopup from '../../../../helpers/clinicianStatusHistoryPopup';
import AddEditHospiceDocs from './clinician-docs/AddEditHospiceDocs';

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;

  viewData: {
    ProfilePictureUrl: string;
    Address: any;
    Id: any;
    FirstName: string;
    LastName: string;
    Email: string;
    MobileNumber: any;
    password: string;
    CountryId: number;
    StateId: number;
    StateName: string;
    CityId: number;
    CityName: string;
    CountryName: string;
    ZipCode: number;
    manageGolfs: string[];
    role: string[];
    Documents: any;
    ProfileStatusName: string;
    ProfileStatusId: any;
    ProviderSkills: any;
    ProgramIds: [];
    Result: [];
  };
};

interface UploadedFile {
  id: any;
  documentTypeId: string;
  name: string;
  document: {
    documentUrl: string;
    fileName: string;
  };
}

const modalsRoot = document.getElementById('root-modals') || document.body;

const ViewProvider = ({ show, handleClose, viewData, title }: Props | any) => {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showStatusModel, setshowStatusModel] = useState({
    id: '',
    status: '',
  });
  const [selectedCompany, setSelectedCompanay] = useState<any>([]);
  const [showVitasModal, setShowVitasModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showRefAddEditModal, setShowRefAddEditModal] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>('');
  const [reliasDocsValue, setReliasDocsValue] = useState<string>('');
  const [programNames, setProgramNames] = useState([]);
  const [showSkillModel, setshowSkillModel] = useState({ id: '', status: '' });
  const [showReferenceModel, setShowReferenceModel] = useState({
    id: '',
    status: '',
  });
  const [showUpdateSkillConfirmModal, setShowUpdateSkillConfirmModal] =
    useState(false);
  const [showUpdateReferenceModal, setShowUpdateReferenceModal] =
    useState(false);

  const [selectedProgram, setSelectedProgram] = useState<any>([]);
  const [clinicianDocument, setClinicianDocument] = useState([]);
  const [isProgramSelected, setIsProgramSelected] = useState(true);
  const [companiesOfDNR, setCompaniesOfDNR] = useState<any[]>([]);
  const [showAddEditRealisModal, setShowAddEditRealisModal] = useState(false);
  const [clinicianNotes, setClinicianNotes] = useState('');
  const [showVitasDocument, setShowVitasDocument] = useState(false);
  const [isHospiceDocs, setIsHospiceDocs] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElForHistory, setAnchorElForHisory] = useState(null);
  const [anchorElForNotesHistory, setAnchorElForNotesHistory] = useState(null);
  const [anchorElForReason, setAnchorElForReason] = useState(null);
  const [showMore, setShowMore] = useState(false); 
  const dispatch = useDispatch();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleReasonClick = (event: any) => {
    setAnchorElForReason(event.currentTarget);
  };
  const handleHistoryClick = (event: any) => {
    setAnchorElForHisory(event.currentTarget);
  };
  const handleHistoryClickForNotes = (event: any) => {
    setAnchorElForNotesHistory(event.currentTarget);
  };

  const handleCloseRef = () => {
    setAnchorEl(null);
  };
  const handleCloseReason = () => {
    setAnchorElForReason(null);
  };
  const handleCloseHistory = () => {
    setAnchorElForHisory(null);
  };
  const handleCloseHistoryForNotes = () => {
    setAnchorElForNotesHistory(null);
  };

  const openRef: boolean = Boolean(anchorEl);
  const openHisory: boolean = Boolean(anchorElForHistory);
  const openReason: boolean = Boolean(anchorElForReason);
  const openHisoryForNotes: boolean = Boolean(anchorElForNotesHistory);
  const id: string | undefined = openRef
    ? 'provider-references-popover'
    : undefined;

    const reasonId: string | undefined = openReason
    ? 'provider-reason-popover'
    : undefined;

  const HisotryId: string | undefined = openRef
    ? 'provider-history-popover'
    : undefined;

  const {
    data: providerData,
    mutateAsync: getproviderId,
    isLoading: isloadinngprovider,
  } = useMutation('add-golf', getproviderbyid);

  const { mutateAsync: updateProviderData, isLoading: isUpdateProfile } =
    useMutation('updateProviderStatus', updateProviderStatus);

  const {
    mutateAsync: updateProviderProfileAPI,
    isLoading: isProviderProfileLoading,
  } = useMutation('clinician-profile', updateProviderProfile);

  const { data: companiesData, isLoading: isLoadingCompanies } = useQuery(
    'companies',
    getCompanies
  );

  const { mutateAsync: updateCliniianStatusAPI } = useMutation(
    'update-clinician-status',
    updateStatusOfClinician
  );

  const { mutateAsync: updateProviderSkillData, isLoading: isUpdateSkill } =
    useMutation('updateProviderSkill', updateProviderSkill);

  const {
    mutateAsync: updateProviderDocumentData,
    isLoading: loadingOnRejection,
  } = useMutation('updateProviderSkill', updateProviderDocument);

  const isShowLoaderOnRejectionPop = loadingOnRejection || isUpdateSkill;

  const {
    mutateAsync: updateReferenceStatus,
    isLoading: isProviderReferenceUpdate,
  } = useMutation('updateProviderReference', updateProviderReference);

  const { data: programData, isLoading: isProgramLoading } = useQuery(
    'program-list',
    getProgram
  );

  const { data: providerDocumentData, mutateAsync: getDocumentById } =
    useMutation('program-document-list', getProviderDocumentById);

  const providerDocuments = providerDocumentData?.Result;

  useEffect(() => {
    if (providerDocuments) {
      const clinicianDocuments = providerDocuments?.filter(
        (typeid: any) => typeid?.DocumentType?.DocumentCategoryId == 1
      );

      setClinicianDocument(clinicianDocuments);
    }
  }, [providerDocuments]);

  const getProviderDocumentData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.providerDocuments
  );

  const getClinicianNotes: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.clinicianNotes
  );


  useEffect(() => {
if(getClinicianNotes){
  setClinicianNotes(getClinicianNotes)
}
  }, [getClinicianNotes])
  const { data: documentTypes } = useQuery(
    'documentsDetails',
    getDocumentsDetails
  );
  const detailsTypeList = documentTypes?.Result;

  const backgroundCheckDocuments = detailsTypeList?.filter(
    (item: any) =>
      item?.DocumentCategoryId == DocumentCategoryEnum.BackgroundCheck
  );

  useEffect(() => {
    if (providerData) {
      let providerDocuments: any =
        providerData?.Result?.ProviderBackgroundChecks;
      const defaultDataMap: any = new Map(
        getProviderDocumentData.map((doc: any) => [doc.documentTypeId, doc])
      );

      const payloadForDocuments = {
        providerDocuments: providerDocuments?.map((item: any) => {
          const documentTypeId =
            item.DocumentType?.Id === 1
              ? 1
              : item.ProviderDocuments[0]?.DocumentTypeId;

          const providerDocumentsArray =
            item?.DocumentType?.Id === 14
              ? item.ProviderDocuments
              : item?.ProviderDocuments[0];
          const hasDocuments = item.ProviderDocuments?.length > 0;
          return {
            id: hasDocuments ? item.ProviderDocuments[0]?.Id : 0,
            parentId: hasDocuments
              ? item.ProviderDocuments[0]?.ParentDocumentTypeId
              : item?.DocumentType?.Id,
            documentTypeId:
              documentTypeId ||
              item.DocumentType?.Id ||
              item.type?.Id ||
              defaultDataMap.get(item.DocumentType?.DocumentType)
                ?.documentTypeId,

            document: {
              documentUrl:
                item?.ProviderDocuments[0]?.Document?.DocumentUrl ||
                defaultDataMap.get(item?.DocumentType?.DocumentType)?.document
                  ?.documentUrl ||
                '',

              fileName:
                item?.ProviderDocuments[0]?.Document?.FileName ||
                defaultDataMap.get(item?.DocumentType?.DocumentType)?.document
                  ?.fileName ||
                '',

              expiryDate:
                item?.ProviderDocuments[0]?.Document?.ExpiryDate &&
                item.ProviderDocuments[0].Document.ExpiryDate !== 'Invalid date'
                  ? dayjs(item.ProviderDocuments[0].Document.ExpiryDate)
                  : '',

              documentName:
                item?.ProviderDocuments[0]?.Document?.DocumentType ||
                defaultDataMap.get(item?.DocumentType?.DocumentType)?.document
                  ?.documentName ||
                '',
            },
            providerDocument: providerDocumentsArray,
            providerDocuments: providerDocumentsArray,
          };
        }),
      };
      dispatch(setClinicianDocuments(payloadForDocuments));
      setClinicianNotes(getClinicianNotes ? getClinicianNotes : providerViewData?.ProviderNotes[0]?.Note);

      const onBoardingDocs = providerData?.Result?.Documents?.filter(
        (item: any) =>
          item?.DocumentType?.UploadFromId ===
        DocumentUploadFromEnum?.Web
      );
      const hospiceDocs = onBoardingDocs?.filter((data: any) => data?.DocumentType?.UploadFromId == DocumentUploadFromEnum?.Web)
      const ishospiceDocsAvailable = hospiceDocs?.length > 0
      setIsHospiceDocs(ishospiceDocsAvailable)

      const obBoarding = hospiceDocs
        ?.map((items: any) => items?.ProviderDocuments?.[0])
        .filter((providerDocuments: any) => providerDocuments)
        .map((providerDocuments: any) => ({
          id: providerDocuments?.Id,
          documentTypeId: providerDocuments?.DocumentTypeId,
          document: {
            documentUrl: providerDocuments?.Document?.DocumentUrl,
            fileName: providerDocuments?.Document?.FileName,
          },
        }));

      setUploadedFiles(obBoarding);
    }
  }, [providerData]);

  const notesHistoryContent = providerData?.Result?.ProviderNotes;

  useEffect(() => {
    if (viewData) {
      getproviderId({ id: viewData.Id });
    }
  }, [viewData]);

  useEffect(() => {
    if (viewData) {
      getDocumentById({ id: viewData.Id });
    }
  }, [viewData]);

  useEffect(() => {
    if (viewData) {
      const selectedProgram = providerData?.Result?.ProgramIds?.map(
        (id: any) => {
          const program = programData?.Result?.find((d: any) => d?.Id === id);
          return program ? { label: program?.Name, value: program?.Id } : null;
        }
      ).filter(Boolean);
      setSelectedProgram(selectedProgram ?? []);
    }
  }, [viewData, providerData?.Result?.ProgramIds?.length > 0]);

  useEffect(() => {
    if (companiesData && providerData) {
      const companysofDNR = providerData?.Result?.DNRCompanyIds;

      const matchedCompanies = companiesData?.Result?.filter((company: any) =>
        companysofDNR.includes(company?.Id)
      );

      setCompaniesOfDNR(matchedCompanies);
    }
  }, [companiesData, providerData]);

  useEffect(() => {
    if (viewData && programData?.Result) {
      const programIds = viewData?.ProgramIds || [];
      const filteredResults = programData.Result.filter((item: any) =>
        programIds.includes(item?.Id)
      );
      const names = filteredResults.map((item: any) => item?.Name);
      if (names?.length > 0) {
        const allNames = names.join(', ');
        setProgramNames(names);
        setIsProgramSelected(names?.length > 0 ? false : true);
      } else {
        setProgramNames([]);
      }
    }
  }, [viewData, programData]);

  const handleStatus = (status: any, id: any) => {
    if (status !== 'Reject' && status !== "RevertRejection") {
      if (!providerData?.Result?.IsAllCredentialApproved) {
        snackActions.error(
          'Please ensure all required documents are uploaded and verified before approving the profile.'
        );
        return;
      }
      if (isProgramSelected && !selectedProgram?.length) {
        snackActions.error('Please choose a program to proceed.');
        return;
      }
    }
    setShowDeleteConfirmModal(true);
    setshowStatusModel({ status, id });
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setshowStatusModel({ id: '', status: '' });
  };

  const updateProviderStatusAPI = async (
    status: any,
    id: any,
    reason: string
  ) => {
    let statusValue;
    if (status == 'Reject') {
      statusValue = 3;
    } else if (status == 'Approve' || status == 'Unblock') {
      statusValue = 2;
    } else if (status == 'RevertRejection') {
      statusValue = 1;
    } else if (status == 'Block') {
      statusValue = 4;
    }
    let selectedProgramArr =
      selectedProgram?.map((item: any) => item?.value) ?? [];

    if (statusValue === 2) {
      let res = await updateCliniianStatusAPI({
        providerId: viewData?.Id,
        programIds: selectedProgramArr,
      });
      if (!res?.IsSuccess) {
        snackActions.error(res?.Message);
        return;
      }
    }
    if (statusValue !== undefined) {
      let response = await updateProviderData({
        id: viewData?.Id,
        status: statusValue,
        reason: (statusValue == 3 ? reason : undefined),
      });
      if (response?.IsSuccess) {
        setShowDeleteConfirmModal(false);
        handleClose(true);
        snackActions.success(response?.Message);
        getDocumentById({ id: viewData.Id });
      } else {
        snackActions.error(response?.Message);
      }
    }
  };

  const uploadBackgroundCheckDocuments = async () => {
    if (getDocumentDataFromSelector?.length > 0) {
      let updatedProviderDocuments = getDocumentDataFromSelector?.filter(
        (e: any) => e?.parentId !== 14
      );
      let otherDocs = getDocumentDataFromSelector?.find(
        (e: any) => e?.parentId === 14
      );
      const updatedDocument = otherDocs?.providerDocuments?.map((item: any) => {
        return {
          ...item,
          id: item?.id,
        };
      });
      updatedProviderDocuments = [
        ...updatedProviderDocuments,
        ...updatedDocument,
      ];

      const onBoardingDocs = providerData?.Result?.Documents?.filter(
        (item: any) =>
          item?.DocumentType?.DocumentCategoryId ===
          DocumentCategoryEnum?.OnBoardDocument
      );

      const updatedOnBoardingDoc = onBoardingDocs
        ?.map((item: any) => item?.ProviderDocuments?.[0])
        ?.filter((doc: any) => doc?.Id !== undefined)
        ?.map((doc: any) => ({
          id: doc?.Id,
          documentTypeId: doc?.DocumentTypeId,
          document: {
            documentUrl: doc?.Document?.DocumentUrl,
            fileName: doc?.Document?.FileName,
            expiryDate: doc?.Document?.ExpiryDate,
          },
        }));

      const vitasCompanyData = companiesData?.Result?.find(
        (item: any) => item?.IsRequiredNdadocument
      );

      const { documentUrl, fileName } =
        getCompanyDocumentDataFromSelector[0]?.document;
      const selectedCompanyProviders = selectedCompany?.map((e: any) => {
        const isVitas = vitasCompanyData?.Id == e.value;
        const document = isVitas && fileName && documentUrl ? {
          documentUrl,
          fileName,
          expiryDate: null,
        } : null;
      
        return {
          companyId: e?.value,
          document,
        };
      });
      const getValueOfSelectedProgram = selectedProgram?.map(
        (item: any) => item?.value
      );
      let response = await updateProviderProfileAPI({
        id: viewData?.Id,
        providerDocuments: updatedProviderDocuments,
        providerOnboardDocuments: updatedOnBoardingDoc,
        companyProviders: selectedCompanyProviders,
        programIds: getValueOfSelectedProgram,
        note: clinicianNotes ?? '',
      });
      if (response?.IsSuccess) {
        handleClose(true);
        snackActions.success(response?.Message ?? 'Success!');
        getDocumentById({ id: viewData.Id });
      }
    }
  };

  const updateProviderReferenceStatus = async (values: any, reason: string) => {
    let statusValue;
    if (values.status == 'Reject') {
      statusValue = 3;
    } else if (values.status == 'Verify') {
      statusValue = 2;
    }
    let response = await updateReferenceStatus({
      id: values?.id,
      status: statusValue,
      reason: statusValue == 3 ? reason : undefined,
    });
    if (response?.IsSuccess) {
      handleProviderReferenceClose();
      snackActions.success(response?.Message);
      getproviderId({ id: viewData.Id });
    } else {
      snackActions.error(response?.message);
    }
  };

  const updateProviderSkillUpdateAPI = async (values: any, reason: string) => {
    let statusValue;
    if (values.status == 'Reject') {
      statusValue = 4;
    } else if (values.status == 'Verify') {
      statusValue = 2;
    }

    const documentId = values?.id?.Document
      ? values?.id?.Id
      : values?.id?.ProviderDocuments[0]?.Id;
    try {
      const updateFunction = values?.id?.Document
        ? updateProviderSkillData
        : updateProviderDocumentData;
      const response = await updateFunction({
        id: documentId,
        status: statusValue,
        reason: statusValue == 4 ? reason : undefined,
      });
      if (response?.IsSuccess) {
        snackActions.success(response?.Message);
        handleProviderSkillClose();
        getproviderId({ id: viewData?.Id });
      } else {
        snackActions.error(response?.Message || response?.message);
      }
    } catch (error) {
      snackActions.error('An unexpected error occurred.');
    }
  };

  const [documentType, setDocumentType] = useState('');
  const handleProviderSkillStatus = (status: any, id: any, type: any) => {
    if (viewData?.ProfileStatusId === 3) {
      snackActions.error(
        'Your profile has been rejected. You are not authorized to verify or reject any documents.'
      );
    } else {
      setShowUpdateSkillConfirmModal(true);
      const skillValue = {
        id: id,
        status: status,
      };
      setshowSkillModel(skillValue);
      setDocumentType(type);
    }
  };

  const handleProviderReference = (status: any, id: any) => {
    if (viewData?.ProfileStatusId === 3) {
      snackActions.error(
        'Your profile has been rejected. You are not authorized to verify or reject references.'
      );
    } else {
      setShowUpdateReferenceModal(true);
      const skillValue = {
        id: id?.[0]?.Id,
        status: status,
      };
      setShowReferenceModel(skillValue);
    }
  };

  const getDocumentDataFromSelector: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.providerDocuments
  );

  const getCompanyDocumentDataFromSelector: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.companyProviders
  );

  const [isEdit, setIsEdit] = useState([]);

  const providerViewData = providerData?.Result;

  const [expiryDates, setExpiryDates] = useState<Map<string, string>>(
    new Map()
  );
  const [documentUrls, setDocumentUrls] = useState<Map<any, any>>(new Map());

  useEffect(() => {
    if (detailsTypeList && getDocumentDataFromSelector) {
      const documents = getDocumentDataFromSelector;

      if (Array.isArray(documents) && Array.isArray(detailsTypeList)) {
        const expiryDateMap = new Map<string, string>();
        const documentUrlMap = new Map<string, string>();
        documents.forEach(doc => {
          const key = providerData?.Result?.Id ? doc?.parentId : doc?.id;
          if (key) {
            if (doc?.document?.expiryDate) {
              expiryDateMap.set(
                key,
                convertDateToMMDDYYYY(doc.document.expiryDate)
              );
            }
            if (doc?.document?.documentUrl) {
              documentUrlMap.set(key, doc.document.documentUrl);
            }
          }
        });
        setExpiryDates(expiryDateMap);
        setDocumentUrls(documentUrlMap);
        // const findDocumentId: any = detailsTypeList.filter(docId =>
        //   documents.some(
        //     doc =>
        //       (doc?.parentId ?? doc?.id) === docId.Id &&
        //       doc?.document?.documentUrl !== ''
        //   )
        // );
        const findDocumentId: any = detailsTypeList.filter(docId =>
          documents.some(doc => {
            const isParentIdValid = (doc?.parentId ?? doc?.id) === docId.Id;
            const hasProviderDocuments =
              doc?.parentId === 14
                ? doc?.providerDocuments?.length > 0
                : doc?.parentId === 1
                  ? doc?.document?.documentUrl !== undefined
                  : doc?.document?.documentUrl !== '';
            return isParentIdValid && hasProviderDocuments;
          })
        );

        const reliadDocs = documents?.filter((data: any) => data?.parentId == 14)
  const reliasDocsLength = reliadDocs[0]?.providerDocuments?.length

  setReliasDocsValue(reliasDocsLength)
        setIsEdit(findDocumentId);
      }
    }
  }, [detailsTypeList, getDocumentDataFromSelector, setIsEdit, providerData]);

  const handleProviderSkillClose = () => {
    setShowUpdateSkillConfirmModal(false);
    setshowSkillModel({ id: '', status: '' });
  };

  const handleProviderReferenceClose = () => {
    setShowUpdateReferenceModal(false);
    setshowSkillModel({ id: '', status: '' });
  };

  const handleVisibilityIconClickforClinician = (url: any) => {
    if (typeof url === 'string') {
      window.open(`${APP_URLs?.viewDocBaseURL}/${url}`, '_blank');
      return;
    }
    const docUrl = url?.ProviderDocuments[0]?.Document?.DocumentUrl ?? url;
    window.open(`${APP_URLs?.viewDocBaseURL}/${docUrl}`, '_blank');
  };

  const handleVisibilityIconClick = (url: string) => {
    const baseURL = APP_URLs?.viewDocBaseURL;
    window.open(`${baseURL}/${url}`, '_blank');
  };

  const handleModalCloseForVitas = (isSubmit: boolean) => {
    setShowVitasModal(false);
    if (isSubmit) {
    }
  };

  useEffect(() => {
    if (providerData) {
      const ProviderCompanies =
        providerData?.Result?.CompanyProviders?.map((company: any) => ({
          label: company.CompanyName,
          value: company.CompanyId,
          IsRequiredNdadocument: company?.IsRequiredNdadocument,
        })) ?? [];

      const companiesList = companiesData?.Result || [];
      const companyidsFromProviderCompanies = ProviderCompanies?.map(
        (id: any) => id?.value
      );
      const companyIdsArray = Array.isArray(companyidsFromProviderCompanies)
        ? companyidsFromProviderCompanies
        : Object.values(companyidsFromProviderCompanies).map(Number);
      const matchedCompany = companiesList.filter((company: any) =>
        companyIdsArray.includes(company.Id)
      );

      const selectedCompanies =
        matchedCompany?.map((company: any) => ({
          label: company.CompanyName,
          value: company.Id,
          IsRequiredNdadocument: company?.IsRequiredNdadocument,
        })) ?? [];
      setSelectedCompanay(selectedCompanies);

      const companyProviderFromAPI = selectedCompanies?.filter(
        (id: any) => id?.IsRequiredNdadocument
      );

      const vitasData = providerData?.Result?.CompanyProviders?.filter(
        (id: any) => companyProviderFromAPI[0]?.value == id?.CompanyId
      );

      const payloadTypeForCompanyProvider = [
        {
          companyId: vitasData[0]?.CompanyId ?? '',
          document: {
            documentUrl: vitasData[0]?.DocumentURL ?? '',
            fileName: vitasData[0]?.FileName ?? '',
            expiryDate: '',
          },
        },
      ];

      dispatch(setCompanyProviders(payloadTypeForCompanyProvider));

      
  const reliadDocs = providerViewData?.ProviderBackgroundChecks?.filter((data: any) => data?.DocumentType?.Id == 14)
  const reliasDocsLength = reliadDocs[0]?.ProviderDocuments?.length

  setReliasDocsValue(reliasDocsLength)
    }
  }, [providerData]);

  const handleEditDocumentClick = (items: any) => {};

  const handleProgramChange = (fiedname: any, value: any) => {
    setSelectedProgram(value ?? []);
    setIsProgramSelected(value?.length === 0);
  };

  const handlecompanychange = (fiedname: any, value: any) => {
    setSelectedCompanay(value);
  };

  const [getIdOnDocumentClick, setIdOnDocumentClick] = useState('');
  const [docData, setDocData] = useState<any[] | undefined>();

  const [reliasData, setReliasData] = useState<any[] | undefined>();

  const handleDocumentClick = (items: any) => {
    const providerDocumentsArray = providerData?.Result?.Documents?.find(
      (item: any) => {
        const resultId = item?.ProviderDocument
          ? item?.ProviderDocument.ParentDocumentTypeId
          : item?.DocumentType?.Id;
        return resultId === items?.Id;
      }
    );

    setIdOnDocumentClick(providerDocumentsArray);

    if (items === 'references') {
      setShowRefAddEditModal(true);
    } else if (items === 'vitasDocument') {
      setShowVitasModal(true);
    } else if (items.Id == 14) {
      const data = getProviderDocumentData?.filter(
        (doc: any) => doc.parentId === items.Id
      );
      const filter = providerData.Result?.ProviderBackgroundChecks?.find(
        (doc: any) => doc.DocumentType.Id == 14
      );
      setDocData(filter);
      setReliasData(data);
      setShowAddEditRealisModal(true);
      setSelectedDocumentType(items);
    } else {
      setShowAddEditModal(true);
      setSelectedDocumentType(items);
    }
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
  };

  const handleModalCloseForRef = (isSubmit: boolean) => {
    setShowRefAddEditModal(false);
    if (isSubmit) {
    }
  };

  const handleModalCloseForRelias = (isSubmit: boolean) => {
    setShowAddEditRealisModal(false);
    if (isSubmit) {
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      const isVitasDocumentShow = selectedCompany?.some(
        (item: any) => item?.IsRequiredNdadocument
      );
      setShowVitasDocument(isVitasDocumentShow);
    }
  }, [selectedCompany, [], providerData]);

  const handleChange = (event: any) => {
    const value = event.target.value;
              if (value.startsWith(' ')) {
                event.target.value = value.trimStart();
              }
    setClinicianNotes(event.target.value);
    dispatch(setClinicianNotesForViewScreen(event.target.value))
  };

  const isShowHistory =
    providerViewData?.ProviderSuspensionHistory?.length ||
    providerViewData?.ProviderTerminationHistory?.length ||
    providerViewData?.ProviderDNRHistory?.length;

  const slicedNotesValue = notesHistoryContent?.slice(1)

  const message = providerData?.Result?.ProfileStatus?.Message ?? '-';
  
  const isLongMessage = message.length > 50;

  const truncatedMessage = message.substring(0, 50);
  const clinicianStatus = providerData?.Result?.ProfileStatusId

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-950px"
      show={show}
      onHide={() => (dispatch(resetAllDocumentsValues), handleClose(false))}
      backdrop="static"
    >
      <div className="modal-header px-5 py-2 align-items-center viewClinicianheader">
        <h2 className="mb-0">{title ? title : 'View Clinician'}</h2>
        <div
          className="btn btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <span className="material-symbols-outlined closeBtn">cancel</span>
        </div>
      </div>

      {isloadinngprovider ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="viewClinicianModel">
          <form>
            <div className="p-6">
              <Row className="my-5">
                <Col sm={3} xs={12}>
                  <div className="mb-4 mb-sm-0">
                    <label
                      htmlFor="profile-img"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer clinicianUserlabel"
                    >
                      {providerData?.Result?.ProfilePictureUrl ? (
                        <img
                          className="clinicianUserimg"
                          src={`${APP_URLs?.viewDocBaseURL}/${providerData?.Result?.ProfilePictureUrl}`}
                        />
                      ) : (
                        <Userprofile
                          className="clinicianSvgimage"
                          onClick={() => {
                            const inputElement =
                              document.getElementById('profile-img');
                            if (inputElement) {
                              inputElement.click();
                            }
                          }}
                        />
                      )}
                    </label>
                  </div>
                </Col>

                <Col sm={5} xs={12}>
                  <Row className="gy-1">
                    <label className="clinicianName">
                      {providerData?.Result?.FirstName +
                        ' ' +
                        providerData?.Result?.LastName}
                    </label>
                    <label className="clinicianNumber">
                      {providerData?.Result?.MobileNumber}
                    </label>
                    <div style={{ alignItems: 'center' }}>
                      <label className="clinicianAddress">
                        {providerData?.Result?.Address},
                        {providerData?.Result?.CityName},{' '}
                        {providerData?.Result?.StateAcronym},{' '}
                        {providerData?.Result?.CountryName},{' '}
                        {providerData?.Result?.ZipCode}
                      </label>
                    </div>
                  </Row>
                </Col>

                <Col sm={4} xs={12}>
                  <div className="mt-5 mt-sm-0">
                    <div
                      className={`d-flex align-items-center justify-content-between mb-3`}
                    >
                      <div className="d-flex align-items-center">
                        <h5 className="clinicianStatus me-2">Status:</h5>
                        <p
                          className={`m-0 ${providerData?.Result?.ProfileStatusId === 2 ? 'statusApprove' : providerData?.Result?.ProfileStatusId === 1 ? 'statusPending' : 'statusSuspend'}`}
                        >
                          {providerData?.Result?.ProfileStatusName}
                        </p>
                      </div>
                      {isShowHistory ? (
                        <div className="d-flex">
                          <h5 className="clinicianStatus me-2">
                            Status history:
                          </h5>
                          <IconButton
                            onClick={handleHistoryClick}
                            className="p-0"
                          >
                            <HistoryIcon
                              style={{
                                color: '#ff3f3f',
                                height: '22px',
                                width: '22px',
                              }}
                            />
                          </IconButton>
                        </div>
                      ) : null}
                    </div>
                    {providerData?.Result?.DNRCompanyIds?.length !== 0 && (
                      <div>
                        <div>
                          <h5 className="dnrCompanyList">DNR Facility List:</h5>
                          <ol>
                            {companiesOfDNR.map((company, index) => (
                              <li key={index} style={{ fontWeight: 'bold' }}>
                                {company.CompanyName}
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    )}
                    {providerData?.Result?.DNRRemarks && (
                      <div className="row">
                        <div className="col-12 d-flex align-items-baseline">
                          <h5 className="me-2 dnrCompanyList">DNR Reason:</h5>
                          <p className="mb-2 programs-text">
                            {providerData?.Result?.DNRRemarks ?? '-'}
                          </p>
                        </div>
                      </div>
                    )}
                    {providerData?.Result?.ProfileStatusId !== 2 &&
                      providerData?.Result?.ProfileStatusId !== 1 && (
                        <div className="row">
                          <div className="col-12 d-flex align-items-baseline">
                            <h5 className="me-2 dnrCompanyList">Reason:</h5>
                            <p className="mb-2 programs-text" style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, overflow: 'hidden' }}>
                            {showMore ? message : truncatedMessage}
        
        {/* Show "more" link if the message is long */}
        {isLongMessage && (
          <span
            style={{ color: '#007bff', cursor: 'pointer' }}
            onClick={handleReasonClick} // Expand message on click
          >
            ... more
          </span>
        )}
                            </p>
                            
                            <Popover
                            id={reasonId}
                            open={openReason as any}
                            anchorEl={anchorElForReason}
                            onClose={handleCloseReason}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            PaperProps={{
                              style: { padding: '16px',           // Add padding for better spacing
                                borderRadius: '8px',       // Round corners for a softer look
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',  // Subtle shadow for depth
                                minWidth: '200px',         // Minimum width for consistency
                                maxWidth: '300px',     },
                            }}
                          >
 <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2" color="textSecondary" style={{ fontWeight: 'bold' }}>
          Reason:
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2" color="textPrimary" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          {providerData?.Result?.ProfileStatus?.Message ??
                                '-'}
        </Typography>
      </Box>
                          </Popover>
                          </div>
                        </div>
                      )}

                    <ShowClinicianHistoryInPopup
                      id={HisotryId}
                      open={openHisory as any}
                      anchorEl={anchorElForHistory}
                      onClose={handleCloseHistory}
                      notes={providerViewData}
                      title="Status History"
                    />
                  </div>
                  {isSuperAdmin && (
                    <div className="d-flex justify-content-sm-start pt-sm-3 pt-0">
                      {providerData?.Result?.ProfileStatusId === 1 && (
                        <div>
                          <Button
                            id="viewStstusrejectBtn"
                            size="sm"
                            onClick={() =>
                              handleStatus('Reject', { id: viewData?.Id })
                            }
                            variant="secondary"
                          >
                            Reject
                          </Button>

                          <Button
                            id="viewStstusApproveBtn"
                            size="sm"
                            onClick={() =>
                              handleStatus('Approve', { id: viewData?.Id })
                            }
                            variant="primary"
                            disabled={clinicianStatus == 3}
                          >
                            Approve
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {providerData?.Result?.ProfileStatusId === 3 && (
                    <div className="d-flex justify-content-start pt-2">
                      <Button
                        className="approveStatus"
                        size="sm"
                        onClick={() =>
                          handleStatus('Approve', { id: viewData?.Id })
                        }
                        disabled={isProgramSelected || clinicianStatus == 3}
                        variant="secondary"
                        style={{
                          backgroundColor: isProgramSelected
                            ? '#e0e0e0'
                            : '#027EA8',
                          color: isProgramSelected ? 'black' : '#ffffff',
                        }}
                      >
                        Approve
                      </Button>

                            <Button
                            id="viewStatusRevertRejectBtn"
                            size="sm"
                            onClick={() =>
                              handleStatus('RevertRejection', { id: viewData?.Id })
                            }
                            variant="primary"
                          >
                            Revert Rejection
                          </Button>
                    </div>
                  )}
                </Col>
              </Row>

              <Row className="mb-4 gy-3">
                {viewData?.ProfileStatusId != 2 &&
                  !viewData?.ProgramIds?.length && (
                    <>
                      <Col sm={6} xs={12}>
                        <label className="form-label fw-bold">
                          Select Program
                          <span className="fw-bolder mt-5 text-danger">
                            {''} *
                          </span>
                        </label>
                        <div className="selectionBorder">
                          <MultiSelect
                            options={
                              programData
                                ? programData?.Result?.map((d: any) => {
                                    return { label: d?.Name, value: d?.Id };
                                  })
                                : []
                            }
                            value={selectedProgram || []}
                            onChange={(selected: any) =>
                              handleProgramChange('selectedProgram', selected)
                            }
                            labelledBy="Select Programs..."
                            className={`form-control form-control-lg form-control-solid p-1 ${clinicianStatus == 3 ? "clinicianNotesDisable" : "bg-white"}`}
                            disabled={clinicianStatus == 3}
                          />
                        </div>
                      </Col>
                    </>
                  )}
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    Select Facility{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>
                  <div className="selectionBorder">
                    <MultiSelect
                      options={
                        companiesData && !isLoadingCompanies
                          ? companiesData?.Result?.map((d: any) => {
                              return {
                                label: d?.CompanyName,
                                value: d?.Id,
                                IsRequiredNdadocument: d?.IsRequiredNdadocument,
                              };
                            })
                          : []
                      }
                      value={selectedCompany || []}
                      onChange={(selected: any) =>
                        handlecompanychange('selectedCompanies', selected)
                      }
                      labelledBy="Select"
                      overrideStrings={{
                        selectSomeItems: 'Select facilities...',
                      }}
                      className={`form-control form-control-lg form-control-solid p-1 ${clinicianStatus == 3 ? "clinicianNotesDisable" : "bg-white"}`}
                      disabled={clinicianStatus == 3}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-5 ">
                    <div className="d-flex justify-content-between">
                      <label className="form-label fw-bold">Notes</label>
                      {notesHistoryContent &&
                      notesHistoryContent?.length > 1 ? (
                        <label className="form-label fw-bold">
                          Notes history:{' '}
                          <IconButton
                            onClick={handleHistoryClickForNotes}
                            className="p-0"
                          >
                            <HistoryIcon
                              style={{
                                color: '#ff3f3f',
                                height: '22px',
                                width: '22px',
                              }}
                            />
                          </IconButton>
                        </label>
                      ) : (
                        ''
                      )}
                    </div>
                    <ShowNotesHistoryInPopup
                      id="note-view-history-popover"
                      open={openHisoryForNotes}
                      anchorEl={anchorElForNotesHistory}
                      onClose={handleCloseHistoryForNotes}
                      title="Notes History"
                      notes={slicedNotesValue}
                    />
                    <div className="selectionBorder">
                      <textarea
                        className={`form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite ${clinicianStatus == 3 ? "clinicianNotesDisable" : "bg-white"}`}
                        placeholder="Write Something!"
                        name="clinicianNotes"
                        rows={4}
                        cols={50}
                        value={clinicianNotes}
                        onChange={handleChange}
                        disabled={clinicianStatus == 3}
                      />
                    </div>
                  </div>
                </Col>
                {viewData?.ProfileStatusId === 2 ||
                viewData?.ProgramIds?.length ? (
                  <>
                    <div>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="clinicianName fw-semibold"
                      >
                        Assigned Programs :
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        {programNames.map((name, index) => (
                          <Grid item key={index}>
                            <Chip
                              className="assigned-programs"
                              label={name}
                              variant="outlined"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </>
                ) : null}
              </Row>
              <hr />
              <div className="main-clinician-document">
                <label className="form-label fw-bold mb-4 fontTitle">
                  Clinician Documents{' '}
                  {/* <span className="fw-bolder mt-5 text-danger">*</span> */}
                </label>
                <div>
                  <Row className="gy-7">
                    {providerData?.Result?.ProviderDocuments?.map(
                      (item: any, index: any) => (
                        <>
                          <Col md={6} xs={12} key={item?.Id}>
                            <h5 className="mb-3">
                              {item?.DocumentType?.DocumentType}
                            </h5>
                            <div className={`${clinicianStatus == 3 ? "rejectedclinician" : "documentBox"}`}>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="documentNametext">
                                  {item?.DocumentType?.Id === 1
                                    ? providerData?.Result?.ProviderSkill
                                        ?.Document?.FileName
                                      ? charValidate(
                                          providerData?.Result?.ProviderSkill
                                            ?.Document?.FileName,
                                          25
                                        )
                                      : '-'
                                    : item?.ProviderDocuments[0]?.Document
                                          ?.FileName
                                      ? charValidate(
                                          item?.ProviderDocuments[0]?.Document
                                            ?.FileName,
                                          25
                                        )
                                      : '-'}{' '}
                                  {/* {item?.ProviderDocuments[0]?.Document
                                    ?.FileName != '' ||
                                    (providerData?.Result?.ProviderSkill
                                      ?.Document?.FileName != '' && (
                                      <span className="fw-bolder mt-5 text-danger me-3">
                                        *
                                      </span>
                                    ))} */}
                                  {(item?.DocumentType?.Id == 1
                                    ? providerData?.Result?.ProviderSkill !=
                                      null
                                    : item?.ProviderDocuments[0] != null) && (
                                    <Visibility
                                      onClick={() =>
                                        handleVisibilityIconClickforClinician(
                                          item?.DocumentType?.Id === 1
                                            ? providerData?.Result
                                                ?.ProviderSkill?.Document
                                                ?.DocumentUrl
                                            : item
                                        )
                                      }
                                    />
                                  )}
                                  {(item?.DocumentType?.Id == 1
                                    ? providerData?.Result?.ProviderSkill
                                        ?.Remarks != null
                                    : item?.ProviderDocuments[0]?.Remarks !=
                                      null) && (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-top">
                                          {item?.DocumentType?.Id == 1
                                            ? providerData?.Result
                                                ?.ProviderSkill?.Remarks
                                            : item?.ProviderDocuments[0]
                                                ?.Remarks}
                                        </Tooltip>
                                      }
                                    >
                                      <IconButton className="ms-2">
                                        <InfoIcon color="error" />
                                      </IconButton>
                                    </OverlayTrigger>
                                  )}
                                </p>
                                <div className="d-flex flex-row align-items-center mt-1">
                                  {(item?.DocumentType?.Id == 1
                                    ? providerData?.Result?.ProviderSkill
                                        ?.StatusId == 1
                                    : item?.ProviderDocuments[0]?.StatusId ==
                                      1) && (
                                    <Button
                                      id="documentVerifyBtn"
                                      size="sm"
                                      variant="secondary"
                                      onClick={() =>
                                        handleProviderSkillStatus(
                                          'Verify',
                                          item?.DocumentType?.Id == 1
                                            ? providerData?.Result
                                                ?.ProviderSkill
                                            : item,
                                          item?.DocumentType?.DocumentType
                                        )
                                      }
                                      disabled={clinicianStatus == 3}
                                    >
                                      <Verify className="me-1" />
                                      Verify
                                    </Button>
                                  )}
                                  {(item?.DocumentType?.Id == 1
                                    ? providerData?.Result?.ProviderSkill
                                        ?.StatusId == 2
                                    : item?.ProviderDocuments[0]?.StatusId ==
                                      2) && (
                                    <div className="d-flex align-items-center">
                                      <h5 className='clinicianVerified'>
                                        <ClinicianVerified className="me-1" />
                                        Verified
                                      </h5>
                                    </div>
                                  )}
                                  {(item?.DocumentType?.Id == 1
                                    ? providerData?.Result?.ProviderSkill
                                        ?.StatusId == 4
                                    : item?.ProviderDocuments[0]?.StatusId ==
                                      4) && (
                                    <span>
                                      <div className="d-flex">
                                        <Rejected className="mt-1 me-1" />
                                        <p className="documentReject">
                                          Rejected
                                        </p>
                                      </div>
                                    </span>
                                  )}
                                  {(item?.DocumentType?.Id == 1
                                    ? providerData?.Result?.ProviderSkill
                                        ?.StatusId == 1
                                    : item?.ProviderDocuments[0]?.StatusId ==
                                      1) && (
                                    <Button
                                      id="documentRejectBtn"
                                      size="sm"
                                      variant="secondary"
                                      onClick={() =>
                                        handleProviderSkillStatus(
                                          'Reject',
                                          item?.DocumentType?.Id == 1
                                            ? providerData?.Result
                                                ?.ProviderSkill
                                            : item,
                                          item?.DocumentType?.DocumentType
                                        )
                                      }
                                      disabled={clinicianStatus == 3}
                                    >
                                      <Clinicianrejecte className="me-1" />
                                      Reject
                                    </Button>
                                  )}
                                </div>
                              </div>
                              <div className={`d-flex justify-content-between pt-2 mt-1 ${item?.DocumentType?.Id != 6 ? "documentTypeBorder" : ""}`}>
                                <div>
                                  {item?.DocumentType?.Id !== 4 &&
                                    item?.DocumentType?.Id !== 6 && (
                                      <p className="mb-0 documentType">
                                        Type:{' '}
                                        {item?.DocumentType?.Id === 1
                                          ? (providerData?.Result?.ProviderSkill
                                              ?.SkillName ?? '-')
                                          : (item?.Childrens?.find(
                                              (type: any) =>
                                                type?.Id ===
                                                item?.ProviderDocuments[0]
                                                  ?.DocumentTypeId
                                            )?.DocumentType ?? '-')}
                                      </p>
                                    )}
                                </div>
                                <div>
                                  {item?.DocumentType?.Id != 5 &&
                                    item?.DocumentType?.Id != 6 && (
                                      <>
                                      <p className="mb-0 documentType">
                                        Expiry Date:{' '}
                                        <span
                                          style={{
                                            color: (item?.DocumentType?.Id === 1
                                              ? providerData?.Result?.ProviderSkill
                                                ?.StatusId == 3 : item?.ProviderDocuments[0]?.StatusId === 3) ? 'red' : 'inherit'
                                          }}
                                        >
                                        {item?.DocumentType?.Id === 1
                                          ? providerData?.Result?.ProviderSkill
                                              ?.Document?.ExpiryDate
                                            ? convertDateToMMDDYYYY(
                                                providerData?.Result
                                                  ?.ProviderSkill?.Document
                                                  ?.ExpiryDate
                                              )
                                            : '-'
                                          : item?.ProviderDocuments[0]?.Document
                                                ?.ExpiryDate
                                            ? convertDateToMMDDYYYY(
                                                item?.ProviderDocuments[0]
                                                  ?.Document?.ExpiryDate
                                              )
                                            : '-'}
                                            </span>
                                      </p>
                                      </>
                                    )}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </>
                      )
                    )}
                  </Row>
                </div>
              </div>

              <div className="mt-5">
                <Row>
                  <Col md={6} xs={12}>
                    <h5>References</h5>
                    <div className={`mb-3 ${clinicianStatus == 3 ? "rejectedclinician" : "documentBox"}`}>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <p className="documentNametext">
                            <span className="me-2">References List</span>
                          </p>
                          <IconButton onClick={handleClick}>
                            <InfoIcon style={{ fill: '#103558' }} />
                          </IconButton>
                          <Popover
                            id={id}
                            open={openRef as any}
                            anchorEl={anchorEl}
                            onClose={handleCloseRef}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            PaperProps={{
                              style: { padding: 0 },
                            }}
                          >
                            <Typography
                              className="mb-0 py-3 px-5"
                              variant="h5"
                              gutterBottom
                            >
                              References List
                            </Typography>

                            <Box sx={{ borderBottom: '2px solid #919eab66' }} />

                            <Box>
                              {providerData?.Result?.ProviderReferences &&
                              providerData?.Result?.ProviderReferences?.length >
                                0
                                ? providerData?.Result?.ProviderReferences?.map(
                                    (item: any, index: any) => (
                                      <Box
                                        key={index}
                                        sx={{
                                          padding: '10px 16px',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          minWidth: '230px',
                                          // mb: 2,
                                          borderBottom:
                                            index <
                                            providerData?.Result
                                              ?.ProviderReferences?.length -
                                              1
                                              ? '2px solid #919eab66'
                                              : '',
                                          // pb: 2,
                                        }}
                                      >
                                        <Typography className="mb-1">
                                          <PersonIcon
                                            sx={{
                                              mr: 1,
                                              color: '#1B676A',
                                              height: '16px',
                                              width: '16px',
                                            }}
                                          />
                                          {item?.FirstName} {item?.LastName}
                                        </Typography>
                                        <Typography className="mb-1">
                                          <EmailIcon
                                            sx={{
                                              mr: 1,
                                              color: '#1B676A',
                                              height: '16px',
                                              width: '16px',
                                            }}
                                          />
                                          {item?.Email}
                                        </Typography>
                                        <Typography className="mb-1">
                                          <CallIcon
                                            sx={{
                                              mr: 1,
                                              color: '#1B676A',
                                              height: '16px',
                                              width: '16px',
                                            }}
                                          />
                                          {item?.PhoneNumber}
                                        </Typography>
                                        <Typography className="mb-1">
                                          <WorkIcon
                                            sx={{
                                              mr: 1,
                                              color: '#1B676A',
                                              height: '16px',
                                              width: '16px',
                                            }}
                                          />
                                          {item?.Designation}
                                        </Typography>
                                      </Box>
                                    )
                                  )
                                : 'No reference available.'}
                            </Box>
                          </Popover>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          {providerData?.Result?.ProviderReferences.some(
                            (sId: any) => sId?.StatusId === 1
                          ) &&
                            providerData?.Result?.ProviderReferences[0]
                              ?.StatusId != 2 &&
                            providerData?.Result?.ProviderReferences[0]
                              ?.StatusId != 3 && (
                              <>
                                <Button
                                  id="documentVerifyBtn"
                                  size="sm"
                                  variant="secondary"
                                  onClick={() =>
                                    handleProviderReference(
                                      'Verify',
                                      providerData?.Result?.ProviderReferences
                                    )
                                  }
                                  disabled={clinicianStatus == 3}
                                >
                                  <Verify className="me-1" />
                                  Verify
                                </Button>

                                <Button
                                  id="documentRejectBtn"
                                  size="sm"
                                  variant="secondary"
                                  onClick={() =>
                                    handleProviderReference(
                                      'Reject',
                                      providerData?.Result?.ProviderReferences
                                    )
                                  }
                                  disabled={clinicianStatus == 3}
                                >
                                  <Clinicianrejecte className="me-1" />
                                  Reject
                                </Button>
                              </>
                            )}
                          {providerData?.Result?.ProviderReferences?.some(
                            (sId: any) => sId?.StatusId === 2
                          ) && (
                            <div className="d-flex align-items-center">
                              <h5  className='clinicianVerified'>
                                <ClinicianVerified className="me-1" />
                                Verified
                              </h5>
                            </div>
                          )}

                          {providerData?.Result?.ProviderReferences?.some(
                            (sId: any) => sId?.StatusId === 3
                          ) && (
                            <span>
                              <div className="d-flex">
                                <Rejected className="mt-1 me-1" />
                                <p className="documentReject">Rejected</p>
                              </div>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <hr />
                  <div className="main-clinician-document">
                    <label className="form-label fw-bold mb-4 fontTitle">
                      Background Check{' '}
                      {/* <span className="fw-bolder mt-5 text-danger">*</span> */}
                    </label>
                    <div>
                      <Row className="gy-7">
                        {backgroundCheckDocuments?.map(
                          (item: any, index: any) => (
                            <Col md={6} xs={12} key={item?.Id}>
                              <div className={`${clinicianStatus == 3 ? "rejectedclinician" : "documentBox"}`}>
                                <div className="d-flex justify-content-between">
                                  <div
                                    className="d-flex align-items-center justify-content-between w-100"
                                    onClick={() => clinicianStatus !== 3 && handleDocumentClick(item)}
                                  >
                                    <p className="documentNametext">
                                      {item.DocumentType}{' '}
                                      {/* <span className="fw-bolder mt-5 text-danger">
                                        *
                                      </span> */}
                                    </p>
                                    {isEdit?.some(
                                      (docId: any) => docId.Id === item.Id
                                    ) ? (
                                      <span
                                        onClick={() =>
                                          handleEditDocumentClick(item)
                                        }
                                      >
                                        {clinicianStatus == 3 ? <ClinicianRejectedEditIcon /> : <ClinicianEdit />}
                                      </span>
                                    ) : (
                                      <ArrowForwardIosIcon />
                                    )}
                                  </div>
                                  {documentUrls.get(item.Id) &&
                                    item.Id !== 14 && (
                                      <span
                                        className="ms-3"
                                        onClick={() =>
                                          handleVisibilityIconClick(
                                            documentUrls.get(item.Id)
                                          )
                                        }
                                      >
                                        <Visibility />
                                      </span>
                                    )}
                                </div>
                                <div className={`d-flex justify-content-end pt-2 mt-1 ${isEdit?.some(
                                      (docId: any) => docId.Id === item.Id
                                    ) ? "documentTypeBorder": ""}`}>
                                  {expiryDates?.get(item.Id) &&
                                    item.Id !== 14 ? (
                                      <span className="mb-0 backgroundExpiry">
                                        {' '}
                                        Expiry Date:{' '}
                                        {expiryDates.get(item.Id) ||
                                          'No Expiry Date'}
                                      </span>
                                    ):(
item?.Id == 14 && reliasDocsValue ? (
  <span>{reliasDocsValue} Documents Uploaded</span>
): null
                                    )}
                                </div>
                              </div>
                            </Col>
                          )
                        )}
                        {/* {showVitasDocument && ( */}
                          <>
                            <label className="form-label fw-bold mb-4 fontTitle">
                              VITAS Document{' '}
                              {/* <span className="fw-bolder mt-5 text-danger">
                                *
                              </span> */}
                            </label>

                            <Col md={6} xs={12} className='mt-0'>
                              <div className={`${clinicianStatus == 3 || !showVitasDocument ? "rejectedclinician" : "documentBox"}`}>
                                <div className="d-flex justify-content-between">
                                  <div
                                    className="d-flex justify-content-between align-items-center w-100"
                                    onClick={() =>
                                      clinicianStatus !== 3 && showVitasDocument && handleDocumentClick('vitasDocument')
                                    }
                                  >
                                    <p className="documentNametext">
                                      VITAS Document
                                    </p>
                                    <span>
                                      {getCompanyDocumentDataFromSelector[0]
                                        ?.document?.documentUrl != '' ? (
                                        (showVitasDocument && (clinicianStatus == 3 ? <ClinicianRejectedEditIcon /> : <ClinicianEdit />))
                                      ) : (
                                        (showVitasDocument && <ArrowForwardIosIcon />)
                                      )}
                                    </span>
                                  </div>
                                  {getCompanyDocumentDataFromSelector[0]
                                    ?.document?.documentUrl != '' && (
                                    <span
                                      className="ms-3"
                                      onClick={() =>
                                        handleVisibilityIconClick(
                                          getCompanyDocumentDataFromSelector[0]
                                            ?.document?.documentUrl
                                        )
                                      }
                                    >
                                      {showVitasDocument && <Visibility />}
                                    </span>
                                  )}
                                </div>
                                {clinicianStatus == 3 || !showVitasDocument && (
                                  <div className="d-flex justify-content-between align-items-center mt-2">
      <span className="text-muted" style={{ fontSize: '14px' }}>
        <i className="bi bi-info-circle-fill text-info me-2"></i>
        Please select a VITAS Health Care facility to upload the VITAS document
      </span>
    </div>
                                )}
                              </div>
                            </Col>
                          </>
                        {/* // )} */}
                      </Row>
                    </div>
                  </div>
                </Row>
              </div>
              {isSuperAdmin && (
                <>
                {/* {isSuperAdmin && isHospiceDocs && (
                                <>
                                  <div className="mt-6">
                                    <label className="form-label fw-bold fontTitle">
                                      Hospice Onboarding Documents{' '}
                                      <span className="fw-bolder mt-5 requriedField">*</span>
                                    </label>
                                  </div>
                                  <hr />
                                  <AddEditHospiceDocs
                                    editData={viewData}
                                    uploadedFiles={uploadedFiles}
                                    setUploadedFiles={setUploadedFiles}
                                    currentPage={'view_clinician'}
                                  />
                                </>
                              )} */}
                  <div className="mt-4">
                    <label className="form-label fw-bold fontTitle">
                      Thrive Onboarding Documents{' '}
                      {/* <span className="fw-bolder mt-5 requriedField">*</span> */}
                    </label>
                  </div>
                  <hr />
                  <ViewOnBoardingDocs
                    editData={viewData}
                    providerData={providerData}
                    setShowUpdateSkillConfirmModal={
                      setShowUpdateSkillConfirmModal
                    }
                    setDocumentType={setDocumentType}
                    setshowSkillModel={setshowSkillModel}
                  />
                </>
              )}
            </div>
            <div className="w-100 d-flex align-items-end justify-content-end pb-4 px-6">
              {providerData?.Result?.ProfileStatusId === 3 ? null : (
                <div>
                  <Button
                    type="button"
                    size="lg"
                    variant="primary"
                    className="clinicianSaveBtn"
                    onClick={uploadBackgroundCheckDocuments}
                  >
                    {isProviderProfileLoading ? 'Loading...' : 'Save'}
                  </Button>
                </div>
              )}
            </div>
          </form>
        </div>
      )}

      {showDeleteConfirmModal && (
        <ConfirmEventPopup
          showPopup={showDeleteConfirmModal}
          handleClose={handleCloseDelete}
          handleConfirmed={reason =>
            updateProviderStatusAPI(
              showStatusModel.status,
              showStatusModel.id,
              reason
            )
          }
          loading={isUpdateProfile}
          title={'Update Status'}
          rejectReasonRequired={showStatusModel.status}
          // message={`Are you sure, do you want to ${showStatusModel.status} this Profile?`}
          message={
            showStatusModel.status === "RevertRejection" 
              ? "Are you sure, do you want to remove user from rejection?" 
              : `Are you sure, do you want to ${showStatusModel.status} this Profile?`
          }
        />
      )}

      {showUpdateSkillConfirmModal && (
        <ConfirmEventPopup
          showPopup={showUpdateSkillConfirmModal}
          handleClose={handleProviderSkillClose}
          handleConfirmed={reason =>
            updateProviderSkillUpdateAPI(showSkillModel, reason)
          }
          loading={isShowLoaderOnRejectionPop}
          rejectReasonRequired={showSkillModel.status}
          title={`${documentType} Status`}
          message={`Are you sure, do you want to ${showSkillModel.status} ${documentType}?`}
        />
      )}

      {showUpdateReferenceModal && (
        <ConfirmEventPopup
          showPopup={showUpdateReferenceModal}
          handleClose={handleProviderReferenceClose}
          handleConfirmed={reason =>
            updateProviderReferenceStatus(showReferenceModel, reason)
          }
          loading={isProviderReferenceUpdate}
          rejectReasonRequired={showReferenceModel.status}
          title={'Reference Status'}
          message={`Are you sure, do you want to ${showReferenceModel.status} the reference?`}
        />
      )}

      {showAddEditModal && (
        <AddEditClinicianDocs
          editData={viewData}
          show={showAddEditModal}
          handleClose={handleModalClose}
          type={selectedDocumentType}
          providerSkillFromAPI={providerData?.Result}
          getIdOnDocumentClick={getIdOnDocumentClick}
          companiesData={companiesData}
          selectedCompany={selectedCompany}
          selectedProgram={selectedProgram}
          currentPageIsView={true}
        />
      )}

      {showAddEditRealisModal && (
        <AddEditReliasDocs
          editData={viewData}
          show={showAddEditRealisModal}
          handleClose={handleModalCloseForRelias}
          type={selectedDocumentType}
          reliasData={reliasData}
          docData={docData}
          getIdOnDocumentClick={getIdOnDocumentClick}
        />
      )}

      {showRefAddEditModal && (
        <AddEditRefDocs
          editData={viewData}
          show={showAddEditModal}
          handleClose={handleModalCloseForRef}
          type={selectedDocumentType}
        />
      )}

      {showVitasModal && (
        <AddEditVitasDoc
          editData={viewData}
          show={showVitasModal}
          handleClose={handleModalCloseForVitas}
          type={2}
          companyProviderDataFromAPI={providerData?.Result?.CompanyProviders}
          getIdOnDocumentClick={getIdOnDocumentClick}
        />
      )}
    </Modal>,
    modalsRoot
  );
};

export default ViewProvider;
