import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import * as Yup from 'yup';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import { uploadPicture, editdocumentTypeData, documentTypeData } from '../api';
import './document.css';
import { useMutation } from 'react-query';

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: {
    Id: number;
    DocumentType: string;
    DocumentCategoryId: number;
    DocumentCategory: string;
    HasParent: boolean;
    ParentId: number | null;
    HasExpiry: boolean;
    IsAllChildDocumentsRequired: boolean;
    SampleDocumentUrl: string;
    UploadFromId: number
  };
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddEditDocument = ({
  show,
  handleClose,
  editData,
}: Props) => {
  const [documentImgURL, setDocumentImgURL] = useState<string>('');
  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [selectedDocumentFor, setSelectedDocumentFor] = useState<number>(0);
  const [fileName, setFileName] = useState<string>('');

  const validationSchema = Yup.object().shape({
    documentType: Yup.string().required('Document Type is required'),
    documentIsFor: Yup.string().required('Type is required')
    // documentCategory: Yup.string().required('Document Category is required'),
  });

  const { mutateAsync: pictureUpload, isLoading: isLoadingDoc } = useMutation(
    'upload-image',
    uploadPicture
  );

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    'add-document',
    documentTypeData
  );
  const { mutateAsync: editAPI, isLoading: isEditingDoc } = useMutation(
    'edit-document',
    editdocumentTypeData
  );

  // const formik = useFormik({
  //   initialValues: {
  //     documentType: editData?.DocumentType || '',
  //     documentCategory: editData?.DocumentCategory || '',
  //     sampleDocumentUrl: editData?.SampleDocumentUrl || '',
  //   },
  //   validationSchema,
  //   onSubmit: async (values) => {
  //     const bodyParams = {
  //       id: editData ? editData.Id : undefined,
  //       documentType: values.documentType,
  //       documentCategory: values.documentCategory,
  //       sampleDocumentUrl: documentImgURL || values.sampleDocumentUrl,
  //       fileName: fileName,
  //     };
      
  //     const response = editData?.Id
  //       ? await editAPI(bodyParams)
  //       : await addAPI(bodyParams);

  //     if (response?.IsSuccess) {
  //       snackActions.success(response?.Message || 'Success');
  //       handleClose(true);
  //     } else {
  //       snackActions.error(response?.Message || 'Something went wrong');
  //     }
  //   },
  // });


  const formik = useFormik({
    initialValues: {
      documentType: editData?.DocumentType || '',
      documentCategory: editData?.DocumentCategory || '',
      sampleDocumentUrl: editData?.SampleDocumentUrl || '',
      documentIsFor: editData?.UploadFromId || ''
    },
    validationSchema,
    onSubmit: async (values) => {
      // Check if a file has been uploaded
      if (!documentImgURL && !values.sampleDocumentUrl) {
        snackActions.error('Please upload a file before submitting.');
        return; // Prevent form submission
      }
  
      const bodyParams = {
        id: editData ? editData.Id : undefined,
        documentType: values.documentType ? values?.documentType?.trim().replace(/\s+/g, ' ') : '',
        documentCategory: values.documentCategory,
        sampleDocumentUrl: documentImgURL || values.sampleDocumentUrl,
        fileName: fileName,
        uploadFrom: values?.documentIsFor
      };
  
      const response = editData?.Id
        ? await editAPI(bodyParams)
        : await addAPI(bodyParams);
  
      if (response?.IsSuccess) {
        snackActions.success(response?.Message || 'Success');
        handleClose(true);
      } else {
        snackActions.error(response?.Message || 'Something went wrong');
      }
    },
  });
  useEffect(() => {
    if (editData) {
      setDocumentUrl(editData.SampleDocumentUrl);
      setFileName(editData.SampleDocumentUrl.split('/').pop() || '');
      setSelectedDocumentFor(editData?.UploadFromId)
      formik.setValues({
        documentType: editData.DocumentType,
        documentCategory: editData.DocumentCategory,
        sampleDocumentUrl: editData.SampleDocumentUrl,
        documentIsFor: editData?.UploadFromId
      });
    }
  }, [editData]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('fileDriveName', 'CompanyDocuments');

      const response = await pictureUpload(formData);
      if (response?.IsSuccess) {
        setDocumentImgURL(response?.Result[0]?.FileUrl || '');
        setFileName(file.name);
        snackActions.success(response.Message || 'File uploaded successfully.');
      } else {
        snackActions.error(response?.Message || 'File upload failed.');
      }
    }
  };

  const handleRemoveFile = () => {
    setDocumentUrl('');
    setFileName('');
    formik.setFieldValue('sampleDocumentUrl', '');
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="addDocumentpopup">
        {isLoadingDoc && (
          <Box className="spinner-loader">
            <CircularProgress />
          </Box>
        )}
        <div className="modal-header">
          <h2 className="mb-0">{editData ? 'Edit' : 'Add'} Document</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined closeBtn">cancel</span>
          </div>
        </div>

        <div className="addDocument">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <Container fluid className="p-0">
              <div className="p-6 pb-0">
                <Row>
                  <Col xs={12}>
                    <label className="form-label fw-bold">
                      Document Name{' '}
                      <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-white documentBorder"
                      placeholder="Document Name"
                      {...formik.getFieldProps('documentType')}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.startsWith(' ')) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />
                    {formik.touched.documentType && formik.errors.documentType && (
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.documentType}
                      </div>
                      </div>
                    )}
                  </Col>

                  {/* <Col xs={12}>
                    <label className="form-label fw-bold">
                      Document Category{' '}
                      <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-white documentBorder"
                      placeholder="Document Category"
                      {...formik.getFieldProps('documentCategory')}
                    />
                    {formik.touched.documentCategory &&
                      formik.errors.documentCategory && (
                        <div className="fv-help-block">
                          {formik.errors.documentCategory}
                        </div>
                      )}
                  </Col> */}

                  <Col xs={12}>
                    <label className="form-label fw-bold">
                      Upload Document
                    </label>
                    <div>
                      {!documentUrl && !fileName ? (
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx,image/*"
                          onChange={handleImageUpload}
                          className="form-control-file chooseFile"
                        />
                      ) : (
                        <div className="d-flex align-items-center justify-content-between selectedFile">
                          <div className="uploadedFile">
                            <p className="m-0">{fileName}</p>
                          </div>
                          <div
                            className="btn btn-sm btn-icon btn-active-color-primary"
                            onClick={handleRemoveFile}
                          >
                            <span className="material-symbols-outlined closeBtn">
                              cancel
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col xs={12}>
                  <label className="form-label fw-bold">
                      Type
                    </label>
                  <select
            className="form-select form-select-solid form-select-lg stateSelect autofillField"
            value={selectedDocumentFor}
            onChange={e => {
              setSelectedDocumentFor(Number(e.target.value));
              formik.setFieldValue('documentIsFor', Number(e.target.value))
            }}
          >
            <option value="">Select Option</option>
            <option value="1">Thrive</option>
            <option value="2">Hospice</option>
            <option value="3">Both</option>
          </select>
          {formik.touched.documentIsFor && formik.errors.documentIsFor && (
            <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.documentIsFor}
                      </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>

              <div className="w-100 d-flex align-items-end justify-content-end mt-4 px-6 py-4 documentFooter">
                <div>
                  <Button
                    type="submit"
                    size="lg"
                    variant="primary"
                    className="documentsaveBtn"
                    disabled={isAdding || isEditingDoc}
                  >
                    {isAdding || isEditingDoc ? 'Loading...' : 'Save'}
                  </Button>
                </div>
              </div>
            </Container>
          </form>
        </div>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default AddEditDocument;
