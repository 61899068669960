import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { uploadPicture } from '../api';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import { useFormik } from 'formik';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const ClockOutPopUp = ({
  showPopup,
  handleClose,
  handleConfirmed,
  loading
}: {
  showPopup: boolean;
  loading: boolean;
  handleClose: () => void;
  handleConfirmed: (data: any) => void
}) => {
  const [documentImgURL, setDocumentImgURL] = useState<string>('');
  const [FileName, setFileName] = useState<string>('');
  const [imgFileName, setImgFileName] = useState<any>('');
  const [documentFileName, setDocumentFileName] = useState('');

  const { mutateAsync: pictureUpload, isLoading: isLoadingDoc } = useMutation(
    'upload-image',
    uploadPicture
  );

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setFileName(file?.name ?? '');
    setImgFileName(file?.name ?? '');
    formData.append('files', file);
    formData.append('fileDriveName', 'CompanyDocuments');
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.IsSuccess) {
        setDocumentImgURL(response?.Result[0].FileUrl);
        setDocumentFileName(response?.Result[0]?.FileName);
        snackActions.success(
          response.Message ?? 'Files Uploaded successfully.'
        );
      } else {
        snackActions.error(
          response?.message ?? 'Issue in image upload, try again latter!'
        );
      }
    }
  };

  const handleSubmit = () => {
    let Document = [
        {
        documentName: FileName ?? '',
        documentUrl: documentImgURL ?? '',
        fileName: FileName,
        }
    ]
    handleConfirmed(Document)
  }

  const handleRemoveFile = () => {
    setDocumentFileName('');
  };

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px"
      show={showPopup}
      onHide={handleClose}
      backdrop="static"
    >
      <div className="modal-header" style={{ padding: '14px 24px' }}>
        <h2 style={{ marginBottom: '0px' }}>Confirm Clock Out</h2>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
        >
          <span
            className="material-symbols-outlined"
            style={{ color: '#FE7253' }}
          >
            cancel
          </span>
        </div>
      </div>
      <div className="d-flex flex-column align-items-left">
        {isLoadingDoc && (
            <Box className="spinner-loader">
            <CircularProgress />
          </Box>
        )
        }
      
        <div className="p-7">
          <h5>Are you sure you want to clock out? If yes, please upload any required attachments before proceeding.</h5>

          <div className="mt-5">
            <label>Upload Attachment</label>
          {documentFileName ? (
                        <div
                          className="d-flex align-items-center"
                          style={fileInputWithDataStyle}
                        >
                          <span>{documentFileName}</span>
                          <span
                            className="material-symbols-outlined"
                            style={{ color: '#FE7253', cursor: 'pointer' }}
                            onClick={handleRemoveFile}
                          >
                            cancel
                          </span>
                        </div>
                      ) : (
            <input
              type="file"
              accept=".pdf,.doc,.docx,image/*"
              onChange={e => {
                const selectedFile = e.currentTarget.files?.[0];
                if (selectedFile) {
                  handleChangeImageUpload(e);
                }
              }}
              className="rounded form-control-file chooseFile"
            />
                      )}
          </div>
        </div>
      </div>
      <div className="w-100 d-flex align-items-center justify-content-end p-5">
        <Button
          disabled={loading}
          onClick={handleClose}
          size="lg"
          variant="secondary"
        >
          Cancel
        </Button>
        <div className="w-15px"></div>
        <Button onClick={handleSubmit} disabled={loading} size="lg" variant="primary">
          {loading ? 'Loading...' : 'Confirm'}
        </Button>
      </div>
    </Modal>
  );
};

export default ClockOutPopUp;

const fileInputWithDataStyle = {
    backgroundColor: '#10355812',
    border: '1px solid #919eab',
    borderRadius: '5px',
    padding: '10px 15px',
    justifyContent: 'space-between',
  };
  