import { Box, IconButton, Popover } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { KTCard, KTIcon } from '../../_metronic/helpers';
import {
  deleteShift,
  getShiftDetail,
} from '../../_metronic/layout/components/api';
import ConfirmEventPopup from '../../_metronic/layout/components/common/ConfirmEventPopup';
import MuiTable from '../../components/table/MuiTable';
import { convertUTCTimesToLocal } from '../../helpers/commonFunctions';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';
import useDebounce from '../../hooks/useDebounce';
import CreateShiftPage from './CreatShiftPage';
import { ReactComponent as Setting } from '../../_metronic/assets/svg/setting.svg';
import { ReactComponent as Edit } from '../../_metronic/assets/svg/edit.svg';
import { ReactComponent as Delete } from '../../_metronic/assets/svg/delete.svg';
import './shift.css';

const ShiftPage: React.FC = () => {
  const [nursingHome, setNursingHome] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const [showActiveDeActivePopup, setShowActiveDeActivePopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
  });

  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const tableRef = useRef();

  const {
    mutateAsync: getShiftDetailData,
    data: nursinghomeaddressdata,
    isLoading: isGettingnursinghomeData,
  } = useMutation('getShiftDetail', getShiftDetail);

  const deleteShiftAPI = async (value: any) => {
    const response = await deleteShiftData({ id: value?.Id });
    if (response) {
      snackActions.success(response?.Message ?? 'Shift archived successfully.');
      handleCloseDelete();
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        roleId: 3,
        search: debounceSearchValue ?? '',
      };
      getShiftDetailAPI(query);
    } else {
      snackActions.error('Failed to delete Shift.');
    }
  };

  const { mutateAsync: deleteShiftData, isLoading: isDeletingDocument } =
    useMutation('delete-document', deleteShift);

  useEffect(() => {
    getShiftDetailAPI({
      ...tableOptions,
      search: debounceSearchValue ?? '',
    });
  }, [debounceSearchValue]);

  const getShiftDetailAPI = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getShiftDetailData(query);
    if (response?.IsSuccess) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.PageDetail?.Skip,
          limit: response?.PageDetail?.Take,
          hasNextPage: response?.PageDetail?.HasNext,
          hasPrevPage: response?.PageDetail?.HasPrevious,
          totalRowCount: response?.PageDetail?.Count,
        };
      });
    }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let offsetValue = page * pageSize;
    let query = {
      offset:
      offsetValue,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? '',
    };
    getShiftDetailAPI(query);
  };

  const handleModalClose = (isSubmit: boolean) => {
    setNursingHome(false);
    handleCloseActivePopup();
    setSelectedRow('');
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        search: '',
      };
      getShiftDetailAPI(query);
    }
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleCloseActivePopup = () => {
    setShowActiveDeActivePopup(false);
  };

  const handleEditModalOpen = () => {
    setNursingHome(true);
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'startTime',
      headerName: 'Start Time',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const currentDate = moment().format('YYYY-MM-DD');
        const convertedTimes = convertUTCTimesToLocal(
          currentDate,
          data?.row?.StartTime,
          data?.row?.EndTime
        );
        const startTime = moment(convertedTimes?.startTime, 'HH:mm:ss').format(
          'HH:mm'
        );

        return <span>{startTime}</span>;
      },
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      flex: 2.8,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const currentDate = moment().format('YYYY-MM-DD');
        const convertedTimes = convertUTCTimesToLocal(
          currentDate,
          data?.row?.StartTime,
          data?.row?.EndTime
        );
        const Endtime = moment(convertedTimes?.endTime, 'HH:mm:ss').format(
          'HH:mm'
        );

        return <span>{Endtime}</span>;
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      minWidth: 120,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        let status: any;
        const handleClick = (value: any, e: any) => {
          setSelectedRow(value);
          setAnchorEl(e.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        return (
          <div className="d-flex align-items-center">
            <IconButton onClick={e => handleClick(data?.row, e)}>
              <Setting />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                p: 1,
                '& .MuiPaper-root': {
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
                },
              }}
            >
              <div className="createShiftPopover">
                <Col
                  className="d-flex align-items-center mb-2"
                  onClick={handleEditModalOpen}
                >
                  <Edit />
                  <label className="ms-3">Edit</label>
                </Col>
                <Col
                  className="d-flex align-items-center"
                  onClick={() => {
                    setShowDeleteConfirmModal(true);
                    setAnchorEl(null);
                  }}
                >
                  <Delete />
                  <label className="ms-3">Archive</label>
                </Col>
              </div>
            </Popover>
          </div>
        );
      },
    },
  ];

  return (
    <Card className="p-6 customeboxShadow">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={nursinghomeaddressdata?.Result ?? []}
            loading={isGettingnursinghomeData}
            tableRef={tableRef}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <>
                <Box>
                  <div className="d-flex pb-5 justify-content-end">
                    <Button
                      className="d-flex align-items-center createShiftBtn"
                      onClick={() => {
                        setNursingHome(true);
                        setSelectedRow('');
                      }}
                    >
                      <KTIcon iconName="plus" className="fs-1" />
                      Create Shift
                    </Button>
                  </div>
                </Box>
              </>
            }
          />
        </KTCard>
        {nursingHome && (
          <CreateShiftPage
            editData={selectedRow}
            show={nursingHome}
            handleClose={handleModalClose}
          />
        )}

        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={() => deleteShiftAPI(selectedRow)}
            loading={isDeletingDocument}
            title={'Archive Shift Time'}
            message={`Are you sure, do you want to archive Shift Time?`}
          />
        )}
      </>
    </Card>
  );
};

export default ShiftPage;
