import { Box, Grid, IconButton, Popover } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { useMutation } from 'react-query';
import MuiTable from '../../../../components/table/MuiTable';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import useDebounce from '../../../../hooks/useDebounce';
import { isSuperAdmin, useUserInfo } from '../../../../hooks/useUserInfo';
import { KTCard, KTIcon } from '../../../helpers';
import { deactivateCompany, getCities, getCompanyDetails, getStates } from '../api';
import ConfirmEventPopup from '../common/ConfirmEventPopup';
import OnboardnewCompany from './onboardnewcompany';
import ViewCompany from './view';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ReactComponent as Setting } from '../../../assets/svg/setting.svg';
import { ReactComponent as Edit } from '../../../assets/svg/edit.svg';
import { ReactComponent as Inactive } from '../../../assets/svg/inactive.svg';
import { ReactComponent as Active } from '../../../assets/svg/active.svg';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import './company.css';
import { boolean } from 'yup';
import FilterCommonPopUp from '../common/FilterCommonPopUp';

const Company: React.FC = () => {
  const [showOnboardCompanyModal, setShowOnboardCompanyModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showactivedeactiveModal, setShowactivedeactiveModal] = useState(false);
  const [isForFilter, setIsForFilter] = useState(false);
  const [showEditCompanyModal, setEditCompanyModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showFilterPopUp, setShowFilterPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isOptionMenuOpen, setIsOptionMenuOpen] = useState<boolean>(false);
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
    companyId: 0,
    stateIds: '',
    cityIds: '',
    isActive: undefined
  });
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  const userInfo = useUserInfo();
  const tableRef = useRef();
  const {
    mutateAsync: getCompanyList,
    data: companyListData,
    isLoading: isGettingCompanyData,
  } = useMutation('company-list', getCompanyDetails);

  const { mutateAsync: updateCompanyData, isLoading: isUpdateCompany } =
    useMutation('updateCompanyStatus', deactivateCompany);

    const {
      data: stateData,
      mutateAsync: getStateData,
      isLoading: isLoadingState,
    } = useMutation('states', getStates);
  
    const {
      data: cityData,
      mutateAsync: getCityData,
      isLoading: isLoadingCity,
      error: cityAPIError,
    } = useMutation('cities', getCities);
  
    useEffect(() => {
      getStateData({ countryId: 1 });
    }, []);
  
    useEffect(() => {
      if (selectedState) {
        getCityData({ stateId: selectedState, countryId: 1, Take: 1000  });
      }
    }, [selectedState]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    setSelectedRow(rowData);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseOptionMenu = () => {
    setAnchorEl(null);
    setIsOptionMenuOpen(false);
  };

  const handleCloseactivedeactive = () => {
    setShowactivedeactiveModal(false);
  };

  useEffect(() => {
    getCompanyData({
      ...tableOptions,
      search: debounceSearchValue ?? '',
    });
  }, [tableOptions?.companyId, debounceSearchValue]);

  useEffect(() => {
    if(isForFilter){
      getCompanyData({
        ...tableOptions,
        search: debounceSearchValue ?? '',
      });
      setIsForFilter(false)
    }
  }, [tableOptions?.companyId, debounceSearchValue, isForFilter]);

  const getCompanyData = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    try {
      let response = await getCompanyList(query);
      if (response?.IsSuccess) {
        setTableOptions((prevState: any) => {
          return {
            ...prevState,
            offset: response?.PageDetail?.Skip,
            limit: response?.PageDetail?.Take,
            hasNextPage: response?.PageDetail?.HasNext,
            hasPrevPage: response?.PageDetail?.HasPrevious,
            totalRowCount: response?.PageDetail?.Count,
          };
        });
      }
    } catch (error) {
      console.error('Error fetching company data: ', error);
    }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let offsetValue = page * pageSize;
    let query = {
      offset:
      offsetValue,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? '',
      stateIds: selectedState,
    cityIds: selectedCity,
    isActive: selectedStatus
    };
    getCompanyData(query);
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowOnboardCompanyModal(false);
    setShowViewModal(false);
    setEditCompanyModal(false);
    setSelectedRow('');
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        roleId: 3,
        search: debounceSearchValue ?? '',
        companyId: tableOptions?.companyId ?? 0,
      };
      getCompanyData(query);
    }
  };

  const handleViewModalOpen = () => {
    handleCloseOptionMenu();
    setShowViewModal(true);
  };

  const updateCompanyStatusAPI = async (value: any) => {
    let status;
    if (value.IsActive) {
      status = false;
    } else {
      status = true;
    }
    let response = await updateCompanyData({
      id: selectedRow?.Id,
      isActive: status,
    });
    if (response?.IsSuccess) {
      snackActions.success(response?.Message);
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        search: debounceSearchValue ?? '',
        companyId: tableOptions?.companyId ?? 0,
      };
      handleCloseactivedeactive();
      getCompanyData(query);
    } else {
      snackActions.error(response?.Message);
    }
  };

  const handleEditCompany = async () => {
    handleCloseOptionMenu();
    setShowOnboardCompanyModal(true);
    setEditCompanyModal(true);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleFilterModalClose = (obj : any, isForClose: boolean) => {
    setShowFilterPopUp(false)
    if(!isForClose){
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          ["stateIds"]: obj?.SelectedState,
          ["cityIds"]: obj?.SelectedCity,
          ["isActive"]: obj?.SelectedStatus,
        };
      });
      setSelectedState(obj?.SelectedState)
      setSelectedCity(obj?.SelectedCity)
      setSelectedStatus(obj?.SelectedStatus)
      setIsForFilter(true)
    }
  else{
    setIsForFilter(false)
  }
  }

  const columns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: 'Facility Name',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => data?.row?.CompanyName ?? '-',
    },
    {
      field: 'companyWebsiteUrl',
      headerName: 'URL',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => data?.row?.CompanyWebsiteUrl ?? '-',
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 3,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) =>
        `${data?.row?.AddressLine1} ${data?.row?.AddressLine2 ?? ''}${','} ${data?.row?.CityName ?? ''}${','} ${data?.row?.StateAcronym ?? ''}${','} ${data?.row?.ZipCode ?? ''}`,
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => {
        const isActive = data?.row?.IsActive;
        const backgroundColor = isActive ? '#00786729' : '#FF000029';
        const statusTextColor = isActive ? '#007867' : '#FF0000';

        return (
          <div
            className="d-flex align-items-center stsusDatadesign"
            style={{ backgroundColor: backgroundColor }}
          >
            <span style={{ color: statusTextColor, fontWeight: 'bold' }}>
              {isActive ? 'Active' : 'Inactive'}
            </span>
          </div>
        );
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      minWidth: 120,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <Box display="flex" alignItems="center">
          <>
            <IconButton
              onClick={(event: any) => handleClick(event, params.row)}
            >
              <Setting />
            </IconButton>

            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className="companyPopover">
                {selectedRow?.IsActive && (
                  <Col
                    className="d-flex align-items-center mb-2"
                    onClick={handleEditCompany}
                  >
                    <Edit />
                    <label className="ms-3">Edit</label>
                  </Col>
                )}
                <Col
                  className="d-flex align-items-center mb-2"
                  onClick={handleViewModalOpen}
                >
                  <VisibilityIcon style={{ fill: '#103558' }} />
                  <label className="ms-3">View</label>
                </Col>
                {selectedRow?.IsActive ? (
                  <Col
                    className="d-flex align-items-center mb-2"
                    onClick={() => {
                      setShowactivedeactiveModal(true);
                      handleCloseOptionMenu();
                    }}
                  >
                    <Inactive />
                    <label className="ms-3">Inactive</label>
                  </Col>
                ) : (
                  <Col
                    className="d-flex align-items-center mb-2"
                    onClick={() => {
                      setShowactivedeactiveModal(true);
                      handleCloseOptionMenu();
                    }}
                  >
                    <Active />
                    <label className="ms-2">Active</label>
                  </Col>
                )}
              </div>
            </Popover>
          </>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Card className="p-6 customeboxShadow">
        <KTCard>
          <MuiTable
            isAddEnable={false}
            columns={columns}
            data={companyListData?.Result ?? []}
            loading={isGettingCompanyData}
            tableRef={tableRef}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <div className="d-sm-flex mb-4 align-items-end justify-content-between">
                <Box display="flex">
                  <div className="mb-sm-0 mb-4 position-relative d-flex">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search Facility"
                      aria-label="Search"
                      style={{
                        backgroundImage: `url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.80454 2.40976C7.78799 2.40985 6.78619 2.65303 5.88273 3.11903C4.97928 3.58503 4.20036 4.26032 3.61097 5.08857C3.02158 5.91683 2.6388 6.87402 2.49457 7.88029C2.35035 8.88657 2.44885 9.91274 2.78188 10.8732C3.1149 11.8337 3.67278 12.7006 4.40897 13.4016C5.14516 14.1026 6.03832 14.6173 7.01393 14.903C7.98953 15.1886 9.0193 15.2367 10.0173 15.0434C11.0153 14.8501 11.9526 14.421 12.751 13.7918L15.49 16.5308C15.6315 16.6674 15.8209 16.743 16.0176 16.7413C16.2142 16.7396 16.4024 16.6607 16.5414 16.5216C16.6805 16.3826 16.7593 16.1945 16.761 15.9978C16.7628 15.8012 16.6872 15.6117 16.5505 15.4703L13.8115 12.7313C14.5525 11.7912 15.0139 10.6616 15.1429 9.47155C15.2718 8.28155 15.0631 7.07928 14.5407 6.00233C14.0183 4.92538 13.2032 4.01728 12.1888 3.38193C11.1743 2.74659 10.0015 2.40968 8.80454 2.40976ZM3.92954 8.78476C3.92954 7.49183 4.44316 6.25185 5.3574 5.33761C6.27164 4.42337 7.51161 3.90976 8.80454 3.90976C10.0975 3.90976 11.3374 4.42337 12.2517 5.33761C13.1659 6.25185 13.6795 7.49183 13.6795 8.78476C13.6795 10.0777 13.1659 11.3177 12.2517 12.2319C11.3374 13.1461 10.0975 13.6598 8.80454 13.6598C7.51161 13.6598 6.27164 13.1461 5.3574 12.2319C4.44316 11.3177 3.92954 10.0777 3.92954 8.78476Z' fill='%23637281'/%3E%3C/svg%3E")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left 8px center',
                        padding: '20px 30px 0px 30px',
                      }}
                      onChange={e =>
                        handleChangeFilters('search', e.target.value)
                      }
                      value={tableOptions?.search}
                    />
                    {tableOptions?.search && (
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleChangeFilters('search', '')}
                      ></i>
                    )}



{/* <select
                      disabled={isLoadingState}
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      // {...formik.getFieldProps('state')}
                      value={selectedState}
                      onChange={e => {
                        setSelectedState(e.target.value)
                          setSelectedCity("")
                          handleChangeFilters('stateIds', e.target.value)
                      }}
                      style={{marginLeft:"10px"}}
                    >
                      <option value="">State</option>
                      {stateData
                        ? stateData?.Result?.map((state: any) => (
                            <option value={state?.Id} key={state?.Id}>
                              {state?.StateName}
                            </option>
                          ))
                        : null}
                    </select>


                    <select
                      disabled={isLoadingCity}
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      value={selectedCity}
                      onChange={e => {
                        setSelectedCity(e.target.value)
                        handleChangeFilters('cityIds', e.target.value)
                      }}
                      style={{marginLeft:"10px"}}
                    >
                      <option value="">Select City</option>
                      {cityData
                        ? cityData?.Result?.map((city: any) => (
                            <option value={city?.Id} key={city?.Id}>
                              {city?.CityName}
                            </option>
                          ))
                        : null}
                    </select> */}

                    {/* <select
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      value={selectedStatus}
                      onChange={e => {
                        setSelectedStatus(e.target.value)
                        handleChangeFilters('isActive', e.target.value)
                      }}
                      style={{marginLeft:"10px"}}
                    >
                      <option value="">Select Status</option>
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select> */}
                  </div>
                  <Button
                      style={{marginLeft:"10px"}}
                      onClick={() => setShowFilterPopUp(true)}
                      >
                      <FilterAltIcon />
                      </Button>
                </Box>

                {!isSuperAdmin && (
                  <Grid item lg={3} md={4} sm={6} xs={12} className="ms-3">
                    <Box>
                      <label
                        className="form-label"
                        style={{
                          marginLeft: 5,
                          fontWeight: '900',
                          fontSize: '15.6px',
                        }}
                      >
                        Select Facility
                      </label>
                      <select
                        className="form-select form-select-solid form-select-lg"
                        style={{
                          backgroundColor: 'white',
                          border: '1px solid #919EAB',
                        }}
                        onChange={(e: any) =>
                          handleChangeFilters('companyId', e?.target?.value)
                        }
                        value={tableOptions?.companyId}
                      >
                        <option value="">All</option>
                        {userInfo?.UserCompanies
                          ? userInfo?.UserCompanies?.map((company: any) => (
                              <option
                                value={company?.CompanyId}
                                key={company?.CompanyId}
                              >
                                {company?.CompanyName}
                              </option>
                            ))
                          : null}
                      </select>
                    </Box>
                  </Grid>
                )}

                {isSuperAdmin && (
                  <div className="d-flex justify-content-end">
                    <Button
                      className="d-flex align-items-center"
                      onClick={() => {
                        setShowOnboardCompanyModal(true);
                        setSelectedRow('');
                      }}
                    >
                      <KTIcon iconName="plus" className="fs-1" />
                      Add Facility
                    </Button>
                  </div>
                )}
              </div>
            }
          />
        </KTCard>
        {showOnboardCompanyModal && (
          <OnboardnewCompany
            editData={selectedRow}
            show={showOnboardCompanyModal}
            handleClose={handleModalClose}
          />
        )}
        {showViewModal && (
          <ViewCompany
            viewData={selectedRow}
            show={showViewModal}
            handleClose={handleModalClose}
          />
        )}
        {showactivedeactiveModal && (
          <ConfirmEventPopup
            showPopup={showactivedeactiveModal}
            handleClose={handleCloseactivedeactive}
            handleConfirmed={() => updateCompanyStatusAPI(selectedRow)}
            loading={isUpdateCompany}
            title={'Update Status'}
            message={`Are you sure, do you want to ${
              selectedRow.IsActive ? 'Inactive' : 'Active'
            } this Facility?`}
          />
        )}
{showFilterPopUp && (
          <FilterCommonPopUp
          showPopup={showFilterPopUp}
          values={{
            selectedState: selectedState,
            selectedCity: selectedCity,
            selectedStatus: selectedStatus
          }}
          handleClose={handleFilterModalClose}
          page={"Company"}
          />
        )}
      </Card>
    </>
  );
};

export default Company;
