import { Box, IconButton, Popover } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import MuiTable from '../../../../components/table/MuiTable';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import useDebounce from '../../../../hooks/useDebounce';
import { useUserInfo } from '../../../../hooks/useUserInfo';
import {
  resetAssignmentFormData,
  resetStep,
} from '../../../../store/slice/form/assignment_form';
import { setData } from '../../../../store/slice/header/headerSlice';
import { ReactComponent as Viewshift } from '../../../assets/svg/viewshift.svg';
import { ReactComponent as Edit } from '../../../assets/svg/edit.svg';
import { ReactComponent as Delete } from '../../../assets/svg/delete.svg';
import { ReactComponent as Approve } from '../../../assets/svg/approve.svg';
import { ReactComponent as Reject } from '../../../assets/svg/reject.svg';
import { ReactComponent as Setting } from '../../../assets/svg/setting.svg';
import { KTCard, KTIcon } from '../../../helpers';
import { AssignmentStatusEnum } from '../../../helpers/enum';
import { DeleteAssignment, getAssignment, getCities, getCompanies, getStates } from '../api';
import ConfirmEventPopup from '../common/ConfirmEventPopup';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterCommonPopUp from '../common/FilterCommonPopUp';

const Assignment = () => {
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [isShowDeleteIcon, setIsShowDeleteIcon] = useState(false);
  const [isForFilter, setIsForFilter] = useState(false);
  const [showFilterPopUp, setShowFilterPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCompany, setSelectedCompany] = useState("");
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const dispatch = useDispatch();

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
    companyId: 0,
    companyIds: undefined,
    stateIds: undefined,
    cityIds: undefined,
    statusIds: undefined
  });
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  const tableRef = useRef();
  const navigate = useNavigate();

  const [showStatusModel, setshowStatusModel] = useState('');
  const [showUserId, setShowUserId] = useState('');

  const {
    mutateAsync: getAssignmentsData,
    data: assignmentData,
    isLoading: isGettingAssignmentData,
  } = useMutation('assignment-list', getAssignment);


  const {
    data: companiesData,
    isLoading: isLoadingCompanies,
    error: stateAPIError,
  } = useQuery('companies', getCompanies);

  useEffect(() => {
    getAssignments({
      ...tableOptions,
      search: debounceSearchValue ?? '',
    });
  }, [tableOptions?.companyId, debounceSearchValue]);

  useEffect(() => {
    if(isForFilter){
      getAssignments({
        ...tableOptions,
        search: debounceSearchValue ?? '',
      });
      setIsForFilter(false)
    }
  }, [isForFilter, debounceSearchValue]);

  const { mutateAsync: deleteAssignmentData, isLoading: isDeletingAssignment } =
    useMutation('delete-document', DeleteAssignment);

  const deleteAssignmentAPI = async (value: any) => {
    const response = await deleteAssignmentData({ id: value?.Id });
    if (response) {
      snackActions.success(response?.Message);
      handleCloseDelete();
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        roleId: 3,
        search: debounceSearchValue ?? '',
        companyId: tableOptions?.companyId ?? 0,
      };
      getAssignments(query);
    }
  };

  const HandleEditAssignment = () => {
    dispatch(resetStep());
    navigate('/new-assignment', { state: { assignmenteditdata: selectedRow } });
  };

  const handleStatus = (value: any, id: any) => {
    setShowDeleteConfirmModal(true);
    setshowStatusModel(value);
    setShowUserId(id);
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setshowStatusModel('');
  };

  const viewshift = () => {
    navigate('/view-assignment-shift', { state: selectedRow });
    localStorage.setItem('previousPathnameKey', window.location.pathname);
  };

  const handleFilterModalClose = (obj : any, isForClose: boolean) => {
    setShowFilterPopUp(false)
    if(!isForClose){
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          ["stateIds"]: obj?.SelectedState,
          ["cityIds"]: obj?.SelectedCity,
          ["companyIds"]: obj?.SelectedCompany,
          ["statusIds"]: obj?.SelectedStatus,
        };
      });
      setSelectedState(obj?.SelectedState)
      setSelectedCity(obj?.SelectedCity)
      setSelectedCompany(obj?.SelectedCompany)
      setSelectedStatus(obj?.SelectedStatus)
        setIsForFilter(true)
    } 
    else{
      setIsForFilter(false)
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'ID',
      headerName: 'Assignment Code',
      flex: 3,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => data?.row?.AssignmentCode ?? '-',
    },
    {
      field: 'Patient Info',
      headerName: 'Patient Info',
      flex: 3,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) =>
        `${data?.row?.PatientDetails.FirstName} ${data?.row?.PatientDetails.LastName}` ??
        '-',
    },
    {
      field: 'TotalShiftCount',
      headerName: 'Total Shifts',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => `${data?.row?.TotalShiftCount}` ?? '-',
    },
    {
      field: 'OpenedShiftCount',
      headerName: 'Opened',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => `${data?.row?.OpenShiftCount}` ?? '-',
    },
    {
      field: 'FilledShiftCount',
      headerName: 'Filled',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => `${data?.row?.FilledShiftCount}` ?? '-',
    },
    {
      field: 'Canceled',
      headerName: 'Canceled',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => `${data?.row?.CancelledShiftCount}` ?? '-',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const statusId = data?.row?.StatusId;
        let backgroundColor = '';
        let statusTextColor = '';

        switch (statusId) {
          case AssignmentStatusEnum.Filled:
            backgroundColor = '#6f42c129';
            statusTextColor = '#855dcf';
            break;
          case AssignmentStatusEnum.Open:
            backgroundColor = '#007bff1f';
            statusTextColor = '#3495ff';
            break;
          case AssignmentStatusEnum.Approved:
            backgroundColor = '#00786729';
            statusTextColor = '#007867';
            break;
          case AssignmentStatusEnum.Completed:
            backgroundColor = '#00786729';
            statusTextColor = '#007867';
            break;
          case AssignmentStatusEnum.Cancelled:
            backgroundColor = '#ff563029';
            statusTextColor = '#ff5630';
            break;
          default:
            backgroundColor = '';
            break;
        }

        return (
          <div
            className="d-flex align-items-center stsusDatadesign"
            style={{
              backgroundColor: backgroundColor,
            }}
          >
            <span style={{ color: statusTextColor, fontWeight: 'bold' }}>
              {data?.row.StatusName}
            </span>
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1.5,
      minWidth: 120,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        let status: any;
        if (data?.row?.ProfileStatusId == 2) {
          status = 'Block';
        } else {
          status = 'Approve';
        }

        const handleClick = (value: any, e: any) => {
          setSelectedRow(value);
          setAnchorEl(e.currentTarget);
          setIsShowDeleteIcon(value?.FilledShiftCount === 0);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };
        return (
          <div className="d-flex align-items-center">
            <IconButton onClick={e => handleClick(data?.row, e)}>
              <Setting />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                p: 1,
                '& .MuiPaper-root': {
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
                },
              }}
            >
              <div className="actionPopup">
                {selectedRow?.StatusId !== AssignmentStatusEnum?.Cancelled && (
                  <Col className="pb-3" onClick={HandleEditAssignment}>
                  <Edit />
                  <label className="ps-3">Edit</label>
                </Col>
                )}
                <Col className="pb-3" onClick={viewshift}>
                  <Viewshift />
                  <label className="ps-3">View Shifts</label>
                </Col>
                {(selectedRow?.StatusId == AssignmentStatusEnum?.Open && selectedRow?.FilledShiftCount == 0) && (
                  <Col
                    onClick={() => {
                      handleStatus(status, data?.row?.Id);
                      setShowDeleteConfirmModal(true);
                      setAnchorEl(null);
                    }}
                  >
                    <Delete />
                    <label className="ps-4" color="#FF5630">
                    Archive
                    </label>
                  </Col>
                )}

                {data?.row?.ProfileStatusId == 1 && (
                  <Col className="pb-3">
                    <Approve />
                    <label className="ps-3">Approve</label>
                  </Col>
                )}
                {data?.row?.ProfileStatusId === 1 ||
                  (data?.row?.ProfileStatusId === 2 && (
                    <Col className="pb-3">
                      <Reject />
                      <label className="ps-3">Reject</label>
                    </Col>
                  ))}
                {data?.row?.ProfileStatusId === 1 ||
                  data?.row?.ProfileStatusId === 2 ||
                  (data?.row?.ProfileStatusId === 3 && (
                    <Col
                      onClick={() => handleStatus(status, data?.row?.UserId)}
                    >
                      <Delete />
                      <label className="ps-4" color="#FF5630">
                        Block / Unblock
                      </label>
                    </Col>
                  ))}
              </div>
            </Popover>
          </div>
        );
      },
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let offsetValue = page * pageSize;
    let query = {
      offset:
      offsetValue,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? '',
      companyId: tableOptions?.companyId ?? 0,
      companyIds: selectedCompany,
      stateIds: selectedState,
      cityIds: selectedCity,
      statusIds: selectedStatus
    };
    getAssignments(query);
  };

  const getAssignments = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
          companyId: number;
        }
      | undefined
  ) => {
    let response = await getAssignmentsData(query);
    if (response?.IsSuccess) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.PageDetail?.Skip,
          limit: response?.PageDetail?.Take,
          hasNextPage: response?.PageDetail?.HasNext,
          hasPrevPage: response?.PageDetail?.HasPrevious,
          totalRowCount: response?.PageDetail?.Count,
        };
      });
    }
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  return (
    <Card className="p-6 customeboxShadow">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={assignmentData?.Result ?? []}
            loading={isGettingAssignmentData}
            tableRef={tableRef}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <>
                <div className="ms-auto d-flex align-items-end justify-content-end mb-2"></div>
                <div className="d-sm-flex mb-4 align-items-end justify-content-between">
                  <Box display="flex">
                    <div className="mb-sm-0 mb-4 position-relative d-flex">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Search Assignment"
                        aria-label="Search"
                        style={{
                          backgroundImage: `url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.80454 2.40976C7.78799 2.40985 6.78619 2.65303 5.88273 3.11903C4.97928 3.58503 4.20036 4.26032 3.61097 5.08857C3.02158 5.91683 2.6388 6.87402 2.49457 7.88029C2.35035 8.88657 2.44885 9.91274 2.78188 10.8732C3.1149 11.8337 3.67278 12.7006 4.40897 13.4016C5.14516 14.1026 6.03832 14.6173 7.01393 14.903C7.98953 15.1886 9.0193 15.2367 10.0173 15.0434C11.0153 14.8501 11.9526 14.421 12.751 13.7918L15.49 16.5308C15.6315 16.6674 15.8209 16.743 16.0176 16.7413C16.2142 16.7396 16.4024 16.6607 16.5414 16.5216C16.6805 16.3826 16.7593 16.1945 16.761 15.9978C16.7628 15.8012 16.6872 15.6117 16.5505 15.4703L13.8115 12.7313C14.5525 11.7912 15.0139 10.6616 15.1429 9.47155C15.2718 8.28155 15.0631 7.07928 14.5407 6.00233C14.0183 4.92538 13.2032 4.01728 12.1888 3.38193C11.1743 2.74659 10.0015 2.40968 8.80454 2.40976ZM3.92954 8.78476C3.92954 7.49183 4.44316 6.25185 5.3574 5.33761C6.27164 4.42337 7.51161 3.90976 8.80454 3.90976C10.0975 3.90976 11.3374 4.42337 12.2517 5.33761C13.1659 6.25185 13.6795 7.49183 13.6795 8.78476C13.6795 10.0777 13.1659 11.3177 12.2517 12.2319C11.3374 13.1461 10.0975 13.6598 8.80454 13.6598C7.51161 13.6598 6.27164 13.1461 5.3574 12.2319C4.44316 11.3177 3.92954 10.0777 3.92954 8.78476Z' fill='%23637281'/%3E%3C/svg%3E")`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'left 8px center',
                          padding: '20px 30px 0px 30px',
                        }}
                        onChange={e =>
                          handleChangeFilters('search', e.target.value)
                        }
                        value={tableOptions?.search}
                      />
                      {tableOptions?.search && (
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleChangeFilters('search', '')}
                        ></i>
                      )}


                     {/* <select
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      value={selectedStatus}
                      onChange={e => {
                        setSelectedStatus(e.target.value)
                        handleChangeFilters('statusIds', e.target.value)
                      }}
                      style={{marginLeft:"10px"}}
                    >
                      <option value="">Select Status</option>
                      <option value="1">Open</option>
                      <option value="2">Filled</option>
                      <option value="3">Completed</option>
                      <option value="4">Approved</option>
                      <option value="5">Cancelled</option>
                    </select>

                    <select
                      disabled={isLoadingState}
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      // {...formik.getFieldProps('state')}
                      value={selectedState}
                      onChange={e => {
                        setSelectedState(e.target.value)
                          setSelectedCity("")
                          handleChangeFilters('stateIds', e.target.value)
                      }}
                      style={{marginLeft:"10px"}}
                    >
                      <option value="">State</option>
                      {stateData
                        ? stateData?.Result?.map((state: any) => (
                            <option value={state?.Id} key={state?.Id}>
                              {state?.StateName}
                            </option>
                          ))
                        : null}
                    </select>


                    <select
                      disabled={isLoadingCity}
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      value={selectedCity}
                      onChange={e => {
                        setSelectedCity(e.target.value)
                        handleChangeFilters('cityIds', e.target.value)
                      }}
                      style={{marginLeft:"10px"}}
                    >
                      <option value="">Select City</option>
                      {cityData
                        ? cityData?.Result?.map((city: any) => (
                            <option value={city?.Id} key={city?.Id}>
                              {city?.CityName}
                            </option>
                          ))
                        : null}
                    </select>

                    <select
                    disabled={isLoadingCompanies}
                    className="form-select form-select-solid form-select-lg"
                    onChange={e => {
                      setSelectedCompany(e.target.value)
                      handleChangeFilters('companyIds', e.target.value)
                    }}
                    style={{marginLeft:"10px"}}
                  >
                    <option value="">Select company</option>
                    {companiesData
                      ? companiesData?.Result?.map((company: any) => (
                          <option value={company?.Id} key={company?.Id}>
                            {company?.CompanyName}
                          </option>
                        ))
                      : null}
                  </select> */}
                    </div>
                    
                    <Button
                      style={{marginLeft:"10px"}}
                      onClick={() => setShowFilterPopUp(true)}
                      >
                      <FilterAltIcon />
                      </Button>
                  </Box>
                  <div className="d-flex justify-content-end">
                    <Link to="/new-assignment" className="text-decoration-none">
                      <Button
                        className="d-flex align-items-center addAssignment"
                        onClick={() => {
                          dispatch(resetAssignmentFormData());
                          dispatch(resetStep());
                          dispatch(setData('Assignments'));
                          localStorage.setItem(
                            'previousPathnameKey',
                            window.location.pathname
                          );
                        }}
                      >
                        <KTIcon iconName="plus" className="fs-1" />
                        Add New Assignment
                      </Button>
                    </Link>
                  </div>
                </div>
              </>
            }
          />
        </KTCard>
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={() => deleteAssignmentAPI(selectedRow)}
            loading={isDeletingAssignment}
            title={'Archive Assignment'}
            message={`Are you sure, do you want to archive this Assignment?`}
          />
        )}

{showFilterPopUp && (
          <FilterCommonPopUp
          showPopup={showFilterPopUp}
          values={{
            selectedState: selectedState,
            selectedCity: selectedCity,
            selectedCompany: selectedCompany,
selectedStatus: selectedStatus
          }}
          handleClose={handleFilterModalClose}
          page={"Assignment"}
          />
        )}
      </>
    </Card>
  );
};

export default Assignment;

enum ProfileStatusName {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Blocked = 4,
}
