import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: 0,
  formData: {
    Id: 0,
    assignmentCode: '',
    assignmentName: '',
    companyId: '',
    nursingHomeId: 0,
    companySkillIds: '' || [],
    skillIdForProviderSearch: '' || [],
    instruction: '',
    companyDocumentIds: [],
    programId: '',
    assignmentDocuments: [],
    isRequiredProviderDocumentUpload: false,
    selectedTabelDates: [],
    assignmentSchedules: [],
    clinicianshiftdetails: [],
    ratePerHour: '',
    weekendrate: '',
    shiftTimeOption: 'Option 1',
    InvitedUserIds: [],
    tablecheckbox: '',
    shiftdetail: [],
    requiredPersonCount: 1,
    patientDetails: {
      id: 0,
      patientCode: '',
      firstName: '',
      lastName: '',
      countryCode: '',
      contactNumber: '',
      contactPersonFirstName: '',
      contactPersonLastName: '',
      contactPersonContactNumber: '',
      patientOtherInformationIds: [],
      note: '',
    },
    selectLocationType: '',
    companyAddress: '',
    locationTypeId: '',
    addressLine1: '',
    addressLine2: '',
    cityId: '',
    cityName: '',
    stateId: 0,
    zipCode: '',
    nursinghomecityId: '',
    latitude: '',
    longitude: '',
    approvalRequestEmail: [],
    scheduleNotificationEmail: [],
    isCustomShiftTime: false,
    shiftId: 0,
    selectedPatientAddress: {},
    isforViewInvited: false,
    viewinvitedselecteddate: '',
    SelectedRowData: {},
    isFromViewShift: false,
    viewShiftEditAssignmentData: []
  },
};

const assignment_form_slice = createSlice({
  name: 'assignment_form',
  initialState,
  reducers: {
    setAssignmentFormData(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    },
    nextStep(state) {
      state.step += 1;
    },
    prevStep(state) {
      state.step -= 1;
    },
    resetStep(state) {
      state.step = 0;
    },
    resetAssignmentFormData(state) {
      state.formData = initialState.formData;
    },
    initializeStep(state, action) {
      state.step = action.payload.isCreateShift ? 1 : 0;
    },
  },
});

export const {
  nextStep,
  prevStep,
  setAssignmentFormData,
  resetStep,
  resetAssignmentFormData,
  initializeStep, 
} = assignment_form_slice.actions;
export default assignment_form_slice.reducer;
