import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import {
  addCity,
  addState,
  editCity,
  editState,
  getCities,
  getStates,
} from '../../_metronic/layout/components/api';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;

  editData: {
    Id: any;
    ZipCode: Number;
    AddressLine1: String;
    AddressLine2: String;
    Name: String;
    CityId: Number;
    StateId: Number;
    StateName: String;
    CityName: String;
    PhoneNumber: any;
    CountryCode: Number;
    IsActive: boolean,
    Acronym: String
  };
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddCity = ({
  show,
  handleClose,
  editData,
  title,
  isForEditProfile = false,
}: Props) => {
  const [isActive, setIsActive] = useState(true);

  const validationSchema = Yup.object().shape({
    state: Yup.string().required('State is required'),
    acronym: Yup.string().required('City is required'),
  });

  const formik: any = useFormik({
    initialValues: {
      state: editData?.StateName,
      acronym: editData?.Acronym,
    },
    validationSchema,
    onSubmit: async values => {

      let bodyParams = {
        id: editData?.Id,
        name : values.state ? values?.state?.trim().replace(/\s+/g, ' ') : '',
        countryId: 1,
        acronym: values?.acronym ? values?.acronym?.trim().replace(/\s+/g, ' ') : '',
        isActive: isActive
      };

      if (editData) {
        let response = await editCityAPI(bodyParams);
        if (response?.IsSuccess) {
          snackActions.success(response?.Message);
          formik.resetForm();
          if (isForEditProfile) {
            localStorage.setItem('userInfo', JSON.stringify(response?.data));
          }
          handleClose(true);
        } else {
          snackActions.error(response?.Message);
        }
      } else {
        let response = await addStateAPI(bodyParams);
        if (response?.IsSuccess) {
          snackActions.success(response.Message);
          formik.resetForm();
          handleClose(true);
        } else {
          snackActions.error(response?.Message);
        }
      }
    },
  });

  useEffect(() => {
if(editData){
  setIsActive(editData?.IsActive)
}
  }, [editData])


  const { mutateAsync: addStateAPI, isLoading: isAdding } = useMutation(
    'add-state',
    addState
  );
  const { mutateAsync: editCityAPI, isLoading: isEditing } = useMutation(
    'edit-state',
    editState
  );

  const handleIsAdminChange = (e: any) => {
    setIsActive(e.target.checked);
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px mx-md-auto mx-3"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="nursingHomepopup">
        <div className="modal-header">
          <h2 className="mb-0">
            {editData ? 'Edit State' : 'Add State'}
          </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined close">cancel</span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="p-4">
            <Container fluid>
              <Row className="mb-2 gy-4">
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    State <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>

                  <div>
                  <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 documentBorder"
                        placeholder="State name"
                        aria-label="state name"
                        {...formik.getFieldProps('state')}
                        onChange={(e) => {
                        const value = e.target.value;
                        if (value.startsWith(' ')) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                        }}
                      />
                    {formik.touched.state && formik.errors.state && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.state}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    Accronym <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>

                  <div>
                  <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 documentBorder"
                        placeholder="Acronym name"
                        aria-label="Acronym name"
                        {...formik.getFieldProps('acronym')}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.startsWith(' ')) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      />
                    {formik.touched.acronym && formik.errors.acronym && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.acronym}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={12} xs={12}>
                <input
                    type="checkbox"
                    className="form-check-input documentCheckbox"
                    checked={isActive}
                    onChange={handleIsAdminChange}
                  />
                  <label className="form-check-label documentCheckboxText">
                    Is Active?
                  </label>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="w-100 d-flex align-items-end justify-content-end px-6 pb-5">
            <Button type="submit" variant="primary">
              {isAdding || isEditing ? 'Loading...' : 'Save'}
            </Button>
          </div>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default AddCity;
