import axios from 'axios';
import CryptoJS from 'crypto-js';
import { refreshToken } from '../../components/api';

const instance = axios.create();

instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.timeout = 1000 * 60;

const encryptPayload = (payload: any, secretKey: any, iv: any) => {
  const key = CryptoJS.enc.Base64.parse(secretKey);
  const ivParsed = CryptoJS.enc.Base64.parse(iv);
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(payload), key, {
    iv: ivParsed,
  }).toString();
  return ciphertext;
};

const decryptPayload = (ciphertext: any, secretKey: any, iv: any) => {
  const key = CryptoJS.enc.Base64.parse(secretKey);
  const ivParsed = CryptoJS.enc.Base64.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
    iv: ivParsed,
  }).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
};

const isEncryptionEnabled = process.env.REACT_APP_IS_ENCRYPT;

//request interceptor
instance.interceptors.request.use(
  (config: any) => {
    if (isEncryptionEnabled === 'true') {
      const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
      const APP_IV = process.env.REACT_APP_IV;
      try {
        const queryString = config?.url?.split('?');
        if (
          config.method &&
          !(config.data && config.data instanceof FormData)
        ) {
          const encryptedPayload = encryptPayload(
            config.data,
            SECRET_KEY,
            APP_IV
          );
          config.data = { encryptedData: encryptedPayload };

          if (queryString) {
            const params = queryString[1]?.split('&');
            const paramObject: any = {};
            params?.forEach((param: any) => {
              const [key, value] = param.split('=');
              paramObject[decodeURIComponent(key)] = value
                ? decodeURIComponent(value)
                : null;
            });

            const encryptedURL = encryptPayload(
              paramObject,
              SECRET_KEY,
              APP_IV
            );

            const encodedQueryParams = encodeURIComponent(encryptedURL);
            config.url = `${queryString[0]}?encryptedData=${encodedQueryParams}`;
          }
        }
      } catch (error) {
        console.error('Error in request interceptor:', error);
      }
      return config;
    } else {
      return config;
    }
  },
  error => {
    return Promise.reject(error);
  }
);

// response interceptor
instance.interceptors.response.use(
  response => {
    const responseData = response.data['Result'];
    if (isEncryptionEnabled === 'true') {
      let decryptedResponseData = {};
      if (responseData) {
        decryptedResponseData = decryptPayload(
          responseData,
          process.env.REACT_APP_SECRET_KEY,
          process.env.REACT_APP_IV
        );
      }
      const responseDataDecrypt = {
        data: {
          Result: decryptedResponseData,
          IsSuccess: response.data['IsSuccess'],
          Message: response.data['Message'],
          PageDetail: response.data['PageDetail'],
        },
      };
      return responseDataDecrypt;
    } else {
      return response;
    }
  },
  async error => {
    const refreshTokenExpiryDate: any = localStorage.getItem(
      'RefreshTokenExpiryDate'
    );

    const isRefreshTokenExpired =
      refreshTokenExpiryDate < new Date().toISOString();

    if (error.response && error.response.status === 401) {
      const expiredToken = localStorage.getItem('userToken');
      const refreshTokenValue = localStorage.getItem('refreshToken');
      const payloadData = {
        expiredToken,
        refreshToken: refreshTokenValue,
      };

      if (typeof refreshToken === 'function') {
        if (!isRefreshTokenExpired) {
          try {
            const response = await refreshToken(payloadData);
            if (response?.IsSuccess) {
              localStorage.setItem('userToken', response?.Result?.Token);
              window.location.reload();
            }
          } catch (err) {
            console.error('Error refreshing token:', err);
          }
        } else {
          localStorage.clear();
          window.location.reload();
        }
      }
      return new Promise(() => {});
    }

    return Promise.reject(error);
  }
);

export default instance;
