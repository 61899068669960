import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';

import PhoneInput from 'react-phone-input-2';
import {
  addNursinghome,
  editnursinghome,
  getCities,
  getStates,
} from '../../_metronic/layout/components/api';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';
import './nursinghome.css';

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;

  editData: {
    Id: any;
    ZipCode: Number;
    AddressLine1: String;
    AddressLine2: String;
    Name: String;
    CityId: Number;
    StateId: Number;
    PhoneNumber: any;
    CountryCode: Number;
  };
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddNursinghome = ({
  show,
  handleClose,
  editData,
  title,
  isForEditProfile = false,
}: Props) => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
  });

  const validationSchema = Yup.object().shape({
    state: Yup.string().required('State is required'),
    mobileNumber: Yup.string()
      .required('Mobile number is required')
      .min(10, 'Mobile number format is incorrect'),
    name: Yup.string().required('Name is required'),
    addressline1: Yup.string().required('Address Line 1 is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.string()
      .matches(/^[0-9]+$/, 'Zip code must contain only numbers')
      .min(5, 'Zip code must be at least 5 digits')
      .max(5, 'Zip code must be at most 5 digits')
      .required('Zip code is required'),
  });

  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
  } = useMutation('states', getStates);

  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
    error: cityAPIError,
  } = useMutation('cities', getCities);

  const formik: any = useFormik({
    initialValues: {
      addressline1: editData?.AddressLine1,
      addressline2: editData?.AddressLine2,
      name: editData?.Name,
      state: editData?.StateId,
      city: editData?.CityId,
      zip: editData?.ZipCode,
      mobileNumber: editData?.PhoneNumber,
      countrycode: editData?.CountryCode,
    },
    validationSchema,
    onSubmit: async values => {
      let bodyParams = {
        id: editData ? editData?.Id : undefined,
        name: values?.name ? values?.name?.trim().replace(/\s+/g, ' ') : '',
        addressline1: values?.addressline1 ? values?.addressline1?.trim().replace(/\s+/g, ' ') : '',
        addressline2: values?.addressline2 ? values?.addressline2?.trim().replace(/\s+/g, ' ') : '',
        cityId: values?.city ?? '',
        stateId: values?.state ?? '',
        zipCode: values?.zip ?? '',
        latitude: '',
        longitude: '',
        countryCode: 1,
        phoneNumber: values?.mobileNumber ?? '',
      };

      if (editData) {
        let response = await editnursinghomeAPI(bodyParams);
        if (response?.IsSuccess) {
          snackActions.success(response?.Message);
          formik.resetForm();
          if (isForEditProfile) {
            localStorage.setItem('userInfo', JSON.stringify(response?.data));
          }
          handleClose(true);
        } else {
          snackActions.error(response?.Message);
        }
      } else {
        let response = await addnursinghomeAPI(bodyParams);
        if (response?.IsSuccess) {
          snackActions.success(response.Message);
          formik.resetForm();
          handleClose(true);
        } else {
          snackActions.error(response?.Message);
        }
      }
    },
  });

  const stateValue = formik.values.state;

  useEffect(() => {
    getStateData({ countryId: 1 });
  }, []);

  useEffect(() => {
    if (stateValue) {
      getCityData({ stateId: stateValue, countryId: 1, Take: 1000  });
    }
  }, [stateValue]);

  const { mutateAsync: addnursinghomeAPI, isLoading: isAdding } = useMutation(
    'add-nursinghome',
    addNursinghome
  );
  const { mutateAsync: editnursinghomeAPI, isLoading: isEditing } = useMutation(
    'edit-nursinghome',
    editnursinghome
  );

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px mx-md-auto mx-3"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="nursingHomepopup">
        <div className="modal-header">
          <h2 className="mb-0">
            {editData ? 'Edit Nursing Home' : 'Add Nursing Home'}
          </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined close">cancel</span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="p-4">
            <Container fluid>
              <Row className="mb-2 gy-4">
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    Name <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>

                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputField autofillField"
                    placeholder="Name"
                    aria-label="name"
                    {...formik.getFieldProps('name')}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.startsWith(' ')) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.name}</div>
                    </div>
                  )}
                </Col>
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    Mobile Number{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>
                  <PhoneInput
                    country={'us'}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobileNumber}
                    onChange={(value: string, country: any) => {
                      const newValue = value.trim() === '' ? '+1' : value;
                      formik.setFieldValue('countryCode', '+1');
                      formik.setFieldValue('mobileNumber', newValue);
                    }}
                    inputStyle={{
                      background: 'transparent',
                      width: '100%',
                      border: '1px solid #919EAB',
                    }}
                    inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                    onlyCountries={['us']}
                    countryCodeEditable={false}
                  />
                  {formik.touched.mobileNumber &&
                    formik.errors.mobileNumber && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.mobileNumber}
                        </div>
                      </div>
                    )}
                </Col>
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    Address Line 1{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>

                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputField autofillField"
                    placeholder="Address Line 1"
                    aria-label="address Line 1"
                    {...formik.getFieldProps('addressline1')}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.startsWith(' ')) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                  />
                  {formik.touched.addressline1 &&
                    formik.errors.addressline1 && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.addressline1}
                        </div>
                      </div>
                    )}
                </Col>
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">Address Line 2</label>

                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputField autofillField"
                    placeholder="Address Line 2"
                    aria-label="address line 2"
                    {...formik.getFieldProps('addressline2')}onChange={(e) => {
                      const value = e.target.value;
                      if (value.startsWith(' ')) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                  />
                </Col>
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    State <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>

                  <div>
                    <select
                      disabled={isLoadingState}
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      {...formik.getFieldProps('state')}
                      onChange={e => {
                        formik.handleChange(e);
                        const selectedState = e.target.value;
                        if (!selectedState) {
                          formik.setFieldValue('city', '');
                          cityData.data = '';
                        }
                      }}
                    >
                      <option value="">State</option>
                      {stateData
                        ? stateData?.Result?.map((state: any) => (
                            <option value={state?.Id} key={state?.Id}>
                              {state?.StateName} ({state?.Acronym})
                            </option>
                          ))
                        : null}
                    </select>

                    {formik.touched.state && formik.errors.state && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.state}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    City <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>

                  <div>
                    <select
                      disabled={isLoadingCity}
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      {...formik.getFieldProps('city')}
                    >
                      <option value="">Select City</option>
                      {cityData
                        ? cityData?.Result?.map((city: any) => (
                            <option value={city?.Id} key={city?.Id}>
                              {city?.CityName}
                            </option>
                          ))
                        : null}
                    </select>
                    {formik.touched.city && formik.errors.city && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.city}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={6} xs={12}>
                  <div>
                    <label className="form-label fw-bold">
                      Zip Code{' '}
                      <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>

                    <input
                      type="number"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 stateSelect autofillField"
                      placeholder="Zip code"
                      aria-label="zip"
                      value={formik.values.zip}
                      onChange={e => {
                        if (e.target.value.length <= 5) {
                          formik.setFieldValue('zip', e.target.value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.zip && formik.errors.zip && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.zip}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="w-100 d-flex align-items-end justify-content-end px-6 pb-5">
            <Button type="submit" variant="primary">
              {isAdding || isEditing ? 'Loading...' : 'Save'}
            </Button>
          </div>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default AddNursinghome;
