import { useEffect, useState } from 'react';
import { Popover, styled, Typography } from '@mui/material';
import { getCities, getCompanies, getSkills, getSkillsData, getStates } from '../api';
import { useMutation, useQuery } from 'react-query';
import RestoreIcon from '@mui/icons-material/Restore';
import CustomTooltip from '../utility/CustomTooltip';
import { KTIcon } from '../../../helpers';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import { isAdmin, isSuperAdmin } from '../../../../hooks/useUserInfo';
import { RoleEnum } from '../../../helpers/enum';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const FilterCommonPopUp = ({
  showPopup,
  handleClose,
  page,
  values,
}: {
  showPopup: boolean;
  handleClose: (obj: any, isForClose: boolean) => void;
  page: string;
  values: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedSkill, setSelectedSkill] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedRole, setSelectedRole] = useState("");
  const [endDate, setEndDate] = useState<any>("");
  const [startDate, setStartDate] = useState<any>("");
  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
  } = useMutation('states', getStates);

  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
  } = useMutation('cities', getCities);

  const {
    data: appSkillData,
    isLoading: isGettingskillData,
  } = useQuery('get-skill', getSkills);

  const {
    data: companiesData,
    isLoading: isLoadingCompanies,
  } = useQuery('companies', getCompanies);

  useEffect(() => {
    getStateData({ countryId: 1 });
  }, []);

  useEffect(() => {
    if (selectedState) {
      getCityData({ stateId: selectedState, countryId: 1, Take: 1000  });
    }
  }, [selectedState]);

  const handleSubmit = () => {
    const obj = {
      SelectedState: selectedState,
      SelectedCity: selectedCity,
      SelectedStatus: selectedStatus,
      SelectedSkill: selectedSkill,
      SelectedCompany: selectedCompany,
      SelectedRole: selectedRole,
      SelectedStartDate: startDate,
      SelectedEndDate: endDate
    };
    handleClose(obj, false);
  };

  const handleReset = () => {
    const obj = {
      SelectedState: '',
      SelectedCity: '',
      SelectedStatus: '',
      SelectedSkill: '',
      SelectedCompany: '',
      SelectedRole: '',
      SelectedStartDate: '',
      SelectedEndDate: ''
    };
    handleClose(obj, false);
  }

  useEffect(() => {
    if (values) {
      setSelectedState(values?.selectedState ?? '');
      setSelectedCity(values?.selectedCity ?? '');
      setSelectedStatus(values?.selectedStatus ?? '');
      setSelectedSkill(values?.selectedSkill ?? '');
      setSelectedCompany(values?.selectedCompany ?? '');
      setSelectedRole(values?.selectedRole ?? '');
      setStartDate(values?.selectedStartDate ?? '');
      setEndDate(values?.selectedEndDate ?? '');
    }
  }, [values]);

  const handleDateChange = (value: any, date: any) => {
if(date == "startDate"){
  setStartDate(value)
}
else{
  setEndDate(value)
}
  }

  
  const StyledDatePicker = styled(DatePicker)`
    border: 1px solid #aa9393;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 13px 12px 12px 30px !important;
    width: 100%;
  `;

  return (
    <Popover
      open={showPopup}
      anchorEl={anchorEl}
      onClose={() => handleClose({}, true)}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 197, left: 520 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className="modal-header" style={{ padding: '14px 24px' , borderBottom:"1px solid black"}}>
        <Typography variant="h6">Filter</Typography>
        <div 
        className="btn btn-sm btn-icon btn-active-color-primary"
        onClick={() => handleClose({}, true)} style={{ color: '#FE7253', display: "flex", alignItems:"center", justifyContent:"end", backgroundColor:"white" }}>
        <span className="material-symbols-outlined closeBtn">cancel</span>
        </div>
      </div>
      <div className="p-7">
        {(page === "Nursinghome" || page === "Company" || page === "Assignment") && (
          <>
            <select
              disabled={isLoadingState}
              className="form-select form-select-solid form-select-lg stateSelect autofillField"
              value={selectedState}
              onChange={e => {
                setSelectedState(e.target.value);
                setSelectedCity('');
              }}
              style={{ marginBottom: '10px' }}
            >
              <option value="">State</option>
              {stateData?.Result?.map((state: any) => (
                <option value={state?.Id} key={state?.Id}>
                  {state?.StateName} ({state?.Acronym})
                </option>
              ))}
            </select>

            <select
              disabled={isLoadingCity}
              className="form-select form-select-solid form-select-lg stateSelect autofillField"
              value={selectedCity}
              onChange={e => {
                setSelectedCity(e.target.value);
              }}
              style={{ marginBottom: (page === "Company" || page === "Assignment") ? "10px" : "0px" }}
            >
              <option value="">Select City</option>
              {cityData?.Result?.map((city: any) => (
                <option value={city?.Id} key={city?.Id}>
                  {city?.CityName}
                </option>
              ))}
            </select>
          </>
        )}

        {(page === "Company" || page === "Document") && (
          <select
            className="form-select form-select-solid form-select-lg stateSelect autofillField"
            value={selectedStatus}
            onChange={e => {
              setSelectedStatus(e.target.value);
            }}
            style={{ marginBottom: page === "Document" ? "10px" : "0px" }}
          >
            <option value="">Select Status</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        )}

        {(page === "ShiftApproval" || page === "Assignment") && (
          <select
            className="form-select form-select-solid form-select-lg stateSelect autofillField"
            value={selectedStatus}
            onChange={e => {
              setSelectedStatus(e.target.value);
            }}
            style={{ marginBottom: page === "Assignment" ? "10px" : "0px" }}
          >
            <option value="">Select Status</option>
            <option value="1">Open</option>
            <option value="2">Filled</option>
            <option value="3">Completed</option>
            <option value="4">Approved</option>
            <option value="5">Cancelled</option>
          </select>
        )}

        {page === "Clinician" && (
          <>
            <select
              disabled={isGettingskillData}
              value={selectedSkill}
              className="form-select form-select-solid form-select-lg stateSelect"
              onChange={e => {
                setSelectedSkill(e.target.value);
              }}
              style={{ marginBottom: "10px" }}
            >
              <option value="">Select skill</option>
              {appSkillData?.Result?.map((skill: any) => (
                <option value={skill?.Id} key={skill?.Id}>
                  {skill?.SkillName}
                </option>
              ))}
            </select>

            <select
              className="form-select form-select-solid form-select-lg stateSelect autofillField"
              value={selectedStatus}
              onChange={e => {
                setSelectedStatus(e.target.value);
              }}
            >
              <option value="">Select Status</option>
              <option value="1">Pending</option>
              <option value="2">Approved</option>
              <option value="3">Rejected</option>
              <option value="4">Blocked</option>
              <option value="5">Suspended</option>
              <option value="6">Terminated</option>
            </select>
          </>
        )}

        {(page === "Document" || page === "Assignment" || page === "Admin" || page === "Timesheet") && (
          <select
            disabled={isLoadingCompanies}
            value={selectedCompany}
            className="form-select form-select-solid form-select-lg stateSelect"
            onChange={e => {
              setSelectedCompany(e.target.value);
            }}
          >
            <option value="">Select company</option>
            {companiesData?.Result?.map((company: any) => (
              <option value={company?.Id} key={company?.Id}>
                {company?.CompanyName}
              </option>
            ))}
          </select>
        )}

        {page === "Admin" && (
          <select
          name="selectedRole"
          value={selectedRole}
          onChange={e => setSelectedRole(e.target.value)}
          // onBlur={formik.handleBlur}
          className="form-select form-select-solid form-select-lg stateSelect"
          style={{marginTop:"10px"}}
        >
          <option value="">Select Role</option>
          {isSuperAdmin && (
            <option value={RoleEnum?.SuperAdmin}>
              Super Admin
            </option>
          )}
          {(isSuperAdmin || isAdmin) && (
            <option value={RoleEnum?.Admin}>Admin</option>
          )}
          <option value={RoleEnum?.CompanyAdmin}>
            Facility Admin
          </option>
        </select>
        )}
        {page === "Timesheet" && (
          <>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='mt-2'>
            <label>Start date</label>
          <DatePicker
            name="StartDate"
            value={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            disableHighlightToday={true}
            slotProps={{
              textField: {
                size: "small",
                error: false,
              },
            }}
            views={["year", "month", "day"]}
            sx={{
              "&.MuiFormControl-root": {
                width: "100%",
              },
              border: "1px solid #919EAB",
              borderRadius: "0.475rem !important",
              height: "3.4rem !important",
              padding: "2px 2px !important",
              marginTop:"10px"
            }}
          />
          </div>
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className='mt-2'>
        <label>End date</label>
          <DatePicker
            name="EndDate"
            value={endDate}
            disabled={!startDate}
            disableHighlightToday={true}
            onChange={(date) => {
              setEndDate(date);
            }}
            minDate={startDate}
            slotProps={{
              textField: {
                size: "small",
                error: false,
              },
            }}
            views={["year", "month", "day"]}
            sx={{
              "&.MuiFormControl-root": {
                width: "100%",
              },
              border: "1px solid #919EAB",
              borderRadius: "0.475rem !important",
              height: "3.4rem !important",
              padding: "2px 2px !important",
              marginTop:"10px"
            }}
          />
          </div>
        </LocalizationProvider> */}
         <div className='mt-2'>
        <label>Start date</label>
        <StyledDatePicker
                showIcon
                selected={startDate}
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                placeholderText="MM/DD/YYYY"
                onChange={(value) => handleDateChange(value, "startDate")}
                autoComplete="off"
              />{' '}
              </div>
              <div className='mt-2'>
<label>End date</label>
        <StyledDatePicker
                showIcon
                selected={endDate}
                disabled={!startDate}
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                minDate={startDate}
                placeholderText="MM/DD/YYYY"
                onChange={(value) => handleDateChange(value, "endDate")}
                autoComplete="off"
              />{' '}
              </div>
              <div className='mt-2'>
        <select
              className="form-select form-select-solid form-select-lg stateSelect autofillField"
              value={selectedStatus}
              onChange={e => {
                setSelectedStatus(e.target.value)
              }}
              style={{marginTop:"10px"}}
            >
              <option value="">Select Status</option>
              <option value="2">Approved</option>
              <option value="3">Rejected</option>
              <option value="1">Pending</option>
            </select>
            </div>
            </>
        )}

      </div>
      <div className="w-100 d-flex align-items-center justify-content-between p-5">
        {/* <Button onClick={() => handleClose({})} variant="contained">
          Cancel
        </Button> */}
         <CustomTooltip title={'Reset'}>
                                        <RestoreIcon
                                          style={{
                                            height: '30px',
                                            width: '50%',
                                          }}
                                          onClick={() => handleReset()}
                                        />
                                      </CustomTooltip>
        <div className="w-15px"></div>
        {/* <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button> */}

<Button
              onClick={handleSubmit}
              className="d-flex align-items-center w-50 justify-content-center"
            >
              Apply
            </Button>
      </div>
    </Popover>
  );
};

export default FilterCommonPopUp;
