import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  isSuperAdmin,
  isAdmin,
  isFacilityAdmin,
} from '../../../../../hooks/useUserInfo';
import { setData } from '../../../../../store/slice/header/headerSlice';
import Company from '../../company/company';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import FolderIcon from '@mui/icons-material/Folder';
import TaskIcon from '@mui/icons-material/Task';
import { ReactComponent as RugbyPlayer } from '../../../../assets/svg/ic_provider.svg';
import { ReactComponent as Shiftapproval } from '../../../../assets/svg/ic_shiftapproval.svg';
import { ReactComponent as ShiftCreation } from '../../../../assets/svg/medical.svg';
import { ReactComponent as NursingHome } from '../../../../assets/svg/nurshing-home.svg';
import { ReactComponent as Program } from '../../../../assets/svg/event.svg';
import { ReactComponent as MasterDocument } from '../../../../assets/svg/master_document.svg';
import ArticleIcon from '@mui/icons-material/Article';
import City from '../../../../../pages/city/City';

const SidebarMenuMain = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location?.pathname;

  interface ISideBarChildMenu {
    path: string;
    title: string;
    child: [];
    hasBullet: boolean;
  }
  interface ISideBarMenu {
    path: string;
    title: string;
    icon: any;
    fontIcon: string;
    child: ISideBarChildMenu[] | [];
    hasBullet: boolean;
    isSuperAdmin: boolean;
    isAdmin: boolean;
    isFacilityAdmin: boolean;
  }
  const sideBarMenus: ISideBarMenu[] = [
    {
      path: '/assignment',
      icon: <AssignmentIcon />,
      title: 'Assignments',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin,
    },
    {
      path: '/clinician',
      icon: <RugbyPlayer />,
      title: 'Clinician',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin,
    },
    {
      path: '/documents',
      icon: <FolderIcon />,
      title: 'Documents',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin,
    },
    {
      path: '/shifts',
      icon: <Shiftapproval />,
      title: 'Manage Shifts',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin,
    },
    {
      path: '/timeSheet',
      icon: <TaskIcon />,
      title: 'Timesheet',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin,
    },
    // {
    //   path: "/chat",
    //   icon: <GroupIcon />,
    //   title: "Chat",
    //   fontIcon: "bi-layers",
    //   child: [],
    //   hasBullet: false,
    //   isSuperAdmin,
    //   isAdmin,
    // },
    // {
    //   path: "/clincian-leads",
    //   icon: <HealthAndSafetyIcon />,
    //   title: "Clincian Leads",
    //   fontIcon: "bi-layers",
    //   child: [],
    //   hasBullet: false,
    //   isSuperAdmin,
    //   isAdmin,
    // },
    {
      path: '/company',
      icon: <Company />,
      title: 'Facility',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin,
    },
    {
      path: '/nursing-home',
      icon: <NursingHome />,
      title: 'Nursing Home',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin: false,
    },
    {
      path: '/shift-creation',
      icon: <ShiftCreation />,
      title: 'Manage Shift Time',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin: false,
    },

    {
      path: '/Admin',
      icon: <BusinessIcon />,
      title: 'Admin',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin: false,
    },
    {
      path: '/skill',
      icon: <BusinessIcon />,
      title: 'Skill',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin: false,
    },
    {
      path: '/program',
      icon: <Program />,
      title: 'Program',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin,
      isFacilityAdmin: false,
    },
    {
      path: '/city',
      icon: <City />,
      title: 'City',
      fontIcon: 'bi-layers',
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
      isFacilityAdmin: false,
    },
    // {
    //   path: '/document-type',
    //   icon: <FolderIcon />,
    //   title: 'Master document',
    //   fontIcon: 'bi-layers',
    //   child: [],
    //   hasBullet: false,
    //   isSuperAdmin,
    //   isAdmin: false,
    //   isFacilityAdmin: false,
    // },
    {
      path: '/manage-pages',
      icon: <ArticleIcon />,
      title: 'Settings',
      fontIcon: 'bi-layers',
      child: [
        // {
        //   path: '/manage-privacy-policy',
        //   title: 'Privacy Policy',
        //   child: [],
        //   hasBullet: true,
        // },
        // {
        //   path: '/manage-terms-condition',
        //   title: 'Terms & Conditions',
        //   child: [],
        //   hasBullet: true,
        // },
        {
          path: '/manage-about-us',
          title: 'About Us',
          child: [],
          hasBullet: true,
        },
        {
          path: '/manage-contact-us',
          title: 'Contact Us',
          child: [],
          hasBullet: true,
        },
      ],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
      isFacilityAdmin: false,
    },
  ];

  const currentPathTitle = useMemo(() => {
    let currTitle = '';
    sideBarMenus.forEach(side => {
      if (side?.child?.length) {
        side?.child?.forEach(childSide => {
          if (childSide.path === pathname) {
            currTitle = childSide.title;
          }
        });
      } else {
        if (side.path === pathname) {
          currTitle = side.title;
        }
      }
    });
    return currTitle;
  }, [pathname]);

  useEffect(() => {
    dispatch(setData(currentPathTitle));
  }, [currentPathTitle]);

  return (
    <>
      {sideBarMenus.map((menu: ISideBarMenu) => {
        return menu.isSuperAdmin || menu.isAdmin || menu?.isFacilityAdmin ? (
          !isSuperAdmin || (isSuperAdmin && menu.title != 'Thrive Admin') ? (
            <div key={menu.title}>
              {menu.child.length ? (
                <SidebarMenuItemWithSub
                  to={menu.path}
                  title={menu.title}
                  hasBullet={menu.hasBullet}
                  fontIcon={menu.fontIcon}
                  icon={menu.icon}
                >
                  {menu.child.map((menuChild: ISideBarChildMenu, i) => (
                    <SidebarMenuItem
                      key={menu.title + i}
                      to={menuChild.path}
                      title={menuChild.title}
                      hasBullet={menuChild.hasBullet}
                    />
                  ))}
                </SidebarMenuItemWithSub>
              ) : (
                <SidebarMenuItem
                  to={menu.path}
                  title={menu.title}
                  hasBullet={menu.hasBullet}
                  fontIcon={menu.fontIcon}
                  icon={menu.icon}
                />
              )}
            </div>
          ) : null
        ) : null;
      })}
    </>
  );
};

export { SidebarMenuMain };
