import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { MultiSelect } from 'react-multi-select-component';
import {
  addStaff,
  editStaff,
  getCompanyName,
} from '../../_metronic/layout/components/api';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';
import './admin.css';
import { RoleEnum } from '../../_metronic/helpers/enum';
import { isAdmin, isSuperAdmin } from '../../hooks/useUserInfo';
type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;

  editData: {
    CompanyProviders: never[];
    Id: any;
    FirstName: string;
    LastName: string;
    Email: string;
    MobileNumber: any;
    Roles: any;
    Companies: any;
  };
};
interface ISelectedCompanies {
  label: string;
  value: string;
}

const modalsRoot = document.getElementById('root-modals') || document.body;

const Invitestaff = ({ show, handleClose, editData }: Props) => {
  const [selectedCompanies, setSelectedCompnaies] = useState<
    ISelectedCompanies[] | []
  >([]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Wrong email format')
      .required('Email is required'),
    mobileNumber: Yup.string()
      .required('Mobile number is required')
      .min(10, 'Mobile number format is incorrect'),
    selectedRole: Yup.string().required('Role is required'),
    selectedCompanies: Yup.array().when('selectedRole', ([type], schema) =>
      type == '2'
        ? schema
            .required('Select at least one company')
            .min(1, 'Select at least one company')
        : schema.nullable()
    ),
  });

  const { data: companyData, isLoading: isLoadingCompany } = useQuery(
    'states',
    getCompanyName
  );

  const { mutateAsync: addStaffAPI, isLoading: isAdding } = useMutation(
    'add-staff',
    addStaff
  );
  const { mutateAsync: editStaffAPI, isLoading: isEditing } = useMutation(
    'edit-staff',
    editStaff
  );

  const formik: any = useFormik({
    initialValues: {
      firstName: editData?.FirstName,
      lastName: editData?.LastName,
      email: editData?.Email,
      mobileNumber: editData?.MobileNumber,
      companyName: editData?.Companies,
      selectedCompanies: editData?.Companies ?? [],
      selectedRole: '',
      Role: '',
    },
    validationSchema,
    onSubmit: async values => {
      const ids = selectedCompanies?.length
        ? selectedCompanies.map((items: any) => items.value)
        : editData?.Companies?.map((items: any) => items.value) || [];

      const idsString = ids.join(',');
      const getSelectedRole = formik.values.selectedRole;

      const bodyParams = {
        id: editData?.Id ? editData?.Id : 0,
        companyIds: getSelectedRole === RoleEnum?.SuperAdmin ? '' : idsString,
        firstName: values.firstName ? values?.firstName?.trim().replace(/\s+/g, ' ') : '',
        lastName: values.lastName ? values?.lastName?.trim().replace(/\s+/g, ' ') : '',
        email: values.email ? values?.email?.trim().replace(/\s+/g, ' ') : '',
        mobileNumber: values.mobileNumber,
        roleIds: getSelectedRole,
      };

      const response = editData?.Id
        ? await editStaffAPI(bodyParams)
        : await addStaffAPI(bodyParams);

      if (response?.IsSuccess) {
        snackActions.success(response.Message);
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message || 'Something went wrong!');
      }
    },
  });

  useEffect(() => {
    if (editData) {
      if (editData.Roles && editData.Roles.length > 0) {
        const selectedRole = editData.Roles[0].RoleId;
        formik.setFieldValue('selectedRole', selectedRole);
      }
      // else {
      //   setSelectedRole('Super Admin');
      //   formik.setFieldValue('selectedRole', 'Super Admin');
      // }

      if (editData.Companies && editData.Companies.length > 0) {
        const selectedCompanies = editData.Companies.map((company: any) => ({
          label: company.Company,
          value: company.CompanyId,
        }));
        setSelectedCompnaies(selectedCompanies);
        formik.setFieldValue('selectedCompanies', selectedCompanies);
      }
    }
  }, [editData]);

  const handlecompanychange = (fiedname: any, value: any) => {
    formik.setFieldValue('selectedCompanies', value);
    setSelectedCompnaies(value);
  };

  const isShowCompanyDropDown =
    formik.values.selectedRole == RoleEnum?.CompanyAdmin;

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px mx-md-auto mx-3"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="adminModel">
        <div className="modal-header">
          <h2 className="mb-0">{editData ? 'Edit Admin' : 'Add Admin'}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined closeBtn">cancel</span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="p-4 pb-0">
            <Container fluid>
              <Row className="gy-sm-3 gy-2">
                <Col sm={6} sx={12}>
                  <div>
                    {' '}
                    <label className="form-label fw-bold">
                      First Name{' '}
                      <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 nameField autofillField"
                      placeholder="First Name"
                      aria-label="First Name"
                      {...formik.getFieldProps('firstName')}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.startsWith(' ')) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.firstName}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={6} sx={12}>
                  <div>
                    <label className="form-label fw-bold">
                      Last Name{' '}
                      <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 nameField autofillField"
                      placeholder="Last Name"
                      aria-label="Last Name"
                      {...formik.getFieldProps('lastName')}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.startsWith(' ')) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.lastName}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={6} sx={12}>
                  <div>
                    <label className="form-label fw-bold">
                      Email{' '}
                      <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <input
                      placeholder="Email"
                      aria-label="Email"
                      {...formik.getFieldProps('email')}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                      type="email"
                      name="email"
                      autoComplete="off"
                      disabled={editData ? true : false}
                      style={{
                        cursor: editData ? 'not-allowed' : 'initial',
                        backgroundColor: editData ? '#e9ecef' : '#f9f9f9',
                        border: '1px solid #919EAB66',
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.startsWith(' ')) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={6} sx={12}>
                  <label className="form-label fw-bold">
                    Mobile Number{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>
                  <div>
                    <PhoneInput
                      country={'us'}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobileNumber}
                      onChange={(value: string, country: any) => {
                        const newValue = value.trim() === '' ? '+1' : value;

                        formik.setFieldValue('countryCode', '+1');
                        formik.setFieldValue('mobileNumber', newValue);
                      }}
                      inputStyle={{
                        background: '#f9f9f9',
                        width: '100%',
                        border: '1px solid #919eab66',
                        backgroundColor: editData ? '#e9ecef' : '#f9f9f9',
                        borderRadius: '0.625rem',
                      }}
                      inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                      onlyCountries={['us']}
                      disabled={editData ? true : false}
                      countryCodeEditable={false}
                    />
                    {formik.touched.mobileNumber &&
                      formik.errors.mobileNumber && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.mobileNumber}
                          </div>
                        </div>
                      )}
                  </div>
                </Col>
                <Col xs={12}>
                  <div>
                    <label className="form-label fw-bold">
                      Role <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <select
                      id="selectedRole"
                      name="selectedRole"
                      value={formik.values.selectedRole}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-select form-select-solid form-select-lg autofillField"
                    >
                      <option value="">Select Role</option>
                      {isSuperAdmin && (
                        <option value={RoleEnum?.SuperAdmin}>
                          Super Admin
                        </option>
                      )}
                      {(isSuperAdmin || isAdmin) && (
                        <option value={RoleEnum?.Admin}>Admin</option>
                      )}
                      <option value={RoleEnum?.CompanyAdmin}>
                        Facility Admin
                      </option>
                    </select>
                    {formik.touched.selectedRole &&
                      formik.errors.selectedRole && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.selectedRole}
                          </div>
                        </div>
                      )}
                  </div>
                </Col>
                <Col xs={12}>
                  {isShowCompanyDropDown && (
                    <div>
                      <label className="form-label fw-bold">
                        Facility{' '}
                        <span className="fw-bolder mt-5 text-danger">*</span>
                      </label>
                      <div className="companyAdminselect">
                        <MultiSelect
                          options={
                            companyData && !isLoadingCompany
                              ? companyData?.Result?.map((d: any) => {
                                  return {
                                    label: d?.CompanyName,
                                    value: d?.Id,
                                  };
                                })
                              : []
                          }
                          value={formik.values.selectedCompanies}
                          onChange={(selected: any) =>
                            handlecompanychange('selectedCompanies', selected)
                          }
                          labelledBy="Select"
                          className="form-control form-control-lg form-control-solid p-1"
                        />
                      </div>
                      {formik.touched.selectedCompanies &&
                        formik.errors.selectedCompanies &&
                        formik.values.selectedRole ==
                          RoleEnum?.CompanyAdmin && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.selectedCompanies}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
          <div className="w-100 d-flex p-6 pt-3 align-items-end justify-content-end">
            <Button type="submit" size="lg" variant="primary">
              {isAdding || isEditing ? 'Loading...' : 'Save'}
            </Button>
          </div>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default Invitestaff;
