import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  nextStep,
  prevStep,
  setAssignmentFormData,
} from '../../../../../store/slice/form/assignment_form';
import {
  getCities,
  getNursingHomeAddresseById,
  getPatientdetail,
  getStates,
} from '../../api';
import './stepper-style.css';

export const Step_2 = () => {
  const [cityName, setCityName] = useState('');
  const [cityvalue, setCityValue] = useState('');
  const [filteredAddressData, setFilteredAddressData] = useState('');
  const { data: patientData } = useQuery('patientdetail', getPatientdetail);
  const { data: addressData } = useQuery(
    'nursingHome',
    getNursingHomeAddresseById
  );
  const { data: stateData, mutateAsync: getStateData } = useMutation(
    'states',
    getStates
  );

  const { data: cityData, mutateAsync: getCityData } = useMutation(
    'cities',
    getCities
  );

  useEffect(() => {
    getStateData({ countryId: 1 });
  }, []);

  const Step_2_validation_schema = Yup.object().shape({
    patientId: Yup.string().required('This field is required'),
    patientContactNo: Yup.string().required('This field is required').min(10, 'Contact Number should be 10 digit'),
    patientFirstName: Yup.string().required('This field is required'),
    patientLastName: Yup.string().required('This field is required'),
    secondaryContactFirstName: Yup.string(),
    secondaryContactLastName: Yup.string(),
    // secondaryContactNumber: Yup.string(),
    secondaryContactNumber: Yup.string()
    .test('remove-country-code', 'Contact Number should be 10 digit', function(value) {
      if (!value) return true;

      if (value === "1") return true;

      const cleanValue = value.replace(/^1/, '');

      return cleanValue.length >= 10;
    })
    .nullable(),
    patientNotes: Yup.string(),
    selectLocationType: Yup.string().required('This field is required'),
    companyAddress: Yup.string().when('selectLocationType', ([type], schema) =>
      type != '1'
        ? schema
            .required('Facility address  is required')
            .min(1, 'select at least one address')
        : schema.nullable()
    ),
    state: Yup.string().when('selectLocationType', ([type], schema) =>
      type != '2' ? schema.required('State is required') : schema.nullable()
    ),
    city: Yup.string().when('selectLocationType', ([type], schema) =>
      type != '2' ? schema.required('City is required') : schema.nullable()
    ),
    zipcode: Yup.string().when('selectLocationType', ([type], schema) =>
      type != '2'
        ? schema
            .required('Zip code is required')
            .matches(/^[0-9]+$/, 'Zip code must contain only numbers')
            .min(5, 'Zip code must be at least 5 digits')
            .max(6, 'Zip code must be at most 6 digits')
        : schema.nullable()
    ),
    addressLine1: Yup.string().when('selectLocationType', ([type], schema) =>
      type != '2' ? schema.required('Address is required') : schema.nullable()
    ),
  });

  const dispatch = useDispatch();

  const formDatas = useSelector((state: any) => state.assignment.formData);
  const formik: any = useFormik({
    initialValues: {
      patientId: formDatas?.patientDetails?.patientCode ?? '',
      patientContactNo:
        `${formDatas?.patientDetails?.countryCode}${formDatas?.patientDetails?.contactNumber}` ??
        '',
      patientFirstName: formDatas?.patientDetails?.firstName ?? '',
      patientLastName: formDatas?.patientDetails?.lastName ?? '',
      secondaryContactFirstName:
        formDatas?.patientDetails?.contactPersonFirstName ?? '',
      secondaryContactLastName:
        formDatas?.patientDetails?.contactPersonLastName ?? '',
      secondaryContactNumber:
        `${formDatas?.patientDetails?.countryCode || ''}${
          formDatas?.patientDetails?.contactPersonContactNumber || ''
        }` ?? '',
      patientNotes: formDatas?.patientDetails?.note ?? '',
      selectLocationType: formDatas?.selectLocationType ?? '',
      companyAddress: formDatas?.companyAddress ?? '',
      state: formDatas?.stateId ?? '',
      city: formDatas?.cityId ?? '',
      zipcode: formDatas?.zipCode ?? '',
      selectedItems: formDatas?.patientOtherInformationIds ?? [],
      addressLine1: formDatas?.addressLine1 ?? '',
      countryCode: formDatas?.patientDetails?.countryCode ?? '',
    },
    validationSchema: Step_2_validation_schema,
    onSubmit: async values => {
      const countryCodeLength = values.countryCode;
      const contactNumber = values.patientContactNo?.slice(countryCodeLength);

      let payload = {
        id: formDatas?.patientDetails?.id,
        patientCode: values?.patientId ?? '',
        firstName: values?.patientFirstName ?? '',
        lastName: values?.patientLastName ?? '',
        countryCode: 1,
        contactNumber: contactNumber ?? '',
        contactPersonFirstName: values?.secondaryContactFirstName ?? '',
        contactPersonLastName: values?.secondaryContactLastName ?? '',
        contactPersonContactNumber: values?.secondaryContactNumber
          ? values?.secondaryContactNumber?.slice(countryCodeLength)
          : '',
        note: values?.patientNotes ?? '',
      };
      dispatch(
        setAssignmentFormData({
          patientDetails: payload,
          patientOtherInformationIds: values?.selectedItems,
          cityName: cityName,
          addressLine1: values?.addressLine1,
          selectLocationType: values?.selectLocationType,
          companyAddress: values?.companyAddress,
          cityId: values?.city,
          stateId: values?.state,
          zipCode: values?.zipcode,
          selectedPatientAddress: filteredAddressData,
          nursinghomecityId: cityvalue,
        })
      );
      dispatch(nextStep());
    },
  });

  let stateValue = formik.values.state;

  useEffect(() => {
    if (stateValue) {
      getCityData({ stateId: stateValue, countryId: 1, Take: 1000 });
    }
  }, [stateValue]);

  useEffect(() => {
    if (cityData) {
      const cities = cityData?.Result;
      const selectedCity = formik.values.city;
      if (selectedCity && cities) {
        const city = cities.find((skill: any) => skill.CityId == selectedCity);
        setCityName(city?.CityName);
      }
    }
  }, [cityData, formik.values.city]);

  useEffect(() => {
    if (addressData) {
      const address = addressData?.Result;
      const selectedAddress = formik.values.companyAddress;
      if (selectedAddress && address) {
        const city = address.find(
          (address: any) => address.Id == selectedAddress
        );
        setFilteredAddressData(city);
        setCityValue(city?.CityId);
        const nursinghomeaddress = city?.Address;
        const addressComponents = nursinghomeaddress.split(',');
        const cityName =
          addressComponents[addressComponents.length - 2]?.trim();
        setCityName(cityName);
      }
    }
  }, [addressData, formik.values.companyAddress]);

  useEffect(() => {
    if (formDatas?.Id) {
      formik.setFieldValue(
        'patientId',
        formDatas?.patientDetails?.patientCode ?? ''
      );
      formik.setFieldValue(
        'patientContactNo',
        formDatas?.patientDetails?.countryCode +
          formDatas?.patientDetails?.contactNumber ?? ''
      );
      formik.setFieldValue(
        'patientFirstName',
        formDatas?.patientDetails?.firstName ?? ''
      );
      formik.setFieldValue(
        'patientLastName',
        formDatas?.patientDetails?.lastName ?? ''
      );
      formik.setFieldValue(
        'secondaryContactFirstName',
        formDatas?.patientDetails?.contactPersonFirstName ?? ''
      );
      formik.setFieldValue(
        'secondaryContactLastName',
        formDatas?.patientDetails?.contactPersonLastName ?? ''
      );
      formik.setFieldValue(
        'secondaryContactNumber',
        formDatas?.patientDetails?.countryCode +
          formDatas?.patientDetails?.contactPersonContactNumber ?? ''
      );
      formik.setFieldValue(
        'patientNotes',
        formDatas?.patientDetails?.note ?? ''
      );
      formik.setFieldValue(
        'selectLocationType',
        formDatas?.selectLocationType ?? ''
      );
      formik.setFieldValue('companyAddress', formDatas?.nursingHomeId ?? '');
      if (formDatas?.nursingHomeId) {
        formik.setFieldValue('state', '');
        formik.setFieldValue('city', '');
        formik.setFieldValue('zipcode', '');
        formik.setFieldValue('addressLine1', '');
      } else {
        formik.setFieldValue('state', formDatas?.stateId ?? '');
        formik.setFieldValue('city', formDatas?.cityId ?? '');
        formik.setFieldValue('zipcode', formDatas?.zipCode ?? '');
        formik.setFieldValue('addressLine1', formDatas?.addressLine1 ?? '');
      }
      if (
        formDatas?.patientDetails?.patientOtherInformationIds &&
        formDatas?.patientDetails?.patientOtherInformationIds != undefined
      )
        formik.setFieldValue(
          'selectedItems',
          formDatas?.patientDetails?.patientOtherInformationIds ?? []
        );
    }
  }, [formDatas?.Id, addressData]);

  const handleCheckboxChange = (id: any) => {
    const selectedItems: any = formik.values.selectedItems;
    const index = selectedItems.indexOf(id);

    if (index > -1) {
      const newSelectedItems = [...selectedItems];
      newSelectedItems.splice(index, 1);
      formik.setFieldValue('selectedItems', newSelectedItems);
    } else {
      formik.setFieldValue('selectedItems', [...selectedItems, id]);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate className="form">
        <div className="my-5">
          <h3>Add Patient Information</h3>
        </div>
        <Row className="gy-4">
          <Col xs={12}>
            <label className="form-label fw-normal inputTitle">
              Patient Id
              {''} <span className="fw-bolder mt-5 text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="Patient Id"
              style={{
                backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
              }}
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputFieldStyle autofillFieldwhite"
              {...formik.getFieldProps('patientId')}
              disabled={formDatas?.Id}
              onChange={(e) => {
                const value = e.target.value;
                if (value.startsWith(' ')) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
            />
            {formik.touched.patientId && formik.errors.patientId && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.patientId}</div>
              </div>
            )}
          </Col>

          <Col lg={4} md={6} sm={12}>
            <label className="form-label fw-normal inputTitle">
              First Name
              {''} <span className="fw-bolder mt-5 text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="First Name"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputFieldStyle autofillFieldwhite"
              {...formik.getFieldProps('patientFirstName')}
              onChange={(e) => {
                const value = e.target.value;
                if (value.startsWith(' ')) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
            />
            {formik.touched.patientFirstName &&
              formik.errors.patientFirstName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.patientFirstName}
                  </div>
                </div>
              )}
          </Col>

          <Col lg={4} md={6} sm={12}>
            <label className="form-label fw-normal inputTitle">
              Last Name
              {''} <span className="fw-bolder mt-5 text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="Last Name"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputFieldStyle autofillFieldwhite"
              {...formik.getFieldProps('patientLastName')}
              onChange={(e) => {
                const value = e.target.value;
                if (value.startsWith(' ')) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
            />
            {formik.touched.patientLastName &&
              formik.errors.patientLastName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.patientLastName}
                  </div>
                </div>
              )}
          </Col>

          <Col lg={4} md={6} sm={12}>
            <label className="form-label fw-normal inputTitle">
              Contact Number
              {''} <span className="fw-bolder mt-5 text-danger">*</span>
            </label>
            <PhoneInput
              country={'us'}
              onBlur={formik.handleBlur}
              value={formik.values.patientContactNo}
              onChange={(value: string, country: any) => {
                formik.setFieldValue('countryCode', country?.dialCode || '');
                const contactNumber = value.replace(country?.dialCode, '');
                formik.setFieldValue(
                  'patientContactNo',
                  `${country?.dialCode}${contactNumber}`
                );
              }}
              inputStyle={{
                width: '100%',
              }}
              inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite"
              disableDropdown={false}
              onlyCountries={['us']}
            />
            {formik.touched.patientContactNo &&
              formik.errors.patientContactNo && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.patientContactNo}
                  </div>
                </div>
              )}
          </Col>

          <Col lg={4} md={6} sm={12}>
            <label className="form-label fw-normal inputTitle">
              Secondary First Name
            </label>
            <input
              type="text"
              placeholder="First Name"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputFieldStyle autofillFieldwhite"
              {...formik.getFieldProps('secondaryContactFirstName')}
              onChange={(e) => {
                const value = e.target.value;
                if (value.startsWith(' ')) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
            />
            {formik.touched.secondaryContactFirstName &&
              formik.errors.secondaryContactFirstName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.secondaryContactFirstName}
                  </div>
                </div>
              )}
          </Col>

          <Col lg={4} md={6} sm={12}>
            <label className="form-label fw-normal inputTitle">
              Secondary Last Name
            </label>
            <input
              type="text"
              placeholder="Last Name"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputFieldStyle autofillFieldwhite"
              {...formik.getFieldProps('secondaryContactLastName')}
              onChange={(e) => {
                const value = e.target.value;
                if (value.startsWith(' ')) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
            />
          </Col>

          <Col lg={4} md={6} sm={12}>
            <label className="form-label fw-normal inputTitle">
              Secondary Contact No.
            </label>
            <PhoneInput
              country={'us'}
              value={formik.values.secondaryContactNumber}
              onChange={(value: string, country: any) => {
                formik.setFieldValue('countryCode', country?.dialCode || '');
                formik.setFieldValue('secondaryContactNumber', value || '');
              }}
              inputProps={{
                name: 'secondaryContactNumber',
              }}
              inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 w-100 autofillFieldwhite"
              disableDropdown={false}
              disableCountryCode={false}
              onlyCountries={['us']}
            />
            {formik.touched.secondaryContactNumber &&
              formik.errors.secondaryContactNumber && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <p className="text-danger">
                      {formik.errors.secondaryContactNumber}
                    </p>
                  </div>
                </div>
              )}
          </Col>

          <Col md={6} xs={12}>
            <label className="form-label fw-normal inputTitle">
              Other Information
            </label>
            {patientData?.Result?.map((item: any, index: any) => (
              <div className="d-flex align-items-center" key={item?.Id}>
                <input
                  type="checkbox"
                  id={item.id}
                  className="mt-2 mb-4 me-2"
                  checked={formik.values.selectedItems.includes(item.Id)}
                  onChange={() => handleCheckboxChange(item.Id)}
                />
                <p
                  className="pt-2 fw-normal cursor-pointer"
                  onClick={() => handleCheckboxChange(item.Id)}
                >
                  {item.OtherInformationTitle}
                  {` (${item.OtherInformationDescription})`}
                </p>
              </div>
            ))}
          </Col>

          <Col md={6} xs={12}>
            <label className="form-label fw-normal inputTitle">Notes</label>
            <textarea
              rows={7}
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputStyleField me-0"
              placeholder="Notes"
              aria-label="notes"
              {...formik.getFieldProps('patientNotes')}
              onChange={(e) => {
                const value = e.target.value;
                if (value.startsWith(' ')) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
            />
          </Col>
        </Row>
        <hr className="my-5"></hr>
        <Row>
          <Col xs={12}>
            <label className="form-label fw-normal inputTitle">
              Patient's Address
              {''} <span className="fw-bolder mt-5 text-danger">*</span>
            </label>
            <select
              style={{
                border: '1px solid #919EAB',
                backgroundColor: formDatas?.Id
                  ? '#e0e0e0'
                  : 'var(--bs-gray-100)',
              }}
              className="form-select form-select-light form-select-lg"
              {...formik.getFieldProps('selectLocationType')}
              disabled={formDatas?.Id}
            >
              <option value="">Select Location Type</option>
              <option value="2">Nursing Home</option>
              <option value="1">Patient's Home</option>
            </select>
            {formik.touched.selectLocationType &&
              formik.errors.selectLocationType && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.selectLocationType}
                  </div>
                </div>
              )}

            {formik.values.selectLocationType === '2' && (
              <select
                style={{
                  border: '1px solid #919EAB',
                  marginTop: '15px',
                  backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
                }}
                className="form-select form-select-solid form-select-lg"
                {...formik.getFieldProps('companyAddress')}
                disabled={formDatas?.Id}
              >
                <option value="">Select Address</option>
                {addressData
                  ? addressData?.Result?.map((address: any) => (
                      <option value={address?.Id} key={address?.Id}>
                        {address?.Address}
                      </option>
                    ))
                  : null}
              </select>
            )}
            {formik.touched.companyAddress && formik.errors.companyAddress && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.values.selectLocationType === '2'
                    ? formik.errors.companyAddress
                    : undefined}
                </div>
              </div>
            )}

            {formik.values.selectLocationType === '1' && (
              <>
                <Row>
                  <Col xs={12}>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputFieldStyle mt-3 autofillFieldwhite"
                      placeholder="Address"
                      aria-label="address"
                      style={{
                        backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
                      }}
                      {...formik.getFieldProps('addressLine1')}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.startsWith(' ')) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      disabled={formDatas?.Id}
                    />
                    {formik.touched.addressLine1 &&
                      formik.errors.addressLine1 && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.addressLine1}
                          </div>
                        </div>
                      )}
                  </Col>
                </Row>

                <Row>
                  <Col xs={4} className="pt-3">
                    <select
                      className="form-select form-select-solid form-select-lg autofillFieldwhite"
                      style={{
                        backgroundColor: formDatas?.Id ? '#e0e0e0' : 'white',
                        border: '1px solid #919EAB',
                      }}
                      {...formik.getFieldProps('state')}
                      disabled={formDatas?.Id}
                    >
                      <option value="">Province/State</option>
                      {stateData
                        ? stateData?.Result?.map((state: any) => (
                            <option value={state?.Id} key={state?.Id}>
                              {state?.StateName} ({state?.Acronym})
                            </option>
                          ))
                        : null}
                    </select>
                    {formik.touched.state && formik.errors.state && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.state}
                        </div>
                      </div>
                    )}
                  </Col>

                  <Col xs={4} className="pt-3">
                    <select
                      style={{
                        backgroundColor: formDatas?.Id ? '#e0e0e0' : 'white',
                        border: '1px solid #919EAB',
                      }}
                      className="form-select form-select-solid form-select-lg autofillFieldwhite"
                      {...formik.getFieldProps('city')}
                      disabled={formDatas?.Id}
                    >
                      <option value=""> City</option>
                      {cityData
                        ? cityData?.Result?.map((city: any) => (
                            <option value={city?.Id} key={city?.Id}>
                              {city?.CityName}
                            </option>
                          ))
                        : null}
                    </select>
                    {formik.touched.city && formik.errors.city && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.city}
                        </div>
                      </div>
                    )}
                  </Col>

                  <Col xs={4} className="pt-3">
                    <input
                      type="number"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite"
                      placeholder="Postal code/Zipcode"
                      style={{
                        backgroundColor: formDatas?.Id
                          ? '#e0e0e0'
                          : 'var(--bs-gray-100)',
                        border: '1px solid #919EAB',
                      }}
                      disabled={formDatas?.Id}
                      value={formik.values.zipcode}
                      onChange={e => {
                        if (e.target.value.length <= 5) {
                          formik.setFieldValue('zipcode', e.target.value);
                        }
                      }}
                    />
                    {formik.touched.zipcode && formik.errors.zipcode && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.zipcode}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>

        <div className="d-flex justify-content-between mt-10">
          <Button
            size="lg"
            className="px-10"
            onClick={() => dispatch(prevStep())}
            variant="secondary"
          >
            Back
          </Button>

          <Button type="submit" size="lg" variant="primary">
            Save & Next
          </Button>
        </div>
      </form>
    </>
  );
};
